import React from 'react'
import { ReactComponent } from './icon.svg'
import { MergeReactElementProps } from '@komune-io/g2-utils'

interface ArtIndexProps {
  color?: string
}

type Props = MergeReactElementProps<'svg', ArtIndexProps>

export const ArtIndexIcon = React.forwardRef(
  (props: Props, ref: React.Ref<SVGSVGElement>) => {
    const { color = '#828282' } = props
    return <ReactComponent fill={color} ref={ref} {...props} />
  }
)

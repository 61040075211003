import { DividerAdd, FormComposable, FormComposableArray } from "@tracabois/components";
import { Stack } from '@mui/material'
import { DestinationEndProductField } from "./DestinationEndProductField";
import { useDestinationFields } from "./useDestinationFields";
import { FormComposableState } from "@komune-io/g2";
import { DataSelection } from "../useDataSelection";
import { OrganizationRef } from "@tracabois/web-certificate/src/App/model";
import { EndProductModel } from "../../../../model/product";
import { PlatformSubSection } from "@tracabois/template";

export interface DestinationFormProps {
  formState: FormComposableState
  dataSelection: DataSelection
  transformer?: OrganizationRef
}

export const DestinationForm = (props: DestinationFormProps) => {
  const { dataSelection, transformer, formState } = props

  // @ts-ignore
  const endProducts: EndProductModel[] = formState.values.destination?.endProduct
  const fields = useDestinationFields(
    dataSelection,
    formState.values?.transformation || {},
    endProducts,
    transformer
  );

  const addLine = (fieldName: string) => {
    FormComposableArray.addLine(formState, fieldName)
  }

  const deleteLine = (fieldName: string, index: number) => {
    FormComposableArray.deleteLine(formState, fieldName, index)
  }
  const endProductsFields = fields.endProductsFields
  return (
    <PlatformSubSection title="Destinataire" elevation={1}>
      <Stack direction={"column"} gap={2}>
        <FormComposable
          fields={[...fields.detailsFields.clientFields, ...fields.detailsFields.ouvrageFields]}
          formState={formState}
          fieldsStackProps={{direction: "row"}}
        />
        <DestinationEndProductField
          formState={formState}
          deleteEndProduct={(index) => deleteLine(endProductsFields.fieldName, index)}
          productTypeFields={endProductsFields.fields}
        />
        <DividerAdd
          loading={formState.isLoading}
          readOnly={formState.readOnly}
          onClick={() => addLine(endProductsFields.fieldName)}
          text="Ajouter un produit"
        />
      </Stack>
    </PlatformSubSection>
  )
}

import {CellProps, Column, Row} from '@komune-io/g2-layout'
import {TFunction} from 'i18next'
import {PlatformTableSearchFilters, PlatformPaginatedTable, TableCellText} from "@tracabois/template";
import {firstPage, OffsetPagination, PageQuery, PageResult} from "@tracabois/utils";
import {RemovalZone} from "../../model/removalZone";
import React from "react";

interface RemovalZoneProps {
  page?: PageResult<RemovalZone>
  isLoading?: boolean
  onPageQueryChange: (page: PageQuery) => void;
  onRowClicked: (row: Row<RemovalZone>) => void;
  actions: React.ReactNode
  noElevation?: boolean
}

export const RemovalZoneTable = (props: RemovalZoneProps) => {
  const {page, isLoading, onPageQueryChange, onRowClicked, actions, noElevation = true} = props

  const onSearch = (search?: string) => {
    onPageQueryChange({
      pagination: page?.pagination || firstPage,
      search: search
    })
  }
  const filter = (
    <PlatformTableSearchFilters actions={actions} onSubmit={(props) => onSearch(props.search)}/>
  )
  const onPaginationChange = (pagination: OffsetPagination) => {
    onPageQueryChange({
      pagination: pagination,
      search: page?.search
    })
  }
  return (
    <PlatformPaginatedTable<RemovalZone>
      page={page}
      columnsDefinition={getColumns}
      isLoading={isLoading}
      actions={filter}
      onPageChange={onPaginationChange}
      onRowClicked={onRowClicked}
      noElevation={noElevation}
    />
  )
}

const getColumns = (t: TFunction): Column<RemovalZone>[] => ([{
  Header: `${t("removalZone.name", "Libellé")}`,
  // @ts-ignore
  Cell: ({row}: CellProps<RemovalZone>) => (
    <TableCellText main={row.original.name} />
  )
}, {
  Header: `${t("removalZone.department", "Département")}`,
  // @ts-ignore
  Cell: ({row}: CellProps<RemovalZone>) => (
    <TableCellText main={row.original.department?.name || ""} />
  )
}, {
  Header: `${t("removalZone.commune", "Commune")}`,
  // @ts-ignore
  Cell: ({row}: CellProps<RemovalZone>) => (
    <TableCellText main={row.original.city?.name || ""} />
  )
}, {
  Header: `${t("removalZone.massif", "Massif")}`,
  // @ts-ignore
  Cell: ({row}: CellProps<RemovalZone>) => (
    <TableCellText main={row.original.massif || ""} />
  )
}])

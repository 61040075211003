import { Box } from "@mui/material";
import { RealizationCertificationLinkCard } from "./RealizationCertificationLinkCard";
import { RealizationCertificationIdLink, RealizationCertificationLink } from "../../../realization/model/realizationModel";
import { RealizationCertificationId } from "../../../../referentiels/realizationCertification";

export interface RealizationCertificationLinkCardStackProps {
  items: RealizationCertificationLink[],
  editable: boolean
  onAdd?: (item: RealizationCertificationIdLink) => Promise<boolean> | boolean
  onUpdate?: (item: RealizationCertificationIdLink) => Promise<boolean> | boolean
  onDelete?: (id: RealizationCertificationId) => Promise<boolean> | boolean
}

export const RealizationCertificationLinkCardStack = (props: RealizationCertificationLinkCardStackProps) => {
  const { items, editable, onAdd, onUpdate, onDelete } = props
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(130px, 1fr))",
        gridGap: "16px",
        justifyContent: "center"
      }}
    >
      {items.map((item, index) => (
        <RealizationCertificationLinkCard item={item} editable={editable} key={`RealizationCertificationLinkCard-Grid-${index}`} 
        onAdd={onAdd} onUpdate={onUpdate} onDelete={onDelete} />
      )
      )}
    </Box>
  )
}

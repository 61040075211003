import {Stack} from '@mui/material'
import {RemovalZoneCertification} from "../../model/RemovalZoneCertification"
import { externalFileUrl } from '@tracabois/domain'

export interface RemovalZoneCertificationCardProps {
  items: RemovalZoneCertification[]
  alignItems?: string
}

export const RemovalZoneCertificationCard = (props: RemovalZoneCertificationCardProps) => {
  const {items, alignItems = "center"} = props
  return (
    <Stack
      alignItems={alignItems}
      direction="row"
      justifyContent="space-around"
      flexWrap="nowrap"
      gap="0px 16px"
    >
      {items.map((item) => (
        <img
          src={externalFileUrl(item.img)}
          alt={item.name}
          width={24}
          height={24}
          loading="lazy"
        />
      ))}
    </Stack>
  )
}

import {Realization, RealizationUpdatedEvent, realizationToUpdateRealizationCommand} from "../..";
import {useCallback} from "react";
import {useRealizationUpdateMutation} from "../../api";
import { EditableGallery } from "@tracabois/connect";
import { CreditedFile, CreditedFileCommand, FileName } from "@tracabois/domain";

interface RealizationGallerySectionProps {
  realization?: Realization
  onRealizationUpdated?: (result: RealizationUpdatedEvent) => void
  editable?: boolean
}

export const RealizationGallerySection = (props: RealizationGallerySectionProps) => {
  const {realization, onRealizationUpdated, editable} = props
  const [realizationUpdate] = useRealizationUpdateMutation()

  const handleRealizationUpdate = useCallback(async (command: CreditedFileCommand): Promise<boolean> => {
    realization && onRealizationUpdated && await realizationUpdate({
      ...realizationToUpdateRealizationCommand(realization),
      imageCommand: command,
    })
    return true;
  }, [realizationUpdate, realization])

  const handleAdd = useCallback(async (item: CreditedFile, toUseAsCover: boolean = false): Promise<boolean> => {
    const command: CreditedFileCommand = toUseAsCover ? {toAdd: item, toUseAsCover: item.name} : {toAdd: item}
    return handleRealizationUpdate(command)
  }, [realizationUpdate, realization])

  const handleUpdate = useCallback(async (name: FileName): Promise<boolean> => {
    const command: CreditedFileCommand = {toUseAsCover: name}
    return handleRealizationUpdate(command)
  }, [realizationUpdate, realization])

  const handleDelete = useCallback(async (name: FileName): Promise<boolean> => {
    const command: CreditedFileCommand = {toRemove: name}
    return handleRealizationUpdate(command)
  }, [realizationUpdate, realization])

  if (realization?.id) {
    return (
      <EditableGallery files={realization?.images} editable={editable ?? false} onAdd={handleAdd} onUpdate={handleUpdate} onDelete={handleDelete}/>
    )
  } else {
    return (
      <></>
    )
  }
}

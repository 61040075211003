import {PlatformSubSection} from "@tracabois/template";
import {MapGeoman} from "@tracabois/components";
import {FeatureCollection} from "geojson";

interface RemovalZoneMapSectionProps {
  geoJson?: FeatureCollection<any, any>
  onGeoJsonChange: (geoJson: FeatureCollection<any, any>) => void
}

export const RemovalZoneMapSection = (props: RemovalZoneMapSectionProps) => {
  const { geoJson, onGeoJsonChange} = props
  return (
    <PlatformSubSection title={""}>
      <MapGeoman
        onGeoJsonChange={onGeoJsonChange}
        geoJson={geoJson}
      />
      {/*<CodeHighlighter*/}
      {/*  style={{marginTop: 24}}*/}
      {/*  code={JSON.stringify( geoJson ?? "", null, 2)}*/}
      {/*  highlightStyle={"atomOneLight"}*/}
      {/*  title={"GeoJson"}*/}
      {/*/>*/}
    </PlatformSubSection>
  );
}
import React from 'react'
import {Action, Actions} from "@komune-io/g2-components";
import {makeG2STyles} from "@komune-io/g2-themes";
import {Paper, Stack, Typography} from '@mui/material'

const useStyles = makeG2STyles()(
  () => ({
    block: {
      padding: "15px",
      marginBottom: "20px",
      backgroundColor: "white",
      borderRadius: "5px"
    },
    title: {
      color: "#C6C6C6",
      paddingBottom: "10px",
    },
  }))

interface PlatformSectionProps {
  children?: React.ReactNode
  title: string
  elevation?: number
  actions?: Action[]
}

export const PlatformSubSection = (props: PlatformSectionProps) => {
  const {classes} = useStyles()
  const {children, title, elevation = 0, actions} = props

  return (
    <Paper className={classes.block} elevation={elevation} sx={{zIndex: 100}}>
      <Stack width="100%" marginBottom={2} direction="row" justifyContent="space-between" spacing={2}>
        {title && (<Typography className={classes.title} variant="subtitle1">{title}</Typography>)}
        {actions && <Actions actions={actions}/>}
      </Stack>
      {children}
    </Paper>
  )
}



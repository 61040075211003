import { RemovalZoneCard } from "./RemovalZoneCard";
import { Stack } from "@mui/material";
import { RemovalZone } from "../../model/removalZone";

export interface RemovalZoneCardStackProps {
  items: RemovalZone[]
}

export const RemovalZoneCardStack = (props: RemovalZoneCardStackProps) => {
  const { items } = props
  return (
    <Stack
      sx={{
        display: "flex",
        gap: "16px",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "stretch",
        flexWrap: "wrap",
      }}
    >
      {items.map((item, index) => (
        <RemovalZoneCard item={item} key={`Grid-${index}`} />
      )
      )}
    </Stack>
  )
}

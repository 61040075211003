import {useEffect} from "react";
import { useLeafletContext } from "@react-leaflet/core";
import "@geoman-io/leaflet-geoman-free";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";
import {LeafletEvent} from "leaflet";
import L from 'leaflet';
import {FeatureCollection} from "geojson";
import {createLayersFromJson, transformLayerToJson} from "../geoJsonLayer";

type PMDrawCircleEvent = { layer: L.Circle & { pm: { enable: () => void } } };
type PMEditCircleEvent = { target: L.Circle };

interface GeomanProps {
  geoJson?: FeatureCollection<any, any>
  onGeoJsonChange: (geoJson: FeatureCollection<any, any>) => void;
  onCircleRemoved?: () => void;
}


const Geoman = (props: GeomanProps) => {
  const {geoJson, onGeoJsonChange} = props
  const {map} = useLeafletContext();

  useEffect(() => {
    map.eachLayer(function (layer) {
      // @ts-ignore
      if(layer.pm) {
        map.removeLayer(layer);
      }
    });
    geoJson && createLayersFromJson(geoJson, map)
  }, [geoJson, map])

  useEffect(() => {
    if (!map) return
    map.on("pm:create", (shape: PMDrawCircleEvent) => {
      // enable editing of circle
      shape.layer.pm.enable();

      const shapeObj = shape.layer.pm.getShape()
      map.eachLayer(function (layer) {
        // @ts-ignore
        if(layer.pm && layer._leaflet_id != shape.layer._leaflet_id) {
          map.removeLayer(layer);
        }
      });

      if(shapeObj == "Circle") {
        onGeoJsonChange(transformLayerToJson(shape.layer))
      }
      shape.layer.on("pm:edit", (e: PMEditCircleEvent) => {
        onGeoJsonChange(transformLayerToJson(e.target))
      });
    });

    map.on("pm:remove", (shape: LeafletEvent) => {
      console.log("object removed");
      const shapeObj = shape.layer.pm.getShape()
      console.log(`object edited: ${shape}`);
      if(shapeObj == "Circle") {
        // @ts-ignore
        onCircleRemoved && onCircleRemoved()
      }
    });

    if (map.pm) {
      // @ts-ignore
      map.pm.setGlobalOptions({ measurements: { measurement: true, displayFormat: 'metric' } })
      map.pm.addControls({
        position: 'topleft',
        drawMarker: false,
        drawCircleMarker: false,
        drawPolyline: false,
        drawRectangle: false,
        drawPolygon: false,
        editMode: false,
        dragMode: false,
        cutPolygon: false,
        rotateMode: false,
        drawText: false
      });
    }

    return () => {
      map.pm?.removeControls();
      // leafletContainer.pm.setGlobalOptions({ pmIgnore: true });
    };
  }, [map]);

  return null;
};

export default Geoman;
import {useState} from "react";


export interface Feedback {
  isSuccess: boolean
  isFailure: boolean
  setFeedback: (feedback: boolean) => void
}

export const useFeedback = (): Feedback => {
  const [feedback, setFeedback] = useState<boolean | undefined>(undefined)
  return {
    isSuccess: feedback !== undefined && feedback,
    isFailure: feedback !== undefined && !feedback,
    setFeedback
  }
}
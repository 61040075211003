import {useMenu, useUserMenu} from "./menu";
import {AppRouter} from "../router";
import {PlatformLayout} from "@tracabois/template";
import {brandConfig} from "@tracabois/domain";
import {CssBaseline} from "@mui/material";

interface AppProps {}

const App = (_: AppProps) => {
  const menu = useMenu()
  const {loggedMenu, notLoggedMenu} = useUserMenu()
  const config = brandConfig()
  return (
      <PlatformLayout menu={menu.principalMenu} loggedMenu={loggedMenu} notLoggedMenu={notLoggedMenu} logo={config.logo} website={config.website} >
        <CssBaseline/>
        <AppRouter />
      </PlatformLayout>
  );
};

export default App;

import {PlatformPageObject, PlatformSubSection} from "@tracabois/template";
import {Grid} from "@mui/material";
import {
  certificateConfig,
  isEditableOuvrage, isOuvrageFinished,
  OuvrageCreatedEvent,
  OuvrageDescriptionSection,
  OuvrageGallerySection,
  OuvrageIndicatorGrid,
  OuvrageProductSection,
  OuvrageStateChip,
  ProductModel,
  RemovalZoneCardStack,
  useGetOuvrageByIdQuery, useOuvrageDeleteMutation,
} from "@tracabois/domain";
import {useNavigate, useParams} from "react-router";
import {useTranslation} from "react-i18next";
import {useCallback, useMemo} from "react";
import {productRoute} from "../../product/route";
import {ObjectHeader} from "@tracabois/components";
import {DeleteButton} from "@tracabois/components";
import {goto} from "../route/ouvrage.goto";
import {OuvrageUpdateFormButton} from "@tracabois/domain";

const useLabels = () => {
  const { t } = useTranslation()
  return useMemo(() => ({
    description: t("realization.description", "Description"),
    implementation: t("ouvrage.implementation.label", "Mise en oeuvre"),
    indicators: t("ouvrage.indicators.label", "Indicateurs"),
    essence: t("ouvrage.woodSpecies.label", "Éssences"),
    products: t("ouvrage.ouvrage.label", "Produits et ouvrages"),
    addProduct: t("ouvrage.produit.add", "Ajouter ma contribution"),
    harvesting: t("ouvrage.harvesting.label", "Origine"),
  }), [t])
}

type OuvrageIdUrl = {
  ouvrageId: string
}

export const OuvrageViewPage = () => {
  const navigate = useNavigate()
  const { ouvrageId = "" } = useParams<OuvrageIdUrl>();
  const labels = useLabels()

  const ouvrageResult = useGetOuvrageByIdQuery({ id: ouvrageId })
  const isEditable = isEditableOuvrage(ouvrageResult.data)
  const isFinished = isOuvrageFinished(ouvrageResult.data)

  const [ouvrageDelete] = useOuvrageDeleteMutation()

  const deleteAction = useCallback(async (): Promise<boolean> => {
    await ouvrageDelete({
      id: ouvrageId,
    })
    goto.listOuvrage(navigate)
    return true;
  }, [ouvrageId, ouvrageDelete])


  const handleProductRowClick = (item: ProductModel) => {
    productRoute.goto.viewProduct(item.id, navigate)
  }

  const handleOuvrageAdded = async (result: OuvrageCreatedEvent) => {
    productRoute.goto.viewProduct(result.id, navigate)
  }

  const handleStateChanged = useCallback(() => {
    ouvrageResult.refetch()
  }, [ouvrageResult])

  const name = useMemo(() => {
    const realizationName = ouvrageResult.data?.realization?.name
    const prefix = realizationName ? `${realizationName} - ` : ''
    const ouvrageName = ouvrageResult.data?.name ?? ""
    return `${prefix}${ouvrageName}`
  }, [ouvrageResult.data?.realization?.name, ouvrageResult.data?.name])
  const qrcode = isFinished ? `${certificateConfig.webUrl}/ouvrages/${ouvrageId}` : undefined
  return (
    <PlatformPageObject>
      <ObjectHeader
        qrcode={qrcode}
        title={name}
        actions={<>
          { isEditable && <OuvrageUpdateFormButton item={ouvrageResult.data} onUpdated={handleStateChanged}/>}
          { isEditable && <DeleteButton onClick={deleteAction}/>}
          <OuvrageStateChip item={ouvrageResult.data} editable onStateChanged={handleStateChanged} />
        </>}
      />
      <OuvrageGallerySection
        ouvrage={ouvrageResult.data}
        editable={isEditable}
        onOuvrageUpdated={handleStateChanged}
      />
      <OuvrageDescriptionSection
        ouvrage={ouvrageResult.data}
        editable={isEditable}
        onOuvrageUpdated={handleStateChanged}
        title={labels.description}
      />
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <PlatformSubSection title={labels.indicators}>
            <OuvrageIndicatorGrid indicators={ouvrageResult.data?.indicators} />
          </PlatformSubSection>
          <PlatformSubSection title={labels.harvesting}>
            <RemovalZoneCardStack items={ouvrageResult?.data?.removalZones || []} />
          </PlatformSubSection>
        </Grid>
        <Grid item xs={9}>
          <OuvrageProductSection
            title={labels.implementation}
            item={ouvrageResult.data}
            isLoading={ouvrageResult.isLoading || ouvrageResult.isFetching}
            onOuvrageAdded={handleOuvrageAdded}
            editable={isEditable}
            onProductClick={handleProductRowClick}
          />
        </Grid>
      </Grid>
    </PlatformPageObject>
  );
}

import {useState} from "react";
import {RemovalZoneCertificationFormPopup} from "../form/RemovalZoneCertificationFormPopup";
import {RemovalZoneCertificationTable} from "../table/RemovalZoneCertificationTable";
import {Button} from '@komune-io/g2-components'
import {useTranslation} from "react-i18next";
import {Row} from "@komune-io/g2-layout";
import {
  RemovalZoneCertification,
  RemovalZoneCertificationCreateCommand,
  RemovalZoneCertificationDeleteCommand,
  RemovalZoneCertificationUpdateCommand
} from "../../model/RemovalZoneCertification";
import {
  useRemovalZoneCertificationAllQuery,
  useRemovalZoneCertificationCreateMutation, useRemovalZoneCertificationDeleteMutation,
  useRemovalZoneCertificationUpdateMutation
} from "../../api/RemovalZoneCertificationApi";
import {PlatformSubSection} from "@tracabois/template";

interface RemovalZoneCertificationSectionProps {
  title: string
}

export const RemovalZoneCertificationSection = (props: RemovalZoneCertificationSectionProps) => {
  const {title} = props
  const [selectedItem, setSelectedItem] = useState<Partial<RemovalZoneCertification> | undefined>(undefined)

  const {t} = useTranslation()
  const removalZoneCertificationPageQuery = useRemovalZoneCertificationAllQuery();
  const [createRemovalZoneCertification] = useRemovalZoneCertificationCreateMutation()
  const [updateRemovalZoneCertification] = useRemovalZoneCertificationUpdateMutation()
  const [deleteRemovalZoneCertification] = useRemovalZoneCertificationDeleteMutation()

  const closePopup = () => {
    setSelectedItem(undefined)
  }

  const handleCreate = async (item: RemovalZoneCertificationCreateCommand) => {
    await createRemovalZoneCertification(item)
    closePopup()
    removalZoneCertificationPageQuery.refetch()
    return true
  }
  const handleUpdate = async (item: RemovalZoneCertificationUpdateCommand) => {
    await updateRemovalZoneCertification(item)
    closePopup()
    removalZoneCertificationPageQuery.refetch()
    return true
  }
  const handleDelete = async (item: RemovalZoneCertificationDeleteCommand) => {
    await deleteRemovalZoneCertification(item)
    closePopup()
    removalZoneCertificationPageQuery.refetch()
    return true
  }
  const clickHandler = (certification: Row<RemovalZoneCertification>) => {
    setSelectedItem(certification.original)
  }

  const actions = (<Button color="primary" onClick={() => setSelectedItem({})} >{t("removalZoneCertification.action.create", "Ajouter une certification")}</Button>)

  return (
    <PlatformSubSection title={title}>
      <RemovalZoneCertificationTable
        page={removalZoneCertificationPageQuery.data}
        onPageQueryChange={removalZoneCertificationPageQuery.setPageQuery}
        onRowClicked={clickHandler}
        isLoading={removalZoneCertificationPageQuery.isLoading}
        actions={actions}
      />
      <RemovalZoneCertificationFormPopup
        open={!!selectedItem} item={selectedItem || {}}
        onCreate={handleCreate}
        onUpdate={handleUpdate}
        onDelete={handleDelete}
        onCancel={closePopup}
      />
    </PlatformSubSection>
  )
}

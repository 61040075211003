import {DeleteButton as G2DeleteButton} from '@komune-io/g2-components';
import {ConfirmationPopUp} from '@komune-io/g2-layout';
import {useState} from "react";
import {Typography} from "@mui/material";

export interface DeleteButtonProps {
  onClick: () => void;
}

export const DeleteButton = (props: DeleteButtonProps) => {
  const {onClick} = props
  const [open, setOpen] = useState(false)
  return (
    <>
      <G2DeleteButton sx={{
        "& .MuiButton-startIcon": {
          margin: "unset"
        }
      }} style={{margin:0}} onClick={() => setOpen(true)} variant="text"/>
      <ConfirmationPopUp
        strongConfirmation={false}
        validateText={"Valider"}
        onConfirm={onClick} open={open} onClose={() => setOpen(false)}>
          <Typography variant="subtitle1">
            Cliquez sur valider pour confirmer la suppression.
          </Typography>
      </ConfirmationPopUp>
    </>
  )
};



import { stringify } from "qs";
import {NavigateFunction} from "react-router";

const createUser = (navigate: NavigateFunction, params?: Object) => {
  return navigate(
    `/users/add${stringify(params, {
      addQueryPrefix: true,
      arrayFormat: "repeat",
    })}`
  );
};

const editUser = (userId: string, navigate: NavigateFunction) => {
  return navigate(`/users/${userId}/edit`);
};

const userTable = (navigate: NavigateFunction, params?: Object) => {
  return navigate(
    `/users${stringify(params, {
      addQueryPrefix: true,
      arrayFormat: "repeat",
    })}`
  );
};

export const goto = {
  createUser: createUser,
  editUser: editUser,
  userTable: userTable,
};

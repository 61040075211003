import {CellProps, Column, Row} from '@komune-io/g2-layout'
import {TFunction} from 'i18next'
import {PlatformPaginatedTable, TableCellText, PlatformActions} from "@tracabois/template";
import {OffsetPagination, PageQuery, PageResult} from "@tracabois/utils";
import {TransformationActor} from "../../model/realizationModel";
import {OrgRolesArray} from "@tracabois/connect";

interface RealizationActorTableProps {
  page: PageResult<TransformationActor>
  isLoading?: boolean
  onPageQueryChange: (page: PageQuery) => void;
  onRowClicked: (row: Row<TransformationActor>) => void;
  actions?: React.ReactNode
}

export const RealizationActorTable = (props: RealizationActorTableProps) => {
  const {page, isLoading, onPageQueryChange, actions, onRowClicked} = props
  const onPaginationChange = (pagination: OffsetPagination) => {
    onPageQueryChange({
      pagination: pagination
    })
  }
  return (<>
      <PlatformPaginatedTable<TransformationActor>
        page={page}
        isLoading={isLoading}
        actions={actions && <PlatformActions actions={actions} />}
        columnsDefinition={getColumns}
        onPageChange={onPaginationChange}
        onRowClicked={onRowClicked}
      />
    </>
  )
}

const getColumns = (t: TFunction): Column<TransformationActor>[] => ([{
  Header: `${t("organization.name", "Nom")}`,
  // @ts-ignore
  Cell: ({row}: CellProps<TransformationActor>) => (
    <TableCellText main={row.original?.organizationRef?.name || ""} />
  )
}, {
  Header: `${t("organization.type", "Type")}`,
  // @ts-ignore
  Cell: ({row}: CellProps<TransformationActor>) => (
    <TableCellText main={row.original?.organizationRef?.roles?.filter(it => OrgRolesArray.includes(it)).join(', ') || ""} />
  )
}, {
  Header: `${t("organization.localisation", "Produits")}`,
  // @ts-ignore
  Cell: ({row}: CellProps<TransformationActor>) => (
    <TableCellText main={row.original.product?.join(', ')} />
  )
}])

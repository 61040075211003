import {useState} from "react";

export interface OffsetPagination {
  offset: number
  limit: number
}

export interface PageQuery {
  pagination?: OffsetPagination,
  search?: string
  filters?: Record<string, string | undefined>
}

export interface PageResult<T> {
  items: T[],
  total: number,
  pagination: OffsetPagination
  search?: string
  filters?: Record<string, string>
}

export interface GetResult<T> {
  item: T
}

export const toPage: <DATA>(data: DATA[] | undefined) => PageResult<DATA> = (data) => {
  const values = data || []
  return {
    items: values,
    pagination: {offset: 0, limit: values.length},
    total: values.length,
  }
}



export const firstPage: OffsetPagination = {
  limit: 10,
  offset: 0
}

export const allPage: OffsetPagination = {
  limit: 10000,
  offset: 0
}

export const toPagination: <DATA>(data?: DATA[], pagination?: OffsetPagination, filters?: Record<string, string>)
  => PageResult<DATA> = (data, pagination, filters) => {
  const values = data || []
  const nnpagination = pagination || firstPage
  const start = nnpagination.offset
  const end = nnpagination.limit + nnpagination.offset
  return {
    items: data?.slice(start, end) || [],
    pagination: nnpagination,
    total: values.length,
    filters: filters
  }
}

interface UsePageResult<DATA> {
  page: PageResult<DATA>,
  setPageQuery: (pageQuery: PageQuery) => void
}

export const usePage: <DATA>(data?: DATA[], pagination?: OffsetPagination, sort?: (a: DATA, b: DATA)=> number)
=> UsePageResult<DATA> = (data, pagination, sort) => {
  const [pageQuery, setPageQuery] = useState<PageQuery>({
    pagination: pagination || allPage
  })
  const sortedData =  [...data ?? []]
  sort && sortedData.sort(sort)
  const page = toPagination(sortedData, pageQuery.pagination)

  return {page, setPageQuery}
}

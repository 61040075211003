import { push } from "connected-react-router";
import {organizationRoutes, userRoute} from "@tracabois/connect";
import {realizationRoute} from "../App/pages/realization/route";
import {logoutRoute} from "../App/pages/logout/route";
import {ouvrageRoute} from "../App/pages/ouvrage/route";
import {productRoute} from "../App/pages/product/route";

const pushUrl = (url: string) => push(url);

export const goto = {
  ...organizationRoutes.goto,
  ...userRoute.goto,
  ...realizationRoute.goto,
  ...ouvrageRoute.goto,
  ...productRoute.goto,
  ...logoutRoute.goto,
  push: pushUrl,
};

import {Stack} from '@mui/material'
import {BasicProps} from '@komune-io/g2-themes'
import {DividerAdd} from "@tracabois/components";
import {SourceFieldsArray} from "./withSourceFields";
import {SourceFormLine} from "./SourceFormLine";
import Divider from "@mui/material/Divider";
import {FormComposableState} from "@komune-io/g2";
import {Fragment} from "react";

export interface ProductSourceFormProps extends BasicProps {
  formState: FormComposableState
  fields: SourceFieldsArray
  addRawProduct: (fieldName: string) => void
  deleteProduct: (fieldName: string, index: number) => void
}

export const SourceForm = (props: ProductSourceFormProps) => {
  const {
    formState,
    fields,
    addRawProduct,
    deleteProduct,
  } = props
  return (
    <Stack direction={"column"} gap={2}>
      {
        fields.sourceFields.map((productSourceField, index) => (
          <Fragment key={index}>
            { index != 0 && <Divider />}
            <SourceFormLine
              formState={formState}
              sourceFields={productSourceField}
              deleteProduct={() => deleteProduct(fields.fieldName, index)}
            />
          </Fragment>
        ))
      }
      <DividerAdd loading={formState.isLoading} readOnly={formState.readOnly} onClick={() => addRawProduct(fields.fieldName)} text="Ajouter un produit brut"/>
    </Stack>
  )
}

import {useState} from "react";
import {QRCodeIcon} from "../icons";
import {Button} from "@komune-io/g2-components";
import {useTheme} from "@komune-io/g2-themes";
import {certificateConfig} from "@tracabois/domain";
import {QrcodePopup} from "./QrcodePopup";

interface ShortUrlQrcode {
  readonly id: string;
  readonly name?: string;
  readonly qrcode: string;
  readonly shortenUrl: string;
  readonly redirectUrl: string;
}

export interface QrcodeImgProps {
  certificateId: String,
  qrcode: ShortUrlQrcode | undefined | null,
}

export const QrcodeImg = (props: QrcodeImgProps) => {
  const {qrcode, certificateId} = props
  const [open, setOpen] = useState(false)
  const theme = useTheme()
  const redirectUrl = `${certificateConfig.webUrl}/realizations/${certificateId}`

  return (
    <>
      {
        !qrcode
          ? <Button variant="text" onClick={() => setOpen(true)}><QRCodeIcon color={theme.colors.primary}/></Button>
          : <img style={{width: "100px"}} src={qrcode.qrcode} onClick={() => setOpen(true)} alt="Qrcode"/>
      }
      <QrcodePopup
        open={open}
        setOpen={setOpen}
        qrcode={qrcode?.qrcode}
        shortenUrl={qrcode?.shortenUrl}
        redirectUrl={redirectUrl}
      />
    </>
  )
};



import {OrganizationId} from "connect-im";
import {DataSelection} from "../../useDataSelection";
import {withProductField} from "../../ProductMetrics/ProductMetricsField";
import {filterByOrganizationId} from "../../../../../../../referentiels/productType";
import {FormComposableField} from "@tracabois/components";

export interface SourceFieldsArray {
  fieldName: string
  sourceFields: SourceFields[]
}

export interface SourceFields {
  providerFields: FormComposableField
  rowProductField: FormComposableField[]
  metricsField: FormComposableField[]
}


export type RefByIndex = {
  [index: number]: OrganizationId;
};

export const withSourceFields = (
  lines: number[],
  prefix: string,
  selection: DataSelection,
  providers: RefByIndex,
  onProviderChange: (organizationId: string, index: number) => void,
): SourceFieldsArray => {
  const sourceFields = lines.map((_, index) => {
    return useProductSourceFields(
      `${prefix}[${index}]`,
      selection,
      (organizationId) => onProviderChange(organizationId, index),
      providers[index]
    )
  })
  return {
    sourceFields: sourceFields,
    fieldName: prefix
  }
}

export const useProductSourceFields = (
  prefix: string,
  selection: DataSelection,
  onProviderChange: (organizationId: string) => void,
  provider?: OrganizationId
): SourceFields => {
  const {firstTransformationOrganizations, rowProductTypes, woodSpecies, units} = selection
  const providerFields: FormComposableField = {
    key: `${prefix}.provider.id`,
    name: `${prefix}.provider.id`,
    type: 'autoComplete',
    label: 'Fournisseur',
    onChange: (it) => {
      onProviderChange(it)
    },
    params: {
      options: firstTransformationOrganizations.map((item) => ({
        key: item.id,
        label: item.name
      }))
    }
  }

  const filtered = filterByOrganizationId(rowProductTypes, selection.transformationOrganizations, provider)
  const rowProductField = [{
    key: `${prefix}.productType.id`,
    name: `${prefix}.productType.id`,
    type: 'autoComplete',
    label: 'Produit brut',
    params: {
      options: filtered.map((item) => ({
        key: item.id,
        label: item.name
      }))
    }
  }] as FormComposableField[]
  const rowProductMetrics = withProductField(units, woodSpecies, false,`${prefix}.metrics`, {})

  return {
    providerFields,
    rowProductField,
    metricsField: rowProductMetrics.fields,
  }
}

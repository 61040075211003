import { DocumentHandler as G2DocumentHandler, DocumentHandlerProps } from '@komune-io/g2'

export const DocumentHandler = (props: DocumentHandlerProps) => {
    return (
        <G2DocumentHandler
            dropzoneProps={{
                maxSize: 10 * 1024 * 1024
            }}
            {...props}
        />
    )
}

import {RealizationId} from "@tracabois/domain";
import {NavigateFunction} from "react-router-dom";

const view = (realizationId: RealizationId, navigate: NavigateFunction) => {
  return navigate(`/removalZones/${realizationId}/view`);
};

const list = (navigate: NavigateFunction) => {
  return navigate('/removalZones');
};

export const goto = {
  viewRemovalZone: view,
  listRemovalZone: list,
};

import {FiltersComposable as MuiFiltersComposable, FiltersComposableProps} from "@komune-io/g2"
import { useTranslation } from "react-i18next"

export const FiltersComposable = (props: FiltersComposableProps) => {
  const {t} = useTranslation()
  return (
    //@ts-ignore
    <MuiFiltersComposable
      {...props}
      filterStyleProps={{color: "default", variant: "outlined"}}
      responsiveFiltersProps={
        {drawerTitle: t("toFilter"), applyString: t("apply"), clearString: t("clearFilters")}
      }
    />
  )
}

import {CellProps, Column, Row} from '@komune-io/g2-layout'
import {TFunction} from 'i18next'
import {PlatformTableSearchFilters, PlatformPaginatedTable, TableCellTag, TableCellText} from "@tracabois/template";
import {firstPage, OffsetPagination, PageQuery, PageResult} from "@tracabois/utils";
import React from "react";
import {productList, Realization} from "../../model/realizationModel";
import {CompletionIcon} from "@tracabois/components";
import {Stack} from "@mui/material";
import {RealizationStateChip} from "../state/RealizationStateChip";

interface RealizationTableProps {
  page?: PageResult<Realization>
  isLoading?: boolean
  actions?: React.ReactNode
  onPageQueryChange: (page: PageQuery) => void;
  onRowClicked: (row: Row<Realization>) => void;
}

export const RealizationTable = (props: RealizationTableProps) => {
  const {page, isLoading, actions, onPageQueryChange, onRowClicked} = props

  const onSearch = (search?: string) => {
    onPageQueryChange({
      pagination: page?.pagination || firstPage,
      search: search
    })
  }
  const onPaginationChange = (pagination: OffsetPagination) => {
    onPageQueryChange({
      pagination: pagination,
      search: page?.search
    })
  }
  const filter = (<PlatformTableSearchFilters actions={actions} onSubmit={(props) => onSearch(props.search)}/>)
  return (
    <Stack
      sx={{
        '& .iconColumn': {
          width: '30px'
        },
      }}
    >
      <PlatformPaginatedTable<Realization>
        page={page}
        columnsDefinition={getColumns}
        isLoading={isLoading}
        actions={filter}
        onPageChange={onPaginationChange}
        onRowClicked={onRowClicked}
      />
    </Stack>
  )
}

const getColumns = (t: TFunction): Column<Realization>[] => ([
  {
    Header: " ",
    // @ts-ignore
    Cell: ({row}: CellProps<Realization>) => (
      <CompletionIcon completion={row.original.indicators.completion || 0}/>
    ),
    className: "iconColumn"
  }, {
    Header: `${t("realization.name", "Nom Réalisation")}`,
    // @ts-ignore
    Cell: ({row}: CellProps<Realization>) => (
      <TableCellText main={row.original.name} second={""}/>
    )
  }, {
    Header: `${t("realization.status", "Status")}`,
    // @ts-ignore
    Cell: ({row}: CellProps<Realization>) => {
      return (
        <TableCellTag
          main={<RealizationStateChip item={row.original} editable={false}/>}
          second={`Créé le ${new Date(row.original.creationDate).toLocaleDateString("fr-FR")}`}
        />
      )
    }
  },
  {
    Header: `${t("realization.acteurs", "Produits")}`,
    // @ts-ignore
    Cell: ({row}: CellProps<Realization>) => (
      <TableCellText main={productList(row.original)}/>
    )
  },
  {
    Header: `${t("realization.volumes", "Volumes")}`,
    // @ts-ignore
    Cell: ({row}: CellProps<Realization>) => (
      <TableCellText main={row.original.indicators.wood.toString()} second={"m3"}/>
    )
  }
])

import {Stack} from "@mui/material";

export interface PlatformPageHeaderActionProps {
  children?: React.ReactNode
}

export const PlatformPageObjectHeaderAction = (props: PlatformPageHeaderActionProps) => {
  const {children} = props
  return (
    <Stack width="100%" height="100%" direction="row" justifyContent="flex-end" alignItems="flex-start" spacing={2}>
      {children}
    </Stack>
  )
}

import {useState} from "react";
import {ProductTypeFormPopup} from "../form/ProductTypeFormPopup";
import {ProductTypeTable} from "../table/ProductTypeTable";

import {Button} from '@komune-io/g2-components'
import {useTranslation} from "react-i18next";
import {Row} from "@komune-io/g2-layout";
import {
  ProductType,
  ProductTypeCreateCommand,
  ProductTypeDeleteCommand,
  ProductTypeUpdateCommand
} from "../../model/ProductType";
import {
  useProductTypeAllQuery,
  useProductTypeCreateMutation, useProductTypeDeleteMutation,
  useProductTypeUpdateMutation
} from "../../api/ProductTypeApi";
import {useGetOrganizationTypeAllQuery} from "../../../organizationType";
import {firstPage} from "@tracabois/utils";
import {useOuvrageTypeAllQuery} from "../../../ouvrageType";
import {PlatformSubSection} from "@tracabois/template";

interface ProductTypeSectionProps {
  title: string
}

export const ProductTypeSection = (props: ProductTypeSectionProps) => {
  const {title} = props
  const [selectedItem, setSelectedItem] = useState<Partial<ProductType> | undefined>(undefined)
  const {t} = useTranslation()
  const productTypePageQuery = useProductTypeAllQuery(undefined, firstPage);
  const organizationTypePageQuery = useGetOrganizationTypeAllQuery();
  const useOuvrageTypePageQuery = useOuvrageTypeAllQuery()
  const [createProductType] = useProductTypeCreateMutation()
  const [updateProductType] = useProductTypeUpdateMutation()
  const [deleteProductType] = useProductTypeDeleteMutation()

  const closePopup = () => {
    setSelectedItem(undefined)
  }

  const handleCreate = async (item: ProductTypeCreateCommand) => {
    await createProductType(item)
    closePopup()
    productTypePageQuery.refetch()
    return true
  }
  const handleUpdate = async (item: ProductTypeUpdateCommand) => {
    await updateProductType(item)
    closePopup()
    productTypePageQuery.refetch()
    return true
  }
  const handleDelete = async (item: ProductTypeDeleteCommand) => {
    await deleteProductType(item)
    closePopup()
    productTypePageQuery.refetch()
    return true
  }
  const clickHandler = (wood: Row<ProductType>) => {
    setSelectedItem(wood.original)
  }
  const actions = (<Button color="primary"
                           onClick={() => setSelectedItem({})}>{t("productType.action.create", "Ajouter un type")}</Button>)
  return (
    <PlatformSubSection title={title}>
      <ProductTypeTable
        page={productTypePageQuery.data}
        onPageQueryChange={productTypePageQuery.setPageQuery}
        onRowClicked={clickHandler}
        isLoading={productTypePageQuery.isLoading}
        actions={actions}
      />
      <ProductTypeFormPopup
        organizationTypes={organizationTypePageQuery.data?.items || []}
        ouvrageTypes={useOuvrageTypePageQuery.data?.items || []}
        open={!!selectedItem} item={selectedItem || {}}
        onCreate={handleCreate}
        onUpdate={handleUpdate}
        onDelete={handleDelete}
        onCancel={closePopup}
      />
    </PlatformSubSection>
  )
}

import {useCallback, useEffect, useMemo, useState} from 'react'
import {
  RemovalZoneCertification,
  RemovalZoneCertificationCreateCommand,
  RemovalZoneCertificationDeleteCommand,
  RemovalZoneCertificationUpdateCommand
} from "../../model/RemovalZoneCertification";
import {FormComposable, FormComposableField, PopupFormAction} from "@tracabois/components";
import {DropZonePicture, useDropZonePicture} from "@komune-io/g2-fs";
import {useFormComposable} from "@komune-io/g2";
import {PlatformSectionForm} from "@tracabois/template/src/platform/PlatformSectionForm";
import { externalFileUrl } from '@tracabois/domain';

export interface RemovalZoneCertificationFormProps {
  item?: Partial<RemovalZoneCertification>
  onCreate: (item: RemovalZoneCertificationCreateCommand) => Promise<boolean> | boolean
  onUpdate: (item: RemovalZoneCertificationUpdateCommand) => Promise<boolean> | boolean
  onDelete: (item: RemovalZoneCertificationDeleteCommand) => Promise<boolean> | boolean
  onCancel: () => void
  readonly?: boolean
}

export const RemovalZoneCertificationForm = (props: RemovalZoneCertificationFormProps) => {
  const {
    item,
    onCreate,
    onUpdate,
    onCancel,
    onDelete,
    readonly = false,
  } = props
  const [feedback, setFeedback] = useState<boolean | undefined>(undefined)
  const imageDropZone = useDropZonePicture(externalFileUrl(item?.img));
  useEffect(() => {
    setFeedback(undefined)
  }, [onCreate, onUpdate])

  const onSubmitMemoized = useCallback(
    async (values: RemovalZoneCertificationCreateCommand|RemovalZoneCertificationUpdateCommand) => {
      if (!item?.id) {
        const feedback = await onCreate({
          ...item,
          ...values,
          img: imageDropZone.image
        })
        setFeedback(feedback)
        return feedback
      } else {
        const feedback = await onUpdate({
          ...item,
          ...values,
          img: imageDropZone.image,
          id: item.id
        })
        setFeedback(feedback)
        return feedback
      }
    },
    [onCreate, onUpdate, imageDropZone.image, item]
  )

  const details = useMemo(
    (): FormComposableField[] => [
      {
        key: 'name',
        name: 'name',
        type: 'textField',
        label: 'Libellé certification',
        defaultValue: item?.name,
        params: {
          disabled: readonly
        },
        validator: (value?: string) => {
          const trimmed = (value ?? '').trim()
          if (!trimmed) return 'Vous devez renseigner le nom' as string
          return undefined
        }
      },

    ],
    [readonly]
  )
  const handleOnDelete = item?.id ? (() => onDelete({id: item.id!!})) : undefined

  const formState = useFormComposable({
    onSubmit: onSubmitMemoized
  })

  return (
    <PlatformSectionForm>
      <DropZonePicture {...imageDropZone}/>
      <FormComposable
        fields={details}
        formState={formState}
      />
      <PopupFormAction
        formState={formState}
        onCancel={onCancel}
        feedback={feedback}
        readonly={readonly}
        isCreation={!item?.id}
        onDelete={handleOnDelete}
      />
    </PlatformSectionForm>
  )
}

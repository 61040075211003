import {PopupForm, UpdateStateAction} from "@tracabois/components";
import {ObjectStateForm} from "./ObjectStateForm";
import {useCallback} from "react";

export interface ObjectStateFormPopupProps {
  stateAction: UpdateStateAction
  open: boolean
  onClose: () => void
}

export const ObjectStateFormPopup = (props: ObjectStateFormPopupProps) => {
  const {stateAction, open, onClose} = props
  const onSubmit = useCallback(async () => {
    const value = await stateAction.action()
    onClose()
    return value
  }, [stateAction, onClose])
  return (
    <PopupForm open={open} onClose={onClose} title={stateAction.label}>
      <ObjectStateForm
        onCancel={() => {
          onClose()
          return true;
        }}
        onSubmit={onSubmit}/>
    </PopupForm>
  )
};

import {useCallback, useMemo} from "react";
import {canFinishProduct, canUnlockProduct, Product, ProductModel, toProductState} from "../../model/product";
import {useTranslation} from "react-i18next";
import {UpdateStateAction} from "@tracabois/components";
import {useProductFinishMutation, useProductUnlockMutation} from "../../api";

export const useProductStateAction =
  (product?: Product | ProductModel, onStateChanged?: () => void): UpdateStateAction | undefined => {
    const state = toProductState(product)
    const {t} = useTranslation()
    const [productUnlock] = useProductUnlockMutation()
    const [productFinish] = useProductFinishMutation()
    const finishAction = useCallback(async (): Promise<boolean> => {
      product?.id && await productFinish({id: product.id})
      onStateChanged && onStateChanged()
      return true;
    }, [product?.id, onStateChanged])

    const unlockAction = useCallback(async (): Promise<boolean> => {
      product && await productUnlock({id: product.id})
      onStateChanged && onStateChanged()
      return true;
    }, [product?.id, state])

    return useMemo(() => {
      if (product && canFinishProduct(state))
        return {
          action: finishAction,
          label: t("product.state.finish.action", "Terminer le produit")
        }
      if (product && canUnlockProduct(state))
        return {
          action: unlockAction,
          label: t("product.state.unlock.action", "Permettre l'édition du produit")
        }

      return undefined
    }, [product, finishAction, unlockAction])
  }

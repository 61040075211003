import {CellProps, Column, Row} from '@komune-io/g2-layout'
import {TFunction} from 'i18next'
import {firstPage, OffsetPagination, PageQuery, PageResult} from "@tracabois/utils";
import {PlatformTableSearchFilters, PlatformPaginatedTable, TableCellText, TableCellTag} from "@tracabois/template";
import React from "react";
import {Ouvrage} from "../../model/ouvrage";
import {OuvrageStateChip} from "../state/OuvrageStateChip";

interface OuvrageTableProps {
  page?: PageResult<Ouvrage>
  isLoading?: boolean
  actions: React.ReactNode
  onPageQueryChange: (page: PageQuery) => void;
  onRowClicked: (row: Row<Ouvrage>) => void;
}

export const OuvrageTable = (props: OuvrageTableProps) => {
  const {page, isLoading, onPageQueryChange, onRowClicked, actions} = props

  const onSearch = (search?: string) => {
    onPageQueryChange({
      pagination: page?.pagination || firstPage,
      search: search
    })
  }
  const onPaginationChange = (pagination: OffsetPagination) => {
    onPageQueryChange({
      pagination: pagination,
      search: page?.search
    })
  }
  const filter = (<PlatformTableSearchFilters actions={actions} onSubmit={(props) => onSearch(props.search)}/>)

  return (
    <PlatformPaginatedTable<Ouvrage>
      page={page}
      columnsDefinition={getColumns}
      isLoading={isLoading}
      actions={filter}
      onPageChange={onPaginationChange}
      onRowClicked={onRowClicked}
    />
  )
}

const getColumns = (t: TFunction): Column<Ouvrage>[] => ([{
  Header: `${t("ouvrage.name", "Nom Ouvrage")}`,
  // @ts-ignore
  Cell: ({row}: CellProps<Ouvrage>) => (
    <TableCellText main={row.original.name}/>
  )
},{
  Header: `${t("ouvrage.realization.name", "Réalisation")}`,
  // @ts-ignores
  Cell: ({row}: CellProps<Ouvrage>) => (
    <TableCellText main={row.original.realization?.name ?? ""}/>
  )
}, {
  Header: `${t("ouvrage.status", "Status")}`,
  // @ts-ignore
  Cell: ({row}: CellProps<Ouvrage>) => {
    return (
      <TableCellTag
        main={<OuvrageStateChip item={row.original} editable={false}/>}
        second={`Créé le ${new Date(row.original.creationDate).toLocaleDateString("fr-FR")}`}
      />
    )
  }
}, {
  Header: `${t("ouvrage.product", "Produits")}`,
  // @ts-ignore
  Cell: ({row}: CellProps<Ouvrage>) => (
    <TableCellText
      main={productList(row.original)}
      second={woodList(row.original)}
    />
  )
}, {
  Header: `${t("ouvrage.volumes", "Volume")}`,
  // @ts-ignore
  Cell: ({row}: CellProps<Ouvrage>) => (
    <TableCellText main={`${row.original.indicators.wood} m3`}/>
  )
}])

const productList = (ouvrage: Ouvrage): string => {
  return  ouvrage.composedBy.map( (it) => it.name).join(", ")
}

const woodList = (ouvrage: Ouvrage): string => {
  return  ouvrage.species.map( (it) => it.name).join(", ")
}

import {org} from "boislocal-platform-platform-api-api-js-export";
import {S2StateProp} from "@tracabois/utils";
import {AuthServices} from "@tracabois/connect";
import {AuthServiceAdditional} from "@komune-io/g2-providers";

export type RealizationCreateCommand = org.boislocal.platform.s2.realization.domain.command.RealizationCreateCommandDTO
export type RealizationCreatedEvent = org.boislocal.platform.s2.realization.domain.command.RealizationCreatedEventDTO

export type RealizationUpdateCommand = org.boislocal.platform.s2.realization.domain.command.RealizationUpdateCommandDTO
export type RealizationUpdatedEvent = org.boislocal.platform.s2.realization.domain.command.RealizationUpdatedEventDTO

export type RealizationDeleteCommand = org.boislocal.platform.s2.realization.domain.command.RealizationDeleteCommandDTO
export type RealizationDeletedEvent = org.boislocal.platform.s2.realization.domain.command.RealizationDeletedEventDTO

export type RealizationFinishCommand = org.boislocal.platform.s2.realization.domain.command.RealizationFinishCommandDTO
export type RealizationFinishedEvent = org.boislocal.platform.s2.realization.domain.command.RealizationFinishedEventDTO

export type RealizationUnlockCommand = org.boislocal.platform.s2.realization.domain.command.RealizationFinishCommandDTO
export type RealizationUnlockedEvent = org.boislocal.platform.s2.realization.domain.command.RealizationFinishedEventDTO

export type RealizationAssignMoeCommand = org.boislocal.platform.s2.realization.domain.command.RealizationAssignMoeCommandDTO
export type RealizationAssignedMoeEvent = org.boislocal.platform.s2.realization.domain.command.RealizationAssignedMoeEventDTO

export type RealizationRemoveMoeCommand = org.boislocal.platform.s2.realization.domain.command.RealizationRemoveMoeCommandDTO
export type RealizationRemovedMoeEvent = org.boislocal.platform.s2.realization.domain.command.RealizationRemovedMoeEventDTO

export type RealizationGetQuery = org.boislocal.platform.f2.realization.query.domain.query.RealizationGetQueryDTO
export type RealizationGetResponse = org.boislocal.platform.f2.realization.query.domain.query.RealizationGetResponseDTO

export type Realization = org.boislocal.platform.f2.realization.query.domain.model.RealizationDTO

export type RealizationModel = org.boislocal.platform.s2.realization.domain.model.RealizationModelDTO
export type RealizationRef = org.boislocal.platform.s2.realization.domain.model.RealizationRefDTO

export type RealizationCertificationIdLink = org.boislocal.platform.s2.realization.domain.model.RealizationCertificationIdLinkDTO
export type RealizationCertificationLink = org.boislocal.platform.s2.realization.domain.model.RealizationCertificationLinkDTO


export type RealizationIndicator = org.boislocal.platform.s2.stats.domain.model.RealizationIndicatorsDTO
export type TransformationActor = org.boislocal.platform.s2.realization.domain.model.TransformationActorDTO
export type RealizationState = org.boislocal.platform.s2.realization.domain.automate.RealizationState

export const canTerminateRealization = (status?: S2StateProp): boolean => {
  return status?.key == "Exists"
}


export const isEditableRealization = (realization?: RealizationModel | Realization): boolean => {
  // @ts-ignore
  return realization?.status == "Exists"
}

export const isEditableRealizationMoe = (service: AuthServiceAdditional<AuthServices>, realization?: RealizationModel | Realization): boolean => {
  // @ts-ignore
  return realization?.status == "Exists" && service.isMarque()
}

export const canUnlockRealization = (status?: S2StateProp): boolean => {
  return status?.key == "Finished" || status?.key == "Deleted"
}


export const toRealizationS2State = (entity?: RealizationModel | Realization): S2StateProp | undefined => {
  const statue = entity?.status
  // @ts-ignore
  if (statue == "Exists") {
    return {
      key: "Exists",
      name: "En cours",
      color: "info",
    }
  }
  // @ts-ignore
  if (statue == "Finished") {
    return {
      key: "Finished",
      name: "Terminé",
      color: "success"
    }
  } else {
    return {
      key: "Deleted",
      name: "Supprimé",
      color: "error"
    }
  }
}

export type RealizationId = string

export const defaultRealizationIndicator: RealizationIndicator = {
  artIndex: "0",
  co2: 0,
  wood: 0,
  route: 0,
  completion: 10
}

export const productList = (realization: RealizationModel | Realization): string => {
  return realization.actors.map((it) => it.product).join(", ")
}

export const realizationToUpdateRealizationCommand = (realization: Realization): RealizationUpdateCommand => {
  return  {
    id: realization?.id,
    name: realization?.name,
    coordinates: realization?.coordinates,
    artIndex: realization?.indicators?.artIndex,
    redirectPath: realization?.qrcode?.name
  }
}

import {PlatformRoute} from "@tracabois/template";
import {RemovalZonesListPage} from "../list";
import {RemovalZoneViewPage} from "../view";

export const RemovalZonesRoutes: PlatformRoute[] = [
    {
        path: "/removalZones",
        components: <RemovalZonesListPage />
    },  {
        path: "/removalZones/:removalZoneId/view",
        components: <RemovalZoneViewPage />
    },
]

import {DataSelection} from "../../useDataSelection";
import {withProductField} from "../../ProductMetrics/ProductMetricsField";
import {FormComposableField} from "@tracabois/components";

export interface OriginFieldsArray {
  fieldName: string
  fields: OriginFields[]
}
export interface OriginFields {
  origin: FormComposableField
  metricsField: FormComposableField[]
}

export const withOriginFields = (
  lines: number[],
  prefix: string,
  selection: DataSelection,
): OriginFieldsArray => {
  const fields = lines.map((_, index) => {
    return withOriginZoneFields(`${prefix}[${index}]`, selection)
  })
  return {
    fieldName: prefix,
    fields: fields
  }
}

export const withOriginZoneFields = (
  prefix: string,
  selection: DataSelection,
): OriginFields => {
  const {removalZones, woodSpecies, units} = selection
  const rowProductMetrics = withProductField(units, woodSpecies, false,`${prefix}.metrics`, {})
  const origin: FormComposableField = {
    key: `${prefix}.removalZone.id`,
    name: `${prefix}.removalZone.id`,
    type: 'autoComplete',
    label: 'Origine du bois rond',
    params: {
      options: removalZones.map((item) => ({
        key: item.id,
        label: item.name
      }))
    }
  }
  return {
    origin: origin,
    metricsField: rowProductMetrics.fields,
  }
}

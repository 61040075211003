import {org} from "boislocal-platform-platform-api-api-js-export";
import {S2StateProp} from "@tracabois/utils";

export type OuvrageState = org.boislocal.platform.s2.ouvrage.domain.automate.OuvrageState
export type OuvrageCreateCommand = org.boislocal.platform.s2.ouvrage.domain.command.OuvrageCreateCommandDTO
export type OuvrageCreatedEvent = org.boislocal.platform.s2.ouvrage.domain.command.OuvrageCreatedEventDTO

export type OuvrageUpdateCommand = org.boislocal.platform.s2.ouvrage.domain.command.OuvrageUpdateCommandDTO
export type OuvrageUpdatedEvent = org.boislocal.platform.s2.ouvrage.domain.command.OuvrageUpdatedEventDTO

export type OuvrageDeleteCommand = org.boislocal.platform.s2.ouvrage.domain.command.OuvrageDeleteCommandDTO
export type OuvrageDeletedEvent = org.boislocal.platform.s2.ouvrage.domain.command.OuvrageDeletedEventDTO

export type OuvrageFinishCommand = org.boislocal.platform.s2.ouvrage.domain.command.OuvrageFinishCommandDTO
export type OuvrageFinishedEvent = org.boislocal.platform.s2.ouvrage.domain.command.OuvrageFinishedEventDTO

export type OuvrageUnlockCommand = org.boislocal.platform.s2.ouvrage.domain.command.OuvrageUnlockCommandDTO
export type OuvrageUnlockedEvent = org.boislocal.platform.s2.ouvrage.domain.command.OuvrageUnlockedEventDTO

export type OuvrageGetQuery = org.boislocal.platform.f2.ouvrage.query.domain.query.OuvrageGetQueryDTO
export type OuvrageGetResponse = org.boislocal.platform.f2.ouvrage.query.domain.query.OuvrageGetResponseDTO

export type Ouvrage = org.boislocal.platform.f2.ouvrage.query.domain.model.OuvrageDTO
export type OuvrageModel = org.boislocal.platform.s2.ouvrage.domain.model.OuvrageModelDTO
export type OuvrageRef = org.boislocal.platform.s2.ouvrage.domain.model.OuvrageRefDTO
export type OuvrageIndicator = org.boislocal.platform.s2.stats.domain.model.OuvrageIndicatorsDTO

export type OuvrageId = string


export const canFinishOuvrage = (status?: S2StateProp): boolean => {
  return status?.key == "Exists"
}

export const isEditableOuvrage = (realization?: Ouvrage): boolean => {
  // @ts-ignore
  return realization?.status == "Exists"
}

export const isOuvrageFinished = (realization?: Ouvrage): boolean => {
  // @ts-ignore
  return realization?.status == "Finished"
}

export const canUnlockOuvrage = (status?: S2StateProp): boolean => {
  return status?.key == "Finished" || status?.key == "Deleted"
}

export const toOuvrageState = (entity?: Ouvrage | OuvrageModel): S2StateProp => {
  const statue = entity?.status
  // @ts-ignore
  if(statue == "Exists") {
    return {
      key: "Exists",
      name: "En cours",
      color: "info",
    }
  }
  // @ts-ignore
  if(statue == "Finished") {
    return {
      key: "Finished",
      name: "Terminé",
      color: "success"
    }
  } else {
    return {
      key: "Deleted",
      name: "Supprimé",
      color: "error"
    }
  }
}

export const ouvrageToUpdateOuvrageCommand = (ouvrage: Ouvrage): OuvrageUpdateCommand => {
  return  {
    id: ouvrage.id,
    name: ouvrage.name,
    description: ouvrage.description,
  }
}

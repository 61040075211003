import {PopupForm} from "@tracabois/components";
import {RealizationForm, RealizationFormLabel} from "./RealizationForm";
import {useTranslation} from "react-i18next";
import {
  RealizationModel,
  RealizationCreateCommand,
  RealizationId,
  RealizationUpdateCommand, Realization
} from "../../../model/realizationModel";
import {useRealizationCreateMutation, useRealizationUpdateMutation} from "../../../api";
import {useCallback} from "react";

type RealizationFormPopupType = "edit" | "create"

export interface RealizationFormPopupProps {
  onSaved: (item: RealizationId) => void
  item?: RealizationModel | Realization
  type: RealizationFormPopupType
  open: boolean
  onClose: () => void
}

export const RealizationFormPopup = (props: RealizationFormPopupProps) => {
  const {onSaved, item, type, open, onClose} = props
  const {t} = useTranslation()

  const [realizationCreate] = useRealizationCreateMutation()
  const [realizationUpdate] = useRealizationUpdateMutation()

  const handleSubmit = async (command: RealizationCreateCommand | RealizationUpdateCommand): Promise<boolean> => {
    // @ts-ignore
    if (!command.id) {
      return handleCreate(command)
    } else {
      // @ts-ignore
      return handleUpdate(command)
    }
  }

  const handleCreate = async (command: RealizationCreateCommand) => {
    const result = await realizationCreate(command)
    // @ts-ignore
    const id = result.data.id
    onSaved(id)
    return true
  }

  const handleUpdate = useCallback(async (command: RealizationUpdateCommand): Promise<boolean> => {
    command && await realizationUpdate({
      ...command,
      id: command.id,
    })
    return true;
  }, [realizationUpdate])

  const title = type == "edit" ? t("realization.form.edit.title", "Edition d’une réalisation") : t("realization.form.create.title", "Création d’une réalisation")
  const label: RealizationFormLabel = {
    submit: t("realization.form.save.title", "Enregistrer")
  }
  return (
    <PopupForm open={open} onClose={onClose} title={title}>
      <RealizationForm
        item={item}
        onCancel={() => {
          onClose()
          return true;
        }}
        labels={label}
        onSubmit={handleSubmit}/>
    </PopupForm>
  )
};

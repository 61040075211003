import {PopupForm} from "@tracabois/components";
import {ProductCreateForm} from "./ProductCreateForm";
import {useTranslation} from "react-i18next";
import {ProductCreateCommand} from "../../../model/product";
import {useProductTypeAllQuery} from "../../../../../referentiels/productType";
import {TransformationRoles, useExtendedAuth, useGetOrganizationRefs} from "@tracabois/connect";
import {OuvrageId} from "../../../../ouvrage";
import {useState} from "react";

export interface ProductCreatePopupProps {
  onSubmit: (item: ProductCreateCommand) => Promise<boolean> | boolean
  open: boolean
  onClose: () => void
  ouvrageId?: OuvrageId
}

export const ProductCreateFormPopup = (props: ProductCreatePopupProps) => {
  const {onSubmit, open, onClose, ouvrageId} = props
  const {t} = useTranslation()
  const {service} = useExtendedAuth()
  const [organization, setOrganization] = useState<string | undefined>(service.getMemberOf())

  const productTypePageQuery = useProductTypeAllQuery(organization);
  const getOrganizationRefs = useGetOrganizationRefs({
    roles: TransformationRoles
  })
  return (
    <PopupForm open={open} onClose={onClose} title={t("product.form.create.title", "Création d'un produit")}>
      <ProductCreateForm
        onCancel={() => {
          onClose()
          return true;
        }}
        onSubmit={onSubmit}
        ouvrageId={ouvrageId}
        productTypes={productTypePageQuery.data.items}
        organizationRefs={getOrganizationRefs.data || []}
        onOrganizationChange={organizationRefs => setOrganization(organizationRefs)}
      />
    </PopupForm>
  )
};

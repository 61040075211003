import {PopupForm, DividerAdd} from "@tracabois/components";
import {RealizationMoeCreateForm} from "./RealizationMoeCreateForm";
import {useTranslation} from "react-i18next";
import {useMemo, useState} from "react";
import {RealizationMoeAssignForm} from "./RealizationMoeAssignForm";
import Box from "@mui/material/Box";
import {RealizationAssignMoeCommand, RealizationId} from "../../../model/realizationModel";

const useLabels = () => {
  const {t} = useTranslation()
  return useMemo(() => ({
    create: t("realization.form.create.title", "Créer un donneur d’ordre"),
    select: t("realization.form.create.title", "Sélectionner donneur d’ordre"),
    title: t("realization.form.assign.title", "Donneur d’ordre"),
    details: t("product.view.details.label", "Détail"),
  }), [])
}

export interface RealizationMoeAddFormPopupProps {
  onSubmit: (item: RealizationAssignMoeCommand) => Promise<boolean> | boolean
  open: boolean
  onClose: () => void
  realizationId: RealizationId
}

export const RealizationMoeAddFormPopup = (props: RealizationMoeAddFormPopupProps) => {
  const {onSubmit, open, onClose, realizationId} = props
  const labels = useLabels()
  const [showCreation, setShowCreation] = useState<boolean>(false)
  return (
    <PopupForm open={open} onClose={onClose} title={labels.title} >
      <Box minWidth={500}>
      {!showCreation && (<>
        <DividerAdd loading={false} readOnly={false} onClick={() => setShowCreation(true)} icon={<>{labels.create}</>} />
        <RealizationMoeAssignForm
          realizationId={realizationId}
          onCancel={() => {
            onClose()
            return true;
          }}
          onSubmit={onSubmit}
        />
      </>)}

      {showCreation && (<>
        <DividerAdd loading={false} readOnly={false} onClick={() => setShowCreation(false)} icon={<>{labels.select}</>}/>
        <RealizationMoeCreateForm
          realizationId={realizationId}
          onCancel={() => {
            onClose()
            return true;
          }}
          onSubmit={onSubmit}/>
      </>)}
      </Box>
    </PopupForm>
  )
}

import {SubOrganizationForm, SubOrganizationFormProps} from "./SubOrganizationForm";
import {PopupForm} from "@tracabois/components";

export interface SubOrganizationFormPopupProps extends SubOrganizationFormProps {
  open: boolean;
}

export const SubOrganizationFormPopup = (props: SubOrganizationFormPopupProps) => {
  const {item, open, ...woodSpeciesFormPopupProps} = props
  const title = !item?.id ? "Création d'un établissement" : "Établissement"

  return (
    <>
      <PopupForm open={open} onClose={woodSpeciesFormPopupProps.onCancel} title={title}>
        <SubOrganizationForm
          item={item}
          {...woodSpeciesFormPopupProps}
        />
      </PopupForm>
    </>
  )
};

import {config} from "../../../config";
import {createApi} from "@reduxjs/toolkit/dist/query/react";
import {PageQuery, PageResult} from "@tracabois/utils";
import {
  Ouvrage,
  OuvrageCreateCommand,
  OuvrageCreatedEvent,
  OuvrageDeleteCommand,
  OuvrageDeletedEvent, OuvrageFinishCommand, OuvrageFinishedEvent,
  OuvrageGetQuery,
  OuvrageGetResponse, OuvrageRef, OuvrageUnlockCommand, OuvrageUnlockedEvent,
  OuvrageUpdateCommand,
  OuvrageUpdatedEvent
} from "../../ouvrage";
import {authQuery} from "../../../utils/authQuery";

export const OuvrageApi = createApi({
  reducerPath: 'ouvrageApi',
  baseQuery: authQuery({baseUrl: config.baseUrl}),
  tagTypes: ['Ouvrage'],
  endpoints: (builder) => ({
    getOuvrageById: builder.query<Ouvrage | undefined, OuvrageGetQuery>({
      query: (body: OuvrageGetQuery) => ({
        url: `ouvrageGet`,
        method: 'POST',
        body: body
      }),
      transformResponse: (response: OuvrageGetResponse[]) => response[0]?.item as Ouvrage,
      providesTags: ['Ouvrage'],
    }),
    getOuvragesPage: builder.query<PageResult<Ouvrage>, PageQuery>({
      query: (page: PageQuery) => ({
        url: `${config.baseUrl}/ouvragePage`,
        method: 'POST',
        body: page
      }),
      transformResponse: (response: PageResult<Ouvrage>[]) => response[0],
      providesTags: ['Ouvrage'],
    }),
    getOuvragesRefs: builder.query<OuvrageRef[], undefined>({
      query: () => ({
        url: `${config.baseUrl}/ouvrageRefs`,
        method: 'POST',
      }),
      providesTags: ['Ouvrage'],
    }),
    ouvrageCreate: builder.mutation<OuvrageCreatedEvent, OuvrageCreateCommand>({
      query: (cmd: OuvrageCreateCommand) => ({
        url: `${config.baseUrl}/ouvrageCreate`,
        method: 'POST',
        body: cmd
      }),
      transformResponse: (response: OuvrageCreatedEvent[]) => response[0],
      invalidatesTags: ['Ouvrage'],
    }),
    ouvrageUpdate: builder.mutation<OuvrageUpdatedEvent, OuvrageUpdateCommand>({
      query: (cmd: OuvrageUpdateCommand) => ({
        url: `${config.baseUrl}/ouvrageUpdate`,
        method: 'POST',
        body: cmd
      }),
      transformResponse: (response: OuvrageUpdatedEvent[]) => response[0],
      invalidatesTags: ['Ouvrage'],
    }),
    ouvrageDelete: builder.mutation<OuvrageDeletedEvent, OuvrageDeleteCommand>({
      query: (cmd: Ouvrage) => ({
        url: `${config.baseUrl}/ouvrageDelete`,
        method: 'POST',
        body: cmd
      }),
      transformResponse: (response: OuvrageDeletedEvent[]) => response[0],
      invalidatesTags: ['Ouvrage'],
    }),
    ouvrageUnlock: builder.mutation<OuvrageUnlockedEvent, OuvrageUnlockCommand>({
      query: (cmd: Ouvrage) => ({
        url: `${config.baseUrl}/ouvrageUnlock`,
        method: 'POST',
        body: cmd
      }),
      transformResponse: (response: OuvrageDeletedEvent[]) => response[0],
      invalidatesTags: ['Ouvrage'],
    }),
    ouvrageFinish: builder.mutation<OuvrageFinishedEvent, OuvrageFinishCommand>({
      query: (cmd: Ouvrage) => ({
        url: `${config.baseUrl}/ouvrageFinish`,
        method: 'POST',
        body: cmd
      }),
      transformResponse: (response: OuvrageFinishedEvent[]) => response[0],
      invalidatesTags: ['Ouvrage'],
    })
  }),
  refetchOnFocus: true,
  refetchOnMountOrArgChange: true
})

export const {
  useGetOuvrageByIdQuery,
  useGetOuvragesPageQuery,
  useGetOuvragesRefsQuery,
  useOuvrageCreateMutation,
  useOuvrageUpdateMutation,
  useOuvrageDeleteMutation,
  useOuvrageUnlockMutation,
  useOuvrageFinishMutation,
} = OuvrageApi

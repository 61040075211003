import {PlatformRoute} from "@tracabois/template";
import {ProductListPage} from "../list";
import {ProductCreatePage} from "../create";
import {ProductEditPage} from "../edit";

export const ProductRoutes: PlatformRoute[] = [
    {
        path: "/products",
        components: <ProductListPage />
    }, {
        path: "/products/create",
        components: <ProductCreatePage />
    }, {
        path: "/products/:productId/view",
        components: <ProductEditPage />
    }
]

import { createSelector } from "reselect";
import {State} from "../store";

const getState = (state: State) => state.router;

const getCurrentLocation = createSelector(
  [getState],
  (state) => state.location
);

export const selectors = {
  getCurrentLocation: getCurrentLocation,
};

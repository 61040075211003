import {Row} from "@komune-io/g2-layout";
import {usePopup} from "@tracabois/components";
import {
  Ouvrage,
  OuvrageCreateCommand,
  OuvrageCreatedEvent,
  OuvrageCreateFormPopup,
  useOuvrageCreateMutation
} from "../../../../ouvrage";
import {PageResult, toPage} from "@tracabois/utils";
import {Realization} from "../../../model/realizationModel";
import {useCallback, useMemo} from "react";
import {RealizationOuvrageTable} from "../../table/RealizationOuvrageTable";
import {useTranslation} from "react-i18next";
import {PlatformSubSection} from "@tracabois/template";
import {Action} from "@komune-io/g2";

interface RealizationOuvrageSectionProps {
  realization?: Realization
  isLoading: boolean
  onOuvrageAdded?: (result: OuvrageCreatedEvent) => void
  onOuvrageClicked?: (item: Ouvrage) => void
  editable?: boolean
  title: string
}

const useLabels = () => {
  const {t} = useTranslation()
  return useMemo(() => ({
    addOuvrage: t("realization.ouvrage.add", "Ajouter un ouvrage"),
  }), [t])
}

export const RealizationOuvrageSection = (props: RealizationOuvrageSectionProps) => {
  const {realization, isLoading, onOuvrageAdded, onOuvrageClicked, editable, title} = props
  const labels = useLabels()
  const ouvragePage: PageResult<Ouvrage> = useMemo(() => toPage(realization?.ouvrages), [realization?.ouvrages])
  const ouvrageActorPopup = usePopup()
  const [ouvrageCreateMutation] = useOuvrageCreateMutation()
  const actions: Action[] = [{
    key: 'ouvrageCreate',
    showIf: () => editable || true,
    onClick: ouvrageActorPopup.open,
    label: labels.addOuvrage
  }]
  const ouvrageActions = useCallback(async (command: OuvrageCreateCommand) => {
    const result = await ouvrageCreateMutation(command)
    // @ts-ignore
    onOuvrageAdded && onOuvrageAdded(result.data)
    ouvrageActorPopup.close()
    return true
  }, [ouvrageCreateMutation, ouvrageActorPopup])
  const noPageChange = () => {}

  const handleOuvrageRowClick = useCallback((row: Row<Ouvrage>) => {
    onOuvrageClicked && onOuvrageClicked(row.original)
  }, [])

  return (
    <PlatformSubSection title={title} actions={actions}>
      <RealizationOuvrageTable
        page={ouvragePage}
        isLoading={isLoading}
        onPageQueryChange={noPageChange}
        onRowClicked={handleOuvrageRowClick}
      />
      {editable && realization?.id && ouvrageActorPopup.isOpen && (
        <OuvrageCreateFormPopup
          realizationId={realization?.id}
          onClose={ouvrageActorPopup.close}
          open={ouvrageActorPopup.isOpen}
          onSubmit={ouvrageActions}/>
      )}
    </PlatformSubSection>
  )
}

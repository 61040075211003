import {ProductTypeForm, ProductTypeFormProps} from "./ProductTypeForm";
import {PopupForm} from "@tracabois/components";

export interface ProductTypeFormPopupProps extends ProductTypeFormProps {
  open: boolean
}

export const ProductTypeFormPopup = (props: ProductTypeFormPopupProps) => {
  const {open, ...productTypeFormProps} = props
  const title = !productTypeFormProps.item?.id ? "Création d’un type de produit" : "Type de produit"
  return (
    <PopupForm open={open} onClose={productTypeFormProps.onCancel} title={title}>
      <ProductTypeForm
        {...productTypeFormProps}
      />
    </PopupForm>
  )
};

import {PopupForm} from "@tracabois/components";
import { CreditedFile, FileName, ExternalFile } from "@tracabois/domain";
import { CreditedImageForm } from "./CreditedImageForm";

type CreditedImageFormPopupType = "edit" | "add"

export interface CreditedImageFormPopupProps {
  onAdd: (item: CreditedFile, toUseAsCover: boolean) => Promise<boolean> | boolean
  onUpdate: (name: FileName) => Promise<boolean> | boolean
  onDelete: (name: FileName) => Promise<boolean> | boolean
  item?: ExternalFile
  isCover?: boolean
  type: CreditedImageFormPopupType
  open: boolean
  onClose: () => void
}

export const CreditedImageFormPopup = (props: CreditedImageFormPopupProps) => {
  const {onAdd, onUpdate, onDelete, item, isCover, type, open, onClose} = props

  const title = type == "edit" ? "Modifier une image" : "Ajouter une image"

  return (
    <PopupForm open={open} onClose={onClose} title={title}>
      <CreditedImageForm
        item={item}
        isCover={isCover}
        onCancel={onClose}
        onAdd={onAdd}
        onUpdate={onUpdate}
        onDelete={onDelete}/>
    </PopupForm>
  )
};

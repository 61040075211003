import {Ouvrage, OuvrageUpdatedEvent, ouvrageToUpdateOuvrageCommand} from "../..";
import {useCallback} from "react";
import {useOuvrageUpdateMutation} from "../..";
import { EditableGallery } from "@tracabois/connect";
import { CreditedFile, CreditedFileCommand, FileName } from "@tracabois/domain";

interface OuvrageGallerySectionProps {
  ouvrage?: Ouvrage
  onOuvrageUpdated?: (result: OuvrageUpdatedEvent) => void
  editable?: boolean
}

export const OuvrageGallerySection = (props: OuvrageGallerySectionProps) => {
  const {ouvrage, onOuvrageUpdated, editable} = props
  const [ouvrageUpdate] = useOuvrageUpdateMutation()

  const handleOuvrageUpdate = useCallback(async (command: CreditedFileCommand): Promise<boolean> => {
    ouvrage && onOuvrageUpdated && await ouvrageUpdate({
      ...ouvrageToUpdateOuvrageCommand(ouvrage),
      imageCommand: command,
    })
    return true;
  }, [ouvrageUpdate, ouvrage])

  const handleAdd = useCallback(async (item: CreditedFile, toUseAsCover: boolean = false): Promise<boolean> => {
    const command: CreditedFileCommand = toUseAsCover ? {toAdd: item, toUseAsCover: item.name} : {toAdd: item}
    return handleOuvrageUpdate(command)
  }, [ouvrageUpdate, ouvrage])

  const handleUpdate = useCallback(async (name: FileName): Promise<boolean> => {
    const command: CreditedFileCommand = {toUseAsCover: name}
    return handleOuvrageUpdate(command)
  }, [ouvrageUpdate, ouvrage])

  const handleDelete = useCallback(async (name: FileName): Promise<boolean> => {
    const command: CreditedFileCommand = {toRemove: name}
    return handleOuvrageUpdate(command)
  }, [ouvrageUpdate, ouvrage])

  if (ouvrage?.id) {
    return (
      <EditableGallery files={ouvrage?.images} editable={editable || true} onAdd={handleAdd} onUpdate={handleUpdate} onDelete={handleDelete}/>
    )
  } else {
    return (
      <></>
    )
  }
}

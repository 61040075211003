import {useCallback, useMemo} from "react";
import {canFinishOuvrage, canUnlockOuvrage, Ouvrage, OuvrageModel, toOuvrageState} from "../../model/ouvrage";
import {useTranslation} from "react-i18next";
import {UpdateStateAction} from "@tracabois/components";
import {useOuvrageFinishMutation, useOuvrageUnlockMutation} from "../../api";

export const useOuvrageStateAction =
  (ouvrage?: Ouvrage | OuvrageModel, onStateChanged?: () => void): UpdateStateAction | undefined => {
    const state = toOuvrageState(ouvrage)
    const {t} = useTranslation()
    const [ouvrageUnlock] = useOuvrageUnlockMutation()
    const [ouvrageFinish] = useOuvrageFinishMutation()
    const finishAction = useCallback(async (): Promise<boolean> => {
      ouvrage?.id && await ouvrageFinish({id: ouvrage.id})
      onStateChanged && onStateChanged()
      return true;
    }, [ouvrage?.id, onStateChanged])

    const unlockAction = useCallback(async (): Promise<boolean> => {
      ouvrage && await ouvrageUnlock({id: ouvrage.id})
      onStateChanged && onStateChanged()
      return true;
    }, [ouvrage?.id, state])

    return useMemo(() => {
      if (ouvrage && canFinishOuvrage(state))
        return {
          action: finishAction,
          label: t("ouvrage.state.finish.action", "Terminer l'ouvrage")
        }
      if (ouvrage && canUnlockOuvrage(state))
        return {
          action: unlockAction,
          label: t("ouvrage.state.unlock.action", "Permettre l'édition l'ouvrage")
        }

      return undefined
    }, [ouvrage, finishAction, unlockAction])
  }

import {UserEditPage, UserListPage, MyProfile} from "@tracabois/connect";
import {PlatformRoute} from "@tracabois/template";
import {UserCreatePage} from "../page/UserCreatePage";

export const UserAppRoute = (): PlatformRoute[] => [
  {
    path: "/users",
    components: <UserListPage />
  }, {
    path: "/users/:userId/edit",
    components: <UserEditPage update />
  }, {
    path: "/users/:userId/view",
    components: <UserEditPage readonly />
  }, {
    path: "/users/add",
    components: <UserCreatePage />
  }, {
    path: "/myProfile",
    components: (<MyProfile />)
  }
]

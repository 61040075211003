import {ProductId} from "@tracabois/domain";
import {NavigateFunction} from "react-router-dom";

const create = (navigate: NavigateFunction) => {
  return navigate(`/products/create`);
};

const view = (id: ProductId, navigate: NavigateFunction) => {
  return navigate(`/products/${id}/view`);
};

const list = (navigate: NavigateFunction) => {
  return navigate(`/products`);
};

export const goto = {
  addProduct: create,
  viewProduct: view,
  listProduct: list,
};

import {RemovalZoneForm} from "./RemovalZoneForm";
import {PopupForm, PopupFormAction} from "@tracabois/components";
import {useCallback, useState} from "react";
import {RemovalZoneCreateCommand} from "../../model/removalZone";
import {useFormComposable} from "@komune-io/g2";
import {DropZonePictureAction} from "../../../brandOrganization";
import {useDropZonePicture} from "@komune-io/g2-fs";
import {WoodSpecies} from "../../../../referentiels/woodSpecies";
import {PlatformSectionForm} from "@tracabois/template/src/platform/PlatformSectionForm";
import { City, Department } from "@tracabois/domain";
import { RemovalZoneCertification } from "../../../../referentiels/removalZoneCertification";

export interface RemovalZoneFormPopupProps {
  open: boolean
  onCancel: () => void;
  woodSpecies: WoodSpecies[],
  departments: Department[],
  cities: City[],
  removalZoneCertifications: RemovalZoneCertification[],
  actor?: string
  onCreate: (item: RemovalZoneCreateCommand) => Promise<boolean> | boolean

}

export const RemovalZoneCreateFormPopup = (props: RemovalZoneFormPopupProps) => {
  const {open, onCreate, woodSpecies, departments, cities, removalZoneCertifications, onCancel, actor} = props
  const [feedback, setFeedback] = useState<boolean | undefined>(undefined)
  const imageDropZone: DropZonePictureAction = useDropZonePicture()
  const onSubmitMemoized = useCallback(
    async (values: RemovalZoneCreateCommand) => {
      const result = await onCreate({
        ...values,
        city: cities.find(city => city.code == values.city?.code)
      }) || false
      setFeedback(result)
      return result
    },
    [onCreate, imageDropZone.image]
  )

  const formState = useFormComposable<RemovalZoneCreateCommand>({
    onSubmit: onSubmitMemoized,
    actions: {
      validate: {
        label: "Créer",
      },
      cancel: {
        onClick: onCancel,
      }
    },
    formikConfig: {
      initialValues: {
        organization: actor ?? {id: actor},
        // img: imageDropZone.image
      }
    },
  })
  return (
    <PopupForm open={open} onClose={onCancel} title="Création d’une zone d'origine">
      <PlatformSectionForm>
        <RemovalZoneForm
          imageDropZone={imageDropZone}
          formState={formState}
          woodSpecies={woodSpecies}
          departments={departments}
          cities={cities}
          removalZoneCertifications={removalZoneCertifications}
        />
        <PopupFormAction
          formState={formState}
          isCreation
          onCancel={onCancel}
          feedback={feedback}
        />
      </PlatformSectionForm>
    </PopupForm>
  )
};

import {Ouvrage, OuvrageModel, toOuvrageState,} from "@tracabois/domain";
import {useMemo} from "react";
import {ObjectStateChip} from "@tracabois/components";
import {useOuvrageStateAction} from "./useOuvrageStateAction";

export interface OuvrageStateChipProps {
  item?: Ouvrage | OuvrageModel
  onStateChanged?: () => void
  editable?: boolean
}

export const OuvrageStateChip = (props: OuvrageStateChipProps) => {
  const {item, onStateChanged, editable} = props
  const action = useOuvrageStateAction(item, onStateChanged)
  const state = useMemo(() => {
    return item && toOuvrageState(item)
  }, [item])
  return (
    <ObjectStateChip editable={editable} state={state} stateAction={action}/>
  );
}

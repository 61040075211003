import {useCallback, useMemo} from "react";
import {PlatformPage} from "@tracabois/template";
import {useTranslation} from "react-i18next";
import {Action} from "@komune-io/g2";
import {UserForm} from "../../components/UserForm/UserForm";
import {User, useUserFormState} from "connect-im";
import {parse} from "qs";
import {userRoute} from "../../route";
import {useNavigate} from "react-router-dom";

export interface UserCreatePagePros {
  title?: string;
}

export const UserCreatePage = (props: UserCreatePagePros) => {
  const {title} = props;
  const {t} = useTranslation()
  const navigate = useNavigate()
  const organizationId = useMemo(() => {
    const params = parse(window.location.search, {ignoreQueryPrefix: true});
    if (typeof params.organizationId === "string") {
      return params.organizationId;
    }
    return undefined;
  }, []);

  const onSave = useCallback(
    (data?: { id: string; }) => {
      data && userRoute.goto.editUser(data.id, navigate)
    },
    [navigate, userRoute]
  )

  const { formState } = useUserFormState<User>({
    createUserOptions: {
      onSuccess: onSave,
    },
    multipleRoles: false,
    organizationId: organizationId
  })

  const actions: Action[] = useMemo(() => ([{
    key: "create",
    label: t("user.create"),
    onClick: async (): Promise<boolean> => {
      await formState.submitForm()
      return true
    }
  }]), [])
  return (
    <PlatformPage title={title ?? t("user.one")} actions={actions}>
      <UserForm formState={formState}/>
    </PlatformPage>
  );
};

import { ThemeContext } from "@komune-io/g2";
import {Skeleton, Typography, useMediaQuery} from "@mui/material";
import { useContext } from "react";

export interface PageHeaderTitleProps {
    title?: string
    isLoading?: boolean
}
export const PageHeaderTitle = (props: PageHeaderTitleProps) => {
    const {title, isLoading = false} = props
    const {openDrawer} = useContext(ThemeContext)
    const smallScreen = useMediaQuery('(max-width:600px)')
    return (
      <Typography color="primary" sx={{textTransform: "capitalize"}} paddingLeft={openDrawer ? "0px" : smallScreen ? "40px":"20px"} variant="h5" key="pageTitle">
          {isLoading ? <Skeleton key="loadingTitle" animation='wave' sx={{width:250}} /> : title}
      </Typography>
    );
}

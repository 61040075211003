import {MapContainer} from "react-leaflet"
import { TileLayer } from "react-leaflet"
import Geoman from "./Geoman";
import {LatLngExpression} from "leaflet";
import {Stack} from "@mui/material";
import {FeatureCollection} from "geojson";
import {brandConfig} from "@tracabois/domain";

interface MapGeomanProps {
  geoJson?: FeatureCollection<any, any>
  onGeoJsonChange: (geojson: FeatureCollection<any, any>) => void;
}

export const MapGeoman = (props: MapGeomanProps) => {
  const {geoJson, onGeoJsonChange} = props

  const position: LatLngExpression =  brandConfig().map.position.center;
  const zoomLv =  brandConfig().map.position.zoom;

  return (
    <Stack sx={{height: 400}}>
      <MapContainer center={position} zoom={zoomLv} scrollWheelZoom={true} style={{height:400, width: "100%"}}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png"
          maxZoom={20}
        />
        <Geoman onGeoJsonChange={onGeoJsonChange} geoJson={geoJson}/>
      </MapContainer>
    </Stack>
  );
};

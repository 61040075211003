import {
  Filters,
  FiltersField,
  useFilters,
  FiltersProps,
} from '@komune-io/g2-forms'
import {BasicProps, MergeMuiElementProps} from '@komune-io/g2-themes'
import React, { useMemo } from 'react'
import {PlatformSectionFilter} from "../PlatformSectionFilter";

export type PlatformFiltersSearch = {
  search?: string
}

export interface PlatformFiltersBasicProps extends BasicProps {
  onSubmit: (values: PlatformFiltersSearch) => void
  filters?: FiltersField[]
  actions?: React.ReactNode
}

export type PlatformFiltersProps = MergeMuiElementProps<
  Omit<FiltersProps, 'fields' | 'formState'>,
  PlatformFiltersBasicProps
>

export const PlatformTableSearchFilters = (props: PlatformFiltersProps) => {
  const {
    onSubmit,
    filters = [],
    actions,
    ...other
  } = props
  const fields = useMemo(
    (): FiltersField[] => [
      {
        key: 'search',
        name: 'search',
        label: 'Rechercher',
        type: 'textfield',
        textFieldProps: {
          textFieldType: 'search',
          className: 'searchFilter',
        }
      }, ...filters
    ],
    [filters]
  )

  const formState = useFilters({
    fields: fields,
    onSubmit: onSubmit
  })

  return (
    <PlatformSectionFilter>
      <Filters fields={fields} formState={formState} {...other} />
      {actions}
    </PlatformSectionFilter>
  )
}

import {RemovalZoneCertificationForm, RemovalZoneCertificationFormProps} from "./RemovalZoneCertificationForm";
import {PopupForm} from "@tracabois/components";

export interface RemovalZoneCertificationFormPopupProps extends RemovalZoneCertificationFormProps {
  open: boolean;
}

export const RemovalZoneCertificationFormPopup = (props: RemovalZoneCertificationFormPopupProps) => {
  const {item, open, ...removalZoneCertificationFormPopupProps} = props
  const title = !item?.id ? "Création certification d'origine" : "Certification d'origine"

  return (
    <PopupForm open={open} onClose={removalZoneCertificationFormPopupProps.onCancel} title={title}>
      <RemovalZoneCertificationForm
        item={item}
        {...removalZoneCertificationFormPopupProps}
      />
    </PopupForm>
  )
};

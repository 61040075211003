import {Avatar, Stack, styled, Typography,} from '@mui/material'
import {RealizationCertification} from "../../model/RealizationCertification";
import { externalFileUrl } from '@tracabois/domain';

const StyledStack = styled(Stack)({
  '& .AruiPopover-root': {
    width: '80vw',
    maxWidth: '450px'
  },
  '& .mainFormLeft': {
    marginRight: '30px',
    width: '260px'
  },
  '& .mainFormRight': {
    width: '260px'
  }
})

export interface RealizationCertificationCardProps {
  items: RealizationCertification[]
  alignItems?: string
}

export const RealizationCertificationCard = (props: RealizationCertificationCardProps) => {
  const {items, alignItems = "flex-start"} = props
  return (
    <StyledStack
      alignItems={alignItems}
      width='100%'
      direction="row"
      justifyContent="space-around"
      flexWrap="wrap"
      gap={2}
    >
      {items.map((item, index) => (
        <Stack
          key={`${item.name}-${index}`}
          direction="column"
          alignItems="center"
        >
          <Avatar alt={item.name} src={externalFileUrl(item?.img)} variant="circular" sx={{ width: 100, height: 100 }}/>
          <Typography variant="body2" color="text.secondary" >{item.name}</Typography>
        </Stack>
      ))}
    </StyledStack>
  )
}

import {PlatformPage, PlatformSection} from "@tracabois/template";
import {RemovalZoneId, RemovalZoneListSection} from "@tracabois/domain";
import {goto} from "../route/removalZones.goto";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useMemo} from "react";

export interface RemovalZonesPros {
}

const useLabels = () => {
  const {t} = useTranslation()
  return useMemo(() => ({
    removalZones: t("removalZone.plurals"),
    createRemovalZone: t("realization.create"),
  }), [t])
}

export const RemovalZonesList = (_: RemovalZonesPros) => {
  // const [updateRemovalZone] = useRemovalZoneUpdateMutation()
  const navigate = useNavigate()
  const label = useLabels()
  const gotoRemovalZonePage = (id: RemovalZoneId) => {
    goto.viewRemovalZone(id, navigate)
  }
  return (
    <PlatformPage title={label.removalZones}>
      <PlatformSection >
        <RemovalZoneListSection noElevation={false} onRemovalZoneCreated={gotoRemovalZonePage} onRowClick={gotoRemovalZonePage}/>
      </PlatformSection>
    </PlatformPage>
  );
}
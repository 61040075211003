import {Router, useAuth} from "@komune-io/g2-providers";
import {OrganizationAppRoute, UserAppRoute} from "@tracabois/connect";
import {Route} from "react-router";
import {HomePage} from "App/pages/home";
import {SettingsRoutes} from "App/pages/settings/SettingsRoutes";
import {RealizationRoutes} from "App/pages/realization/route";
import {LogoutRoutes} from "App/pages/logout/route";
import {ProductRoutes} from "App/pages/product/route";
import {OuvrageRoutes} from "App/pages/ouvrage/route";
import {RemovalZonesRoutes} from "App/pages/removalZones/route/RemovalZonesRoutes";
import {PlatformRoute} from "@tracabois/template";

export const AppRouter = () => {
  const {keycloak} = useAuth();
  if (!keycloak. isAuthenticated) return <></>;
  const routes: PlatformRoute[] = [
    {
      path: "/",
      components: <HomePage/>
    },
    ...LogoutRoutes,
    ...ProductRoutes,
    ...RealizationRoutes,
    ...OuvrageRoutes,
    ...SettingsRoutes,
    ...RemovalZonesRoutes,
    ...UserAppRoute(),
    ...OrganizationAppRoute()
  ]

  return (
    <Router>
      {routes.map((value, index) => (
        <Route key={index} path={value.path} element={value.components} />
      ))}
    </Router>
  );
};

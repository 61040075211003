import {useCallback, useState} from 'react'
import {Stack} from '@mui/material'
import {
  BtmcSubOrganizationAddCommand, BtmcSubOrganizationDeleteCommand, BtmcSubOrganizationUpdateCommand,
  SubOrganization,
} from "../../../model";
import {useSubOrganizationFormField} from "./SubOrganizationFormFields";
import {useFormComposable} from "@komune-io/g2";
import {FormComposable, PopupFormAction} from "@tracabois/components";

export interface SubOrganizationFormProps {
  organizationId: string,
  item?: Partial<SubOrganization>
  onCreate: (item: BtmcSubOrganizationAddCommand) => Promise<boolean> | boolean
  onUpdate: (item: BtmcSubOrganizationUpdateCommand) => Promise<boolean> | boolean
  onDelete: (item: BtmcSubOrganizationDeleteCommand) => Promise<boolean> | boolean
  onCancel: () => void
  readonly?: boolean
}

export const SubOrganizationForm = (props: SubOrganizationFormProps) => {
  const {
    item,
    onCreate,
    onUpdate,
    onDelete,
    onCancel,
    readonly = false,
    organizationId
  } = props

  const [feedback, setFeedback] = useState<boolean | undefined>(undefined)

  const onSubmitMemoized = useCallback(
    async (values: BtmcSubOrganizationAddCommand | BtmcSubOrganizationUpdateCommand) => {
      if (!item?.id) {
        // @ts-ignore
        const feedback = await onCreate({
          ...item,
          ...values,
          id: organizationId,
        })
        setFeedback(feedback)
      } else {
        // @ts-ignore
        const feedback = await onUpdate({
          ...item,
          ...values,
          id: organizationId,
          subOrganizationId: item.id
        })
        setFeedback(feedback)
      }
    },
    [onCreate, onUpdate, item]
  )

  const fields = useSubOrganizationFormField()
  const formState = useFormComposable({
    formikConfig:{
      initialValues: item || {}
    },
    // @ts-ignore
    onSubmit: onSubmitMemoized
  })

  const handleDelete = useCallback(() => {
    item?.id && onDelete({id: organizationId, subOrganizationId: item?.id})
  }, [organizationId])
  return (
    <Stack>
      <FormComposable
        fields={Object.values(fields)}
        formState={formState}
      />
      <PopupFormAction
        formState={formState}
        onCancel={onCancel}
        feedback={feedback}
        readonly={readonly}
        isCreation={!item?.id}
        onDelete={handleDelete}
      />
    </Stack>
  )
}

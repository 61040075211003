import {PopUp} from '@komune-io/g2-layout';
import {Link, Stack, Typography} from "@mui/material";
import {useCallback} from "react";

export interface QrcodeImgProps {
  qrcode?: string;
  shortenUrl?: string;
  redirectUrl: string;
  open: boolean;
  setOpen: (open: boolean) => void
}

export const QrcodePopup = (props: QrcodeImgProps) => {
  const {qrcode, shortenUrl, redirectUrl, open, setOpen} = props
  const goToRedirectUrl = useCallback(() => {
    window.open(redirectUrl, '_blank');
  }, [redirectUrl])
  const goToShortenUrl = useCallback(() => {
    window.open(shortenUrl, '_blank');
  }, [shortenUrl])

  return (
    <PopUp open={open}  onClose={() => setOpen(false)}>
      <Stack spacing={2}>
        {qrcode &&
          <Stack justifyContent="center">
            <img src={qrcode} alt="Qrcode" />
          </Stack>
        }
        <Stack>
          <Typography variant="subtitle1">
            Realisation
          </Typography>
          <Link onClick={goToRedirectUrl} variant="body2">
            {redirectUrl}
          </Link>
        </Stack>
        { shortenUrl &&
          <Stack>
            <Typography variant="subtitle1">
              Raccourci
            </Typography>
            <Link onClick={goToShortenUrl} variant="body2">
              {shortenUrl}
            </Link>
          </Stack>
        }
      </Stack>
    </PopUp>
  )
};



import {ProductCreateCommand, ProductCreateFormPopup, useProductCreateMutation} from "@tracabois/domain";
import {PlatformPageObject} from "@tracabois/template";
import {productRoute} from "../route";
import {history} from "../../../../store";
import {useNavigate} from "react-router";

export interface ProductCreatePageProps {
}


export const ProductCreatePage = (_: ProductCreatePageProps) => {
  const [productCreate] = useProductCreateMutation()
  const navigate = useNavigate()

  const handleCreate = async (item: ProductCreateCommand) => {
    const result = await productCreate(item)
    // @ts-ignore
    productRoute.goto.viewProduct(result.data.id, navigate)
    return true
  }

  return (
    <PlatformPageObject title="Creation d'un produit">
      <ProductCreateFormPopup onSubmit={handleCreate} onClose={() => history.goBack()} open={true} />
    </PlatformPageObject>
  );
}

import { Theme as AruiTheme} from "@komune-io/g2-themes";
import { AppStyleProps } from "@komune-io/g2-layout";
import {PartialDeep} from "type-fest";
import {brandConfig} from "./config";

export const appLayoutStyleProps: PartialDeep<AppStyleProps> = {
  appBar: {
    background: "white"
  },
  menu: {
    background: "white",
  },
};

export const theme: PartialDeep<AruiTheme> = {// to complete and to use
  name: brandConfig().acronym,
  colors: brandConfig().colors
};


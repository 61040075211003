import {useCallback, useEffect, useState} from 'react'
import {Stack} from '@mui/material'
import {Button} from '@komune-io/g2-components'
import {BasicProps} from '@komune-io/g2-themes'

export interface ObjectStateFormProps extends BasicProps {
  onSubmit?: () => Promise<boolean> | boolean
  onCancel?: () => void
}

export const ObjectStateForm = (props: ObjectStateFormProps) => {
  const {
    onSubmit,
    onCancel,
  } = props
  const [feedback, setFeedback] = useState<boolean | undefined>(undefined)
  //
  useEffect(() => {
    setFeedback(undefined)
  }, [onSubmit])

  const onSubmitMemorized = useCallback(
    async () => {
      if (onSubmit) {
        const feedback = await onSubmit()
        setFeedback(feedback)
      }
    },
    [onSubmit]
  )

  return (
    <Stack
      direction='row'
      justifyContent='flex-end'
      width='100%'
    >
      <Button onClick={onCancel} variant="text">Annuler</Button>
      <Button
        success={feedback !== undefined && feedback}
        fail={feedback !== undefined && !feedback}
        onClick={onSubmitMemorized}
      >
        Valider
      </Button>
    </Stack>
  )
}

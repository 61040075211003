import {OuvrageTypeForm, OuvrageTypeFormProps} from "./OuvrageTypeForm";
import {PopupForm} from "@tracabois/components";

export interface OuvrageTypeFormPopupProps extends OuvrageTypeFormProps {
  open: boolean
}

export const OuvrageTypeFormPopup = (props: OuvrageTypeFormPopupProps) => {
  const {open, ...ouvrageTypeFormProps} = props
  const title = !ouvrageTypeFormProps.item?.id ? "Création d’un type d'ouvrage" : "Type d'ouvrage"
  return (
    <PopupForm open={open} onClose={ouvrageTypeFormProps.onCancel} title={title}>
      <OuvrageTypeForm
        {...ouvrageTypeFormProps}
      />
    </PopupForm>
  )
};

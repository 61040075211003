import {PopupForm} from "@tracabois/components";
import {OuvrageUpdateForm} from "./OuvrageUpdateForm";
import {useTranslation} from "react-i18next";
import {Ouvrage, OuvrageId, OuvrageUpdateCommand} from "../../../model/ouvrage";
import {useOuvrageTypeAllQuery} from "../../../../../referentiels/ouvrageType";
import {
  useRealizationRefsAllQuery,
} from "../../../../realization";
import {useCallback} from "react";
import {useOuvrageUpdateMutation} from "../../../api";

export interface OuvrageUpdatePopupProps {
  onUpdated: (id: OuvrageId) => void
  open: boolean
  onClose: () => void
  item: Ouvrage
}

export const OuvrageUpdateFormPopup = (props: OuvrageUpdatePopupProps) => {
  const {open, onClose, item} = props
  const {t} = useTranslation()
  const ouvrageTypeAllQuery = useOuvrageTypeAllQuery();
  const realizationRefsAllQuery = useRealizationRefsAllQuery(undefined);

  const [ouvrageUpdate] = useOuvrageUpdateMutation()
  const handleUpdate = useCallback(async (command: OuvrageUpdateCommand): Promise<boolean> => {
    command && await ouvrageUpdate({
      ...command,
      id: command.id,
    })
    return true;
  }, [ouvrageUpdate])
  return (
    <PopupForm open={open} onClose={onClose} title={t("ouvrage.form.update.title", "Edition d’un ouvrage")}>
      <OuvrageUpdateForm
        ouvrage={item}
        realizationRefs={realizationRefsAllQuery.data || []}
        onCancel={() => {
          onClose()
          return true;
        }}
        onSubmit={handleUpdate}
        ouvrageTypes={ouvrageTypeAllQuery.data.items}
      />
    </PopupForm>
  )
};

import {PopupForm} from "@tracabois/components";
import {useTranslation} from "react-i18next";
import {RealizationCertificationIdLink, RealizationCertificationLink} from "../../../model/realizationModel";
import { RealizationCertificationLinkForm } from "./realizationCertificationLinkForm";
import { RealizationCertificationId, useRealizationCertificationAllQuery } from "../../../../../referentiels/realizationCertification";

type RealizationCertificationLinkFormPopupType = "edit" | "add"

export interface RealizationCertificationLinkFormPopupProps {
  onAdd: (item: RealizationCertificationIdLink) => Promise<boolean> | boolean
  onUpdate: (item: RealizationCertificationIdLink) => Promise<boolean> | boolean
  onDelete: (id: RealizationCertificationId) => Promise<boolean> | boolean
  item?: RealizationCertificationLink
  type: RealizationCertificationLinkFormPopupType
  open: boolean
  onClose: () => void
}

export const RealizationCertificationLinkFormPopup = (props: RealizationCertificationLinkFormPopupProps) => {
  const {onAdd, onUpdate, onDelete, item, type, open, onClose} = props
  const {t} = useTranslation()
  const useGetAllRealizationCertificationsQuery = useRealizationCertificationAllQuery().getAll()

  const title = type == "edit" ? t("realization.certifications.form.edit.title", "Modifier une certification") : t("realization.certifications.form.add.title", "Ajouter une certification")

  return (
    <PopupForm open={open} onClose={onClose} title={title}>
      <RealizationCertificationLinkForm
        item={item}
        onCancel={onClose}
        realizationCertifications={useGetAllRealizationCertificationsQuery}
        onSubmit={item ? onUpdate : onAdd}
        onDelete={onDelete}/>
    </PopupForm>
  )
};

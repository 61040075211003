import {Box, Typography} from '@mui/material'
import { RealizationCertificationIdLink, RealizationCertificationLink } from '../../../realization/model/realizationModel'
import { usePopup } from '@tracabois/components'
import { RealizationCertificationLinkFormPopup } from '../form/realizationCertificationLinkForm/realizationCertificationLinkFormPopup'
import { RealizationCertificationId } from '../../../../referentiels/realizationCertification'
import { Edit } from '@mui/icons-material'
import { useCallback } from 'react'
import { externalFileUrl } from '@tracabois/domain'
import { makeG2STyles } from '@komune-io/g2'

const useStyles = makeG2STyles()(
  () => ({
    hover: {
      "&:hover": { 
        backgroundColor: '#EEEEEE',
        opacity:'0.8',
        cursor: 'pointer'
      },
    }
  }))

export interface RealizationCertificationLinkCardProps {
  item: RealizationCertificationLink
  editable: boolean
  onAdd?: (item: RealizationCertificationIdLink) => Promise<boolean> | boolean
  onUpdate?: (item: RealizationCertificationIdLink) => Promise<boolean> | boolean
  onDelete?: (id: RealizationCertificationId) => Promise<boolean> | boolean
}

export const RealizationCertificationLinkCard = (props: RealizationCertificationLinkCardProps) => {
  const {item, editable, onAdd, onUpdate, onDelete} = props
  const {classes} = useStyles()
  const updatePopup = usePopup()

  const isPopupOpenable = editable && onAdd !== undefined && onUpdate !== undefined && onDelete !== undefined
  const isClickable = editable || (item.url && item.url.length > 0)
  const handleClick = useCallback(() => {
    editable ? updatePopup.open() : (item.url && item.url.length > 0 ? window.open(item.url) : '')
  }, [editable, updatePopup, item])

  return (
    <>
      <Box onClick={handleClick} style={{ 
        position: 'relative', display:'flex', flexDirection:'column', 
        justifyContent: 'flex-start', alignItems: 'center', borderRadius: '6px'
        }} className={isClickable ? classes.hover : ''}>
        { isPopupOpenable && (
          <Edit style={{
            width: '20px', height:'20px', padding:'2px', opacity: '0.5',
            position: 'absolute', top: '0', right: '0',
          }}/>
        )}
        <img width="70px" height="70px" src={externalFileUrl(item.model.img)} alt={item.model.name} style={{padding: '6px'}}/>
        <Box>
          <Typography variant="subtitle1" color="text.secondary" component="div" padding="6px" textAlign="center">
            {item.model.name}
          </Typography>
        </Box>
      </Box>
      { isPopupOpenable && (
        <RealizationCertificationLinkFormPopup type={"add"} item={item}
        onAdd={onAdd} onUpdate={onUpdate} onDelete={onDelete} onClose={updatePopup.close} open={updatePopup.isOpen} />
      )}
    </>
  )
}

import {OuvrageId} from "@tracabois/domain";
import {NavigateFunction} from "react-router-dom";

const view = (id: OuvrageId, navigate: NavigateFunction) => {
  return navigate(`/ouvrages/${id}/view`);
};

const list = (navigate: NavigateFunction) => {
  return navigate(`/ouvrages`);
};

export const goto = {
  viewOuvrage: view,
  listOuvrage: list
};

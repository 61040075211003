import {org} from "boislocal-platform-platform-api-api-js-export";
import {S2StateProp} from "@tracabois/utils";

export type ProductCreateCommand = org.boislocal.platform.s2.product.domain.command.ProductCreateCommandDTO
export type ProductCreatedEvent = org.boislocal.platform.s2.product.domain.command.ProductCreatedEventDTO

export type ProductRenameCommand = org.boislocal.platform.s2.product.domain.command.ProductRenameCommandDTO
export type ProductRenamedEvent = org.boislocal.platform.s2.product.domain.command.ProductRenamedEventDTO

export type ProductUpdateCommand = org.boislocal.platform.s2.product.domain.command.ProductUpdateCommandDTO
export type ProductUpdatedEvent = org.boislocal.platform.s2.product.domain.command.ProductUpdatedEventDTO

export type ProductUnlockCommand = org.boislocal.platform.s2.product.domain.command.ProductUnlockCommandDTO
export type ProductUnlockedEvent = org.boislocal.platform.s2.product.domain.command.ProductUnlockedEventDTO

export type ProductFinishCommand = org.boislocal.platform.s2.product.domain.command.ProductFinishCommandDTO
export type ProductFinishedEvent = org.boislocal.platform.s2.product.domain.command.ProductFinishedEventDTO

export type ProductDeleteCommand = org.boislocal.platform.s2.product.domain.command.ProductDeleteCommandDTO
export type ProductDeletedEvent = org.boislocal.platform.s2.product.domain.command.ProductDeletedEventDTO

export type ProductTransformationDateChangeCommand = org.boislocal.platform.s2.product.domain.command.ProductTransformationDateChangeCommandDTO
export type ProductTransformationDateChangedEvent = org.boislocal.platform.s2.product.domain.command.ProductTransformationDateChangedEventDTO

export type ProductTransformationActorDefineCommand = org.boislocal.platform.s2.product.domain.command.ProductTransformationActorDefineCommandDTO
export type ProductTransformationActorDefinedEvent = org.boislocal.platform.s2.product.domain.command.ProductTransformationActorDefinedEventDTO

export type ProductGetQuery = org.boislocal.platform.s2.product.domain.query.ProductModelGetQueryDTO
export type ProductGetResponse = org.boislocal.platform.s2.product.domain.query.ProductModelGetResponseDTO


export type ProductPageQuery = org.boislocal.platform.f2.product.query.domain.query.ProductPageQueryDTO

export type ProductModel = org.boislocal.platform.s2.product.domain.model.ProductModelDTO
export type Product = org.boislocal.platform.f2.product.query.domain.model.ProductDTO
export type city = org.boislocal.platform.s2.product.domain.model.DestinationModelDTO
export type ProductSource = org.boislocal.platform.s2.product.domain.model.SourceModelDTO
export type ProductTransformation = org.boislocal.platform.s2.product.domain.model.TransformationModelDTO

export type EndProductModel = org.boislocal.platform.s2.product.domain.model.EndProductModelDTO
export type RawProductModel = org.boislocal.platform.s2.product.domain.model.RawProductModelDTO
export type ProductMetrics = org.boislocal.platform.s2.product.domain.model.ProductMetricsDTO

export type OriginModelDTO = org.boislocal.platform.s2.product.domain.model.OriginModelDTO
export type OriginZoneModelDTO = org.boislocal.platform.s2.product.domain.model.OriginZoneModelDTO

export type ProductId = string
export type ProductSection = "to" | "from"

export const canFinishProduct = (status?: S2StateProp): boolean => {
  return status?.key == "Exists"
}

export const isEditableProduct = (item?: Product | ProductModel): boolean => {
  // @ts-ignore
  return item?.status == "Exists"
}

export const canUnlockProduct = (status?: S2StateProp): boolean => {
  return status?.key == "Finished" || status?.key == "Deleted"
}



export const toProductState = (item?: Product | ProductModel): S2StateProp => {
  const statue = item?.status
  // @ts-ignore
  if(statue == "Exists") {
    return {
      key: "Exists",
      name: "En cours",
      color: "info",
    }
  }
  // @ts-ignore
  if(statue == "Finished") {
    return {
      key: "Finished",
      name: "Terminé",
      color: "success"
    }
  } else {
    return {
      key: "Deleted",
      name: "Supprimé",
      color: "error"
    }
  }
}

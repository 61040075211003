import {useState} from "react";
import {RealizationCertificationFormPopup} from "../form/RealizationCertificationFormPopup";
import {RealizationCertificationTable} from "../table/RealizationCertificationTable";
import {Button} from '@komune-io/g2-components'
import {useTranslation} from "react-i18next";
import {Row} from "@komune-io/g2-layout";
import {
  RealizationCertification,
  RealizationCertificationCreateCommand,
  RealizationCertificationDeleteCommand,
  RealizationCertificationUpdateCommand
} from "../../model/RealizationCertification";
import {
  useRealizationCertificationAllQuery,
  useRealizationCertificationCreateMutation, useRealizationCertificationDeleteMutation,
  useRealizationCertificationUpdateMutation
} from "../../api/RealizationCertificationApi";
import {PlatformSubSection} from "@tracabois/template";

interface RealizationCertificationSectionProps {
  title: string
}

export const RealizationCertificationSection = (props: RealizationCertificationSectionProps) => {
  const {title} = props
  const [selectedItem, setSelectedItem] = useState<Partial<RealizationCertification> | undefined>(undefined)

  const {t} = useTranslation()
  const realizationCertificationPageQuery = useRealizationCertificationAllQuery();
  const [createRealizationCertification] = useRealizationCertificationCreateMutation()
  const [updateRealizationCertification] = useRealizationCertificationUpdateMutation()
  const [deleteRealizationCertification] = useRealizationCertificationDeleteMutation()

  const closePopup = () => {
    setSelectedItem(undefined)
  }

  const handleCreate = async (item: RealizationCertificationCreateCommand) => {
    await createRealizationCertification(item)
    closePopup()
    realizationCertificationPageQuery.refetch()
    return true
  }
  const handleUpdate = async (item: RealizationCertificationUpdateCommand) => {
    await updateRealizationCertification(item)
    closePopup()
    realizationCertificationPageQuery.refetch()
    return true
  }
  const handleDelete = async (item: RealizationCertificationDeleteCommand) => {
    await deleteRealizationCertification(item)
    closePopup()
    realizationCertificationPageQuery.refetch()
    return true
  }
  const clickHandler = (wood: Row<RealizationCertification>) => {
    setSelectedItem(wood.original)
  }

  const actions = (<Button color="primary" onClick={() => setSelectedItem({})} >{t("realizationCertification.action.create", "Ajouter une certification")}</Button>)

  return (
    <PlatformSubSection title={title}>
      <RealizationCertificationTable
        page={realizationCertificationPageQuery.data}
        onPageQueryChange={realizationCertificationPageQuery.setPageQuery}
        onRowClicked={clickHandler}
        isLoading={realizationCertificationPageQuery.isLoading}
        actions={actions}
      />
      <RealizationCertificationFormPopup
        open={!!selectedItem} item={selectedItem || {}}
        onCreate={handleCreate}
        onUpdate={handleUpdate}
        onDelete={handleDelete}
        onCancel={closePopup}
      />
    </PlatformSubSection>
  )
}

import thunk from "redux-thunk";
import {Reducer} from "redux";
import {
  OrganizationTypeApi,
  OuvrageApi,
  ProductApi,
  ProductTypeApi,
  RealizationApi,
  RemovalZoneApi,
  WoodSpeciesApi,
  RealizationCertificationApi,
  RemovalZoneCertificationApi,
  BtmcOrganizationApi, OuvrageTypeApi
} from "@tracabois/domain";
import {StatsApi} from "@tracabois/domain";
import {initRedux} from "@tracabois/utils";
import { GeoApi } from "@tracabois/connect";

interface Reducers extends ReducersType {

}

type ReducersType = { [key: string]: Reducer; }

const reducers: Reducers = {
  [ProductApi.reducerPath]: ProductApi.reducer,
  [RealizationApi.reducerPath]: RealizationApi.reducer,
  [OuvrageApi.reducerPath]: OuvrageApi.reducer,
  [OuvrageTypeApi.reducerPath]: OuvrageTypeApi.reducer,
  [OrganizationTypeApi.reducerPath]: OrganizationTypeApi.reducer,
  [ProductTypeApi.reducerPath]: ProductTypeApi.reducer,
  [RealizationCertificationApi.reducerPath]: RealizationCertificationApi.reducer,
  [WoodSpeciesApi.reducerPath]: WoodSpeciesApi.reducer,
  [RemovalZoneCertificationApi.reducerPath]: RemovalZoneCertificationApi.reducer,
  [RemovalZoneApi.reducerPath]: RemovalZoneApi.reducer,
  [BtmcOrganizationApi.reducerPath]: BtmcOrganizationApi.reducer,
  [StatsApi.reducerPath]: StatsApi.reducer,
  [GeoApi.reducerPath]: GeoApi.reducer,
};

export const { store, history, reducer } = initRedux<typeof reducers>(
  reducers,
  [thunk,
    ProductApi.middleware,
    RealizationApi.middleware,
    OuvrageApi.middleware,
    OuvrageTypeApi.middleware,
    OrganizationTypeApi.middleware,
    ProductTypeApi.middleware,
    WoodSpeciesApi.middleware,
    RealizationCertificationApi.middleware,
    RemovalZoneCertificationApi.middleware,
    RemovalZoneApi.middleware,
    BtmcOrganizationApi.middleware,
    StatsApi.middleware,
    GeoApi.middleware,
  ]
);

export type State = ReturnType<typeof reducer>;

import {
  RemovalZone,
  RemovalZoneUpdateCommand
} from "../../model/removalZone";
import {usePageQuery} from "@tracabois/components";
import {
  useRemovalZonePageQuery,
  useRemovalZoneUpdateMutation
} from "../../api/RemovalZoneApi";
import {useWoodSpeciesPageQuery} from "../../../../referentiels/woodSpecies";
import {allPage} from "@tracabois/utils";
import {RemovalZoneForm} from "../form/RemovalZoneForm";
import {FeatureCollection} from "geojson";
import {useCallback, useEffect} from "react";
import {FormComposableState, useFormComposable} from "@komune-io/g2";
import {DropZonePictureAction} from "../../../brandOrganization";
import {useDropZonePicture} from "@komune-io/g2-fs";
import {PlatformSubSection} from "@tracabois/template";
import { useGetCitiesQuery, useGetDepartmentsQuery } from "@tracabois/connect";
import { RemovalZoneCertification, useRemovalZoneCertificationAllQuery } from "../../../../referentiels/removalZoneCertification";
import { externalFileUrl } from "@tracabois/domain";

export interface RemovalZoneFormSectionProps {
  title: string
  item?: Partial<RemovalZone>
  geoJson?: FeatureCollection<any, any>
  onFormStateReady: (formState: FormComposableState) => void
}

export const RemovalZoneFormSection = (props: RemovalZoneFormSectionProps) => {
  const {title, item, geoJson, onFormStateReady} = props
  const removalZonePageQuery = usePageQuery(useRemovalZonePageQuery);
  const [updateRemovalZone] = useRemovalZoneUpdateMutation()
  // const [feedback, setFeedback] = useState<boolean | undefined>(undefined)
  const woodSpecies = useWoodSpeciesPageQuery({
    pagination: allPage
  })
  const departments = useGetDepartmentsQuery({})
  const cities = useGetCitiesQuery({})
  const certification = useRemovalZoneCertificationAllQuery().getAll()
  const imageDropZone: DropZonePictureAction = useDropZonePicture(externalFileUrl(item?.img))
  const onSubmitMemoized = useCallback(
    async (values: RemovalZoneUpdateCommand) => {
      let updatedGeoJson: FeatureCollection<any, any> | undefined
      if (geoJson !== undefined){
        updatedGeoJson = JSON.parse(JSON.stringify(geoJson))
        if (updatedGeoJson !== undefined && updatedGeoJson.features.length > 0){
          updatedGeoJson.features[0].geometry.coordinates = [
            values?.geo?.longitude, 
            values?.geo?.latitude
          ]
        }
      }
      const cmd: RemovalZoneUpdateCommand = {
        ...item,
        ...values,
        img: imageDropZone.image,
        geoJson: updatedGeoJson,
      }
      // const feedback = await onUpdate(object)
      // setFeedback(feedback)
      await updateRemovalZone(cmd)
      removalZonePageQuery.refetch()
      return true
    },
    [item, geoJson, imageDropZone.image]
  )

  const formState = useFormComposable<RemovalZoneUpdateCommand>({
    onSubmit: onSubmitMemoized,
    formikConfig: {
      initialValues: {
        ...item,
        img: imageDropZone.image,
        certifications: item?.certifications?.map((item: RemovalZoneCertification) => ({key: item.id, label: item.name})) || []
      }
    },
  })
  
  useEffect(() => {
    if (geoJson != undefined && geoJson.features[0]) {
      formState.setFieldValue("geo.latitude", geoJson.features[0].geometry.coordinates[1])
      formState.setFieldValue("geo.longitude", geoJson.features[0].geometry.coordinates[0])
    }
  }, [geoJson])

  useEffect(() => onFormStateReady(formState), [])

  return (
    <PlatformSubSection title={title}>
      <RemovalZoneForm
        item={item}
        formState={formState}
        woodSpecies={woodSpecies.data?.items || []}
        departments={departments.data || []}
        cities={cities.data || []}
        removalZoneCertifications={certification}
        imageDropZone={imageDropZone}
        readonlyOrganization
      />
    </PlatformSubSection>
  )
};

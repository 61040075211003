import {TFunction} from 'i18next'
import {OffsetPagination, PageQuery, PageResult} from "@tracabois/utils";
import {Ouvrage} from "../../../ouvrage";
import {PlatformActions, PlatformPaginatedTable, TableCellText} from "@tracabois/template";
import {CellProps, Column, Row} from '@komune-io/g2-layout'
import {ProductModel} from "../../../product";

interface RealizationOuvrageTableProps {
  page: PageResult<Ouvrage>
  isLoading?: boolean
  onPageQueryChange: (page: PageQuery) => void;
  onRowClicked: (row: Row<Ouvrage>) => void;
  actions?: React.ReactNode
}

export const RealizationOuvrageTable = (props: RealizationOuvrageTableProps) => {
  const {page, isLoading, onPageQueryChange, onRowClicked, actions} = props
  const filter = (
    actions && <PlatformActions actions={actions} />
  )
  const onPaginationChange = (pagination: OffsetPagination) => {
    onPageQueryChange({
      pagination: pagination,
      search: page?.search
    })
  }

  return (
    <PlatformPaginatedTable<Ouvrage>
      page={page}
      columnsDefinition={getColumns}
      isLoading={isLoading}
      actions={filter}
      onPageChange={onPaginationChange}
      onRowClicked={onRowClicked}
    />
  )
}


const getColumns = (t: TFunction): Column<Ouvrage>[] => ([{
  Header: `${t("ouvrage.name", "Nom Ouvrage")}`,
  // @ts-ignore
  Cell: ({row}: CellProps<Ouvrage>) => (
    <TableCellText main={row.original.name}/>
  )
}, {
  Header: `${t("ouvrage.name", "Acteur")}`,
  // @ts-ignore
  Cell: ({row}: CellProps<Ouvrage>) => (
    <TableCellText main={productsList(row.original.composedBy)}/>
  )
}, {
  Header: `${t("ouvrage.product", "Produits")}`,
  // @ts-ignore
  Cell: ({row}: CellProps<Ouvrage>) => (
    <TableCellText
      main={productList(row.original)}
      second={woodList(row.original)}
    />
  )
}, {
  Header: `${t("ouvrage.volumes", "Volume")}`,
  // @ts-ignore
  Cell: ({row}: CellProps<Ouvrage>) => (
    <TableCellText main={`${row.original.indicators.wood} m3`}/>
  )
}])

const productList = (ouvrage: Ouvrage): string => {
  return  ouvrage.composedBy.map( (it) => it.name).join(", ")
}

const woodList = (ouvrage: Ouvrage): string => {
  return  ouvrage.species.map( (it) => it.name).join(", ")
}

const productsList = (products: ProductModel[]): string => {
  return products.map( (item) => {
    return item.transformation.actor.name
  }).join(", ")
}

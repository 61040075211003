import {RealizationCertificationForm, RealizationCertificationFormProps} from "./RealizationCertificationForm";
import {PopupForm} from "@tracabois/components";

export interface RealizationCertificationFormPopupProps extends RealizationCertificationFormProps {
  open: boolean;
}

export const RealizationCertificationFormPopup = (props: RealizationCertificationFormPopupProps) => {
  const {item, open, ...realizationCertificationFormPopupProps} = props
  const title = !item?.id ? "Création d'une certification de réalisation" : "Certification de réalisation"

  return (
    <PopupForm open={open} onClose={realizationCertificationFormPopupProps.onCancel} title={title}>
      <RealizationCertificationForm
        item={item}
        {...realizationCertificationFormPopupProps}
      />
    </PopupForm>
  )
};

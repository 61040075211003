import {RealizationId} from "@tracabois/domain";
import {NavigateFunction} from "react-router-dom";

const view = (realizationId: RealizationId, navigate: NavigateFunction) => {
  return navigate(`/realizations/${realizationId}/view`);
};

const list = (navigate: NavigateFunction) => {
  return navigate('/realizations');
};

export const goto = {
  viewRealization: view,
  listRealization: list,
};

import {Avatar, Stack} from "@mui/material";

export interface TableCellImgProps {
  src?: string
}

export const TableCellImg = (props: TableCellImgProps) => {
  const {src} = props
  return (
    <Stack>
      <Avatar variant="rounded" src={src}/>
    </Stack>
  )
}

import {Page as AruiPage} from '@komune-io/g2-layout'
import React from "react";
import {PlatformPageObjectHeader} from "../PlatformPageObjectHeader";

interface PlatformPageObjectProps {
  children?: React.ReactNode
  headerContent?: React.ReactNode;
  title?: string
}

export const PlatformPageObject = (props: PlatformPageObjectProps) => {
  const {children, headerContent, title} = props
  const headerProps = headerContent ? {freeContent: headerContent} : undefined
  return (
    <AruiPage headerProps={headerProps}>
      {title && <PlatformPageObjectHeader title={title}/>}
      {children}
    </AruiPage>
  )
}

import {useCallback, useEffect, useMemo, useState} from 'react'
import {
  OuvrageType,
  OuvrageTypeCreateCommand,
  OuvrageTypeDeleteCommand,
  OuvrageTypeUpdateCommand
} from "../../model/OuvrageType";
import {FormComposable, FormComposableField, PopupFormAction} from "@tracabois/components";
import {useFormComposable} from "@komune-io/g2";
import {PlatformSectionForm} from "@tracabois/template/src/platform/PlatformSectionForm";

export interface OuvrageTypeFormProps {
  item?: Partial<OuvrageType>
  onCreate: (item: OuvrageTypeCreateCommand) => Promise<boolean> | boolean
  onUpdate: (item: OuvrageTypeUpdateCommand) => Promise<boolean> | boolean
  onDelete: (item: OuvrageTypeDeleteCommand) => Promise<boolean> | boolean
  onCancel: () => void
  readonly?: boolean
}

export const OuvrageTypeForm = (props: OuvrageTypeFormProps) => {
  const {
    item,
    onCreate,
    onUpdate,
    onDelete,
    onCancel,
    readonly = false,
  } = props
  const [feedback, setFeedback] = useState<boolean | undefined>(undefined)
  useEffect(() => {
    setFeedback(undefined)
  }, [onCreate, onUpdate])

  const onSubmitMemoized = useCallback(
    async (values: OuvrageTypeCreateCommand | OuvrageTypeUpdateCommand) => {
      if (!item?.id) {
        const feedback = await onCreate({
          ...item,
          ...values,
        })
        setFeedback(feedback)
        return feedback
      } else {
        const feedback = await onUpdate({
          ...item,
          ...values,
          id: item.id
        })
        setFeedback(feedback)
        return feedback
      }
    },
    [onCreate, onUpdate, item]
  )

  const details = useMemo(
    (): FormComposableField[] => [
      {
        key: 'name',
        name: 'name',
        type: 'textField',
        label: 'Libellé du type d’opération',
        defaultValue: item?.name,
        params: {
          disabled: readonly
        },
        validator: (value?: string) => {
          const trimmed = (value ?? '').trim()
          if (!trimmed) return 'Vous devez renseigner le nom' as string
          return undefined
        }
      }
    ],
    [readonly]
  )

  const formState = useFormComposable({
    onSubmit: onSubmitMemoized
  })
  const handleOnDelete = item?.id ? (() => onDelete({id: item.id!!})) : undefined

  return (
    <PlatformSectionForm>
      <FormComposable
        fields={details}
        formState={formState}
      />
      <PopupFormAction
        formState={formState}
         onCancel={onCancel}
         feedback={feedback}
         readonly={readonly}
         isCreation={!item?.id}
        onDelete={handleOnDelete}
      />
    </PlatformSectionForm>
  )
}

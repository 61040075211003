import {usePopup} from "@tracabois/components";
import {Ouvrage, OuvrageId} from "../../../model/ouvrage";
import {EditButton} from "@tracabois/components";
import {OuvrageUpdateFormPopup} from "./OuvrageUpdateFormPopup";

export interface OuvrageUpdateFormButtonProps {
  onUpdated: (id: OuvrageId) => void
  item?: Ouvrage
}

export const OuvrageUpdateFormButton = (props: OuvrageUpdateFormButtonProps) => {
  const {onUpdated, item} = props
  const editPopup = usePopup()
  return (
    <>
      <EditButton onClick={editPopup.open}/>
      {item && <OuvrageUpdateFormPopup onUpdated={onUpdated} onClose={editPopup.close} open={editPopup.isOpen} item={item} />}
    </>
  )
};

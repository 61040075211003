import {AutomatedUserTable} from "@komune-io/g2-im";
import {PlatformSectionFilter} from "@tracabois/template";
import {useUserListPage} from "./useUserListPage";
import {useUserFilters} from "./useUserFilters";

export interface UserTablePros {
  organizationId?: string;
}

export const UserTable = (props: UserTablePros) => {
  const {organizationId} = props;
  const { getOrganizationUrl, onRowClicked, columns } = useUserListPage(
    {withOrganizationColumn: true}
  )
  const { component, submittedFilters, setPage } = useUserFilters({ searchOrg: true })

  const organizationFilter = organizationId ? {organizationId: organizationId} : {}

  return (
    <AutomatedUserTable
      tableStateParams={{
        columns: columns
      }}
      header={(<PlatformSectionFilter>{component}</PlatformSectionFilter>)}
      variant='grounded'
      filters={{...organizationFilter, ...submittedFilters}}
      onRowClicked={onRowClicked}
      page={submittedFilters.page + 1}
      setPage={setPage}
      hasOrganizations
      getOrganizationUrl={getOrganizationUrl}
    />
  );
};

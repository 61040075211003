import {useCallback, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {RealizationMoeTable} from "../../table/RealizationMoeTable";
import {RealizationMoeAddFormPopup} from "../../form/moeCreateForm/RealizationMoeAddFormPopup";
import {usePage} from "@tracabois/utils";
import {OrganizationRef} from "../../../../../referentiels/organizationType";
import {
  RealizationAssignedMoeEvent,
  RealizationAssignMoeCommand,
  Realization,
  RealizationRemovedMoeEvent,
  RealizationRemoveMoeCommand
} from "../../../model/realizationModel";
import {usePopup} from "@tracabois/components";
import {useRealizationAssignMoeMutation, useRealizationRemoveMoeMutation} from "../../../api";
import {PlatformSubSection} from "@tracabois/template";
import {Action} from "@komune-io/g2";

interface RealizationMoeSectionProps {
  realization?: Realization
  title: string
  onMoeAssigned?: (event: RealizationAssignedMoeEvent) => void
  onMoeRemoved?: (event: RealizationRemovedMoeEvent) => void
  isLoading?: boolean
  editable?: boolean
}

const useLabels = () => {
  const {t} = useTranslation()
  return useMemo(() => ({
    addMoeActor: t("realization.moe.add", "Ajouter un acteur"),
  }), [t])
}

export const RealizationMoeSection = (props: RealizationMoeSectionProps) => {
  const {realization, title, onMoeAssigned, onMoeRemoved, isLoading, editable} = props
  const moePopup = usePopup()
  const labels = useLabels()
  const [assignMoeMutation] = useRealizationAssignMoeMutation()
  const [removeMoeMutation] = useRealizationRemoveMoeMutation()
  const moesPage = usePage<OrganizationRef>(realization?.moes)

  const actions: Action[] = [{
    key: 'moeCreate',
    showIf: () => editable || true,
    onClick: moePopup.open,
    label: labels.addMoeActor
  }]

  const assignMoe = useCallback(async (command: RealizationAssignMoeCommand) => {
    const event = await assignMoeMutation(command)
    // @ts-ignore
    onMoeAssigned && onMoeAssigned(event.data)
    moePopup.close()
    return true
  }, [assignMoeMutation, moePopup, onMoeAssigned])

  const removeMoe = useCallback(async (command: RealizationRemoveMoeCommand) => {
    const event = await removeMoeMutation(command)
    // @ts-ignore
    onMoeRemoved && onMoeRemoved(event.data)
    return true
  }, [realization, editable, removeMoeMutation, onMoeRemoved])

  return (
    <PlatformSubSection title={title} actions={actions}>
      { realization?.id &&
        <RealizationMoeTable
        page={moesPage.page}
        realizationId={realization.id}
        isLoading={isLoading}
        editable={editable}
        onPageQueryChange={moesPage.setPageQuery}
        onRowClicked={() => {}}
        onRemoveMoe={removeMoe}
        />
      }
      {
        editable && realization?.id && moePopup.isOpen && (
          <RealizationMoeAddFormPopup
            realizationId={realization.id}
            onClose={moePopup.close} 
            open={moePopup.isOpen}
            onSubmit={assignMoe}
          />
        )
      }
    </PlatformSubSection>
  )
}


import { FilterComposableField, useFiltersComposable, Action } from '@komune-io/g2'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FiltersComposable } from '../FiltersComposable'

export interface useAvFiltersParams {
    filters: FilterComposableField[]
    withPage?: boolean
    actions?: Action[]
    initialValues?: any
}

export const useFilters = <T extends {} = any>(params: useAvFiltersParams) => {
    const {filters, withPage = true, actions, initialValues} = params
    const { t } = useTranslation()
    const onSubmit = useCallback(
        (values: any, submittedFilters: any) => {
          const page = withPage ? 0 : undefined
          if (values.page === submittedFilters.page) return {...values, page: page}
        },
        [],
      )
    const { filtersCount, formState, submittedFilters, setAdditionalFilter } = useFiltersComposable<T & {page: number}>({
        onSubmit: onSubmit,
        formikConfig: {
            initialValues: {
                ...(withPage ? {
                    page: 0
                } : undefined),
                ...initialValues
            } 
        }
    })

    const setPage = useCallback(
        (newPage: number) => {
            setAdditionalFilter("page", newPage - 1)
        },
        [setAdditionalFilter],
    )

    const component = useMemo(() => (
        <FiltersComposable
            formState={formState}
            filterButtonProps={{ children: t("toFilterCount", { count: withPage ? filtersCount - 1 : filtersCount }) }}
            fields={filters}
            actions={actions}
        />
    ), [formState, filters, actions, filtersCount])

    return {
        component: component,
        submittedFilters,
        setPage
    }
}

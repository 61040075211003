import {Ouvrage, OuvrageUpdatedEvent, ouvrageToUpdateOuvrageCommand} from "../../../ouvrage";
import {useCallback, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {PlatformSubSection} from "@tracabois/template";
import {Action, MarkdownField} from "@komune-io/g2";
import {useOuvrageUpdateMutation} from "../../../ouvrage";

interface OuvrageDescriptionSectionProps {
  ouvrage?: Ouvrage
  onOuvrageUpdated?: (result: OuvrageUpdatedEvent) => void
  editable?: boolean
  title: string
}

const useLabels = () => {
  const {t} = useTranslation()
  return useMemo(() => ({
    editDescription: t("ouvrage.description.edit", "Editer"),
    saveDescription: t("ouvrage.description.save", "Valider"),
  }), [t])
}

export const OuvrageDescriptionSection = (props: OuvrageDescriptionSectionProps) => {
  const {ouvrage, onOuvrageUpdated, editable, title} = props
  const [editMode, setEditMode] = useState<boolean>(false)
  const [markdown, setMarkdown] = useState<string>(ouvrage?.description || "")
  const labels = useLabels()
  const [ouvrageUpdate] = useOuvrageUpdateMutation()
  const actions: Action[] = [{
    key: 'descriptionEdit',
    showIf: () => editable || true,
    onClick: () => {
      if (!editable) return
      if (editMode && markdown != ouvrage?.description) {
        handleUpdate(markdown)
      }
      setEditMode(!editMode)
    },
    label: editMode ? labels.saveDescription : labels.editDescription
  }]

  useEffect(() => {
    setMarkdown(ouvrage?.description || "")
  },[ouvrage?.description])

  const handleUpdate = useCallback(async (description: string): Promise<boolean> => {
    ouvrage && onOuvrageUpdated && await ouvrageUpdate({
      ...ouvrageToUpdateOuvrageCommand(ouvrage),
      description: description,
    })
    return true;
  }, [ouvrageUpdate, ouvrage])

  return (
    <PlatformSubSection title={title} actions={actions}>
      {ouvrage?.id && (
        <MarkdownField markdown={markdown} readOnly={!((editable || false) && editMode)} onChange={setMarkdown}/>
      )}
    </PlatformSubSection>
  )
}

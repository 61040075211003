import {useTranslation} from "react-i18next";
import {UserEditPage} from "../UserUpdatePage";
import {useExtendedAuth} from "../../../auth";

export const MyProfile = () => {
  const {t} = useTranslation()
  const auth = useExtendedAuth()
  return (
    <UserEditPage title={t("user.mine")} userId={auth.service.getUserId()} />
  )
}

import {useRealizationFinishMutation, useRealizationUnlockMutation} from "../../api";
import {useCallback, useMemo} from "react";
import {
  canTerminateRealization,
  canUnlockRealization,
  Realization,
  RealizationModel,
  toRealizationS2State
} from "../../model/realizationModel";
import {useTranslation} from "react-i18next";
import {UpdateStateAction} from "@tracabois/components";

export const useRealizationStateAction =
  (realization?: RealizationModel | Realization, onStateChanged?: () => void): UpdateStateAction | undefined => {
    const state = toRealizationS2State(realization)
    const {t} = useTranslation()
    const [realizationUnlock] = useRealizationUnlockMutation()
    const [realizationFinish] = useRealizationFinishMutation()
    const finishAction = useCallback(async (): Promise<boolean> => {
      realization?.id && await realizationFinish({id: realization.id})
      onStateChanged && onStateChanged()
      return true;
    }, [realization?.id, onStateChanged])

    const unlockAction = useCallback(async (): Promise<boolean> => {
      realization && await realizationUnlock({id: realization.id})
      onStateChanged && onStateChanged()
      return true;
    }, [realization?.id, state])

    return useMemo(() => {
      if (realization && canTerminateRealization(state))
        return {
          action: finishAction,
          label: t("realization.state.finish.action", "Terminer la réalisation")
        }
      if (realization && canUnlockRealization(state))
        return {
          action: unlockAction,
          label: t("realization.state.finish.action", "Permettre l'édition la réalisation")
        }

      return undefined
    }, [realization, finishAction, unlockAction])
}

import { stringify } from "qs";
import {NavigateFunction} from "react-router";

const createOrganization = (navigate: NavigateFunction) => {
  return navigate(`/organizations/add`);
};

const editOrganization = (organizationId: string, navigate: NavigateFunction) => {
  return navigate(`/organizations/${organizationId}/edit`);
};

const organizationTable = (navigate: NavigateFunction, params?: Object) => {
  return navigate(
    `/organizations${stringify(params, {
      addQueryPrefix: true,
      arrayFormat: "repeat",
    })}`
  );
};

export const goto = {
  createOrganization: createOrganization,
  editOrganization: editOrganization,
  organizationTable: organizationTable,
};

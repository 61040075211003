import {useEffect, useState} from "react";
import {usePageQuery} from "@tracabois/components";
import {Button} from '@komune-io/g2-components'
import {
  useRemovalZoneCreateMutation,
  useRemovalZonePageQuery,
} from "../../api/RemovalZoneApi";
import {RemovalZoneCreateFormPopup} from "./RemovalZoneCreateFormPopup";
import {useWoodSpeciesPageQuery} from "../../../../referentiels/woodSpecies";
import {
  RemovalZone,
  RemovalZoneCreateCommand,
  RemovalZoneCreatedEvent,
} from "../../model/removalZone";
import {Stack} from "@mui/material";
import {allPage} from "@tracabois/utils";
import { useGetCitiesQuery, useGetDepartmentsQuery } from "@tracabois/connect";
import { useRemovalZoneCertificationAllQuery } from "../../../../referentiels/removalZoneCertification";

interface RemovalZoneButtonProps {
  item?: Partial<RemovalZone>
  label: string
  onRemovalZoneCreated?: (evt: RemovalZoneCreatedEvent) => void
  actor?: string
  onClose?: () => void
}

export const RemovalZoneFormCreatePopupButton = (props: RemovalZoneButtonProps) => {
  const {label, item, onRemovalZoneCreated, onClose, actor } = props
  const [selectedItem, setSelectedItem] = useState<Partial<RemovalZone> | undefined>(item)
  const removalZonePageQuery = usePageQuery(useRemovalZonePageQuery);
  const [ createRemovalZone] = useRemovalZoneCreateMutation()
  const woodSpecies = useWoodSpeciesPageQuery({
    pagination: allPage
  })
  const departments = useGetDepartmentsQuery({})
  const cities = useGetCitiesQuery({})
  const removalZone = useRemovalZoneCertificationAllQuery().getAll()

  useEffect(() => {
    setSelectedItem(item)
  }, [item])

  const closePopup = () => {
    setSelectedItem(undefined)
    onClose && onClose()
  }

  const handleCreate = async (item: RemovalZoneCreateCommand) => {
    const event = await createRemovalZone(item)
    closePopup()
    removalZonePageQuery.refetch()
    // @ts-ignore
    onRemovalZoneCreated && onRemovalZoneCreated(event.data)
    return true
  }

  return (
    <>
      <Stack justifyContent="flex-end" direction="row">
        <Button onClick={() => setSelectedItem({})}>{label}</Button>
      </Stack>
      <RemovalZoneCreateFormPopup
        open={!!selectedItem}
        onCreate={handleCreate}
        onCancel={closePopup}
        actor={actor}
        woodSpecies={woodSpecies.data?.items || []}
        departments={departments.data || []}
        cities={cities.data || []}
        removalZoneCertifications={removalZone}
      />
    </>
  )
}

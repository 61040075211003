import {
  OuvrageType,
  OuvrageTypeCreateCommand,
  OuvrageTypeCreatedEvent,
  OuvrageTypeDeleteCommand,
  OuvrageTypeDeletedEvent,
  OuvrageTypeId,
  OuvrageTypePageQuery,
  OuvrageTypeUpdateCommand,
  OuvrageTypeUpdatedEvent
} from "../model/OuvrageType";
import {config} from "../../../config";
import {createApi} from "@reduxjs/toolkit/dist/query/react";
import {firstPage, PageQuery, PageResult, toPagination} from "@tracabois/utils";
import {useState} from "react";
import {authQuery} from "../../../utils/authQuery";

export const OuvrageTypeApi = createApi({
  reducerPath: 'ouvrageTypeApi',
  baseQuery: authQuery({baseUrl: config.baseUrl}),
  tagTypes: ['OuvrageType'],
  endpoints: (builder) => ({
    getOuvrageTypeById: builder.query<OuvrageType, OuvrageTypeId>({
      query: (id: OuvrageTypeId) => ({
        url: `ouvrageTypeGet`,
        method: 'POST',
        body: {id: id}
      }),
    }),
    ouvrageTypePage: builder.query<PageResult<OuvrageType>, OuvrageTypePageQuery>({
      query: (page: OuvrageTypePageQuery) => ({
        url: `ouvrageTypePage`,
        method: 'POST',
        body: page
      }),
      transformResponse: (response: PageResult<OuvrageType>[]) => response[0],
      providesTags: ['OuvrageType'],
    }),
    ouvrageTypeCreate: builder.mutation<OuvrageTypeCreatedEvent, OuvrageTypeCreateCommand>({
      query: (cmd: OuvrageTypeCreateCommand) => ({
        url: `ouvrageTypeCreate`,
        method: 'POST',
        body: cmd
      }),
      transformResponse: (response: OuvrageTypeCreatedEvent[]) => response[0],
      invalidatesTags: ['OuvrageType'],
    }),
    ouvrageTypeUpdate: builder.mutation<OuvrageTypeUpdatedEvent, OuvrageTypeUpdateCommand>({
      query: (cmd: OuvrageTypeUpdateCommand) => ({
        url: `ouvrageTypeUpdate`,
        method: 'POST',
        body: cmd
      }),
      transformResponse: (response: OuvrageTypeUpdatedEvent[]) => response[0],
      invalidatesTags: ['OuvrageType'],
    }),
    ouvrageTypeDelete: builder.mutation<OuvrageTypeDeletedEvent, OuvrageTypeDeleteCommand>({
      query: (cmd: OuvrageType) => ({
        url: `ouvrageTypeDelete`,
        method: 'POST',
        body: cmd
      }),
      transformResponse: (response: OuvrageTypeDeletedEvent[]) => response[0],
      invalidatesTags: ['OuvrageType'],
    }),
  }),
})

export const {
  useGetOuvrageTypeByIdQuery, useOuvrageTypePageQuery,
  useOuvrageTypeCreateMutation, useOuvrageTypeUpdateMutation, useOuvrageTypeDeleteMutation
} = OuvrageTypeApi


export const useOuvrageTypeAllQuery = () => {
  const {data, error, isLoading, refetch} = OuvrageTypeApi.useOuvrageTypePageQuery({})
  const [pageQuery, setPageQuery] = useState<PageQuery>({
    pagination: firstPage
  })
  let sortedData =  [...data?.items ?? []]
  if(pageQuery.search) {
    sortedData = sortedData.filter((item) => {
      return item.name.toLowerCase().includes(pageQuery.search?.toLowerCase() ?? "")
    })
  }
  const page = toPagination(sortedData, pageQuery.pagination)

  return {data: page, error, isLoading, refetch, setPageQuery}
}
import { Box, Typography } from '@mui/material'
import { useCallback } from 'react'
import { BasicProps } from '@komune-io/g2-themes'
import { CreditedFile, FileName, ExternalFile, METADATA_CREDIT_KEY, theme, externalFileUrl } from '@tracabois/domain'
import { usePopup } from '@tracabois/components'
import { AddPhotoAlternate, Copyright } from '@mui/icons-material'
import { CreditedImageFormPopup } from './form/CreditedImageFormPopup'
import { makeG2STyles } from '@komune-io/g2'

const useStyles = makeG2STyles()(
  () => ({
    hover: {
      "&:hover": { 
        backgroundColor: '#EEEEEE',
        opacity:'0.8',
        cursor: 'pointer'
      },
    }
  }))

export interface CreditedImageProps extends BasicProps {
  item?: ExternalFile
  isCover?: boolean
  editable: boolean
  onAdd?: (item: CreditedFile, toUseAsCover: boolean) => Promise<boolean> | boolean
  onUpdate?: (name: FileName) => Promise<boolean> | boolean
  onDelete?: (name: FileName) => Promise<boolean> | boolean
}

export const CreditedImage = (props: CreditedImageProps) => {
  const {item, isCover, editable, onAdd, onUpdate, onDelete} = props
  const {classes} = useStyles()
  const updatePopup = usePopup()

  const isCertificate = onAdd === undefined && onUpdate === undefined && onDelete === undefined
  const isPopupOpenable = editable && onAdd !== undefined && onUpdate !== undefined && onDelete !== undefined
  const formType = item ? 'edit' : 'add'

  const handleClick = useCallback(() => {
    isPopupOpenable ? updatePopup.open() : ''
  }, [editable, updatePopup, item])

  const credit = item?.metadata?.[METADATA_CREDIT_KEY] ?? ""
  const url = externalFileUrl(item)
  return (
    <Box style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column'}}>
      <Box onClick={handleClick} style={{width: '240px', height: '180px', borderRadius: '20px', overflow: 'hidden'}}
        className={isPopupOpenable ? classes.hover : ''}>
          { item ? (
          <Box style={{
            width: '100%', height: '100%', 
            backgroundImage: `url('${url}')`, backgroundSize: 'cover', backgroundPosition: 'center',
            display: 'flex', alignItems: 'flex-end'
          }}>
            {credit !== "" && (
            <Box style={{
                bottom: 0, padding: '2px 20px',
                width: '100%', backgroundColor: '#00000080',
                display: 'flex', justifyContent: 'flex-start', alignItems: 'center',
                color: '#FFFFFF', fontSize: '14px!important'
                }}>
                  <Copyright style={{opacity: '0.8', marginRight: '10px', width: '20px', height: "20px"}}/>
                  <Typography sx={{overflowX: "auto"}} whiteSpace="nowrap">{credit}</Typography>
            </Box>
            )}
          </Box>
          ):(
            <Box style={{
              width: '100%', height: '100%', display: 'flex', flexDirection: 'column',
              justifyContent: 'center', alignItems: 'center', backgroundColor: '#FFFFFF',
              borderRadius: '20px', border: '2px dashed #CED4DA'
            }}>
              <AddPhotoAlternate style={{width: '40px', height: '40px'}}/>
              <Typography padding={'5px'}>Ajouter une image</Typography>
            </Box>
          )}
      </Box>
      { isPopupOpenable && (
        <CreditedImageFormPopup type={formType} item={item} isCover={isCover}
          onAdd={onAdd} onUpdate={onUpdate} onDelete={onDelete} onClose={updatePopup.close} open={updatePopup.isOpen} />
      )}
      { item && isCover && !isCertificate && (
        <Box style={{
          backgroundColor: `${theme.colors?.primary}19`,
          display: 'flex', justifyContent: 'center', alignItems: 'center',
          color: theme.colors?.primary, fontSize: '16px!important',
          padding: '4px 10px', margin: '10px', borderRadius: '8px'
          }}>
          <Typography>Couverture</Typography>
        </Box>
      )}
    </Box>
  )
}

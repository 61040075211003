import { Action } from "@komune-io/g2";
import { usePopup } from "@tracabois/components";
import { PlatformSubSection } from "@tracabois/template";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { RealizationCertificationId } from "../../../../../referentiels/realizationCertification";
import { useRealizationUpdateMutation } from "../../../api";
import { Realization, RealizationCertificationIdLink, RealizationCertificationLink, RealizationUpdatedEvent, realizationToUpdateRealizationCommand } from "../../../model/realizationModel";
import { RealizationCertificationLinkCardStack } from "../../card/RealizationCertificationLinkCardStack";
import { RealizationCertificationLinkFormPopup } from "../../form/realizationCertificationLinkForm/realizationCertificationLinkFormPopup";

interface RealizationCertificationLinkSectionProps {
  realization?: Realization
  onRealizationUpdated?: (result: RealizationUpdatedEvent) => void
  editable?: boolean
  title: string
}
 
const useLabels = () => {
  const {t} = useTranslation()
  return useMemo(() => ({
    realizationCertificationLinkAdd: t("realization.certifications.add", "Ajouter"),
  }), [t])
}

export const RealizationCertificationLinkSection = (props: RealizationCertificationLinkSectionProps) => {
  const {realization, onRealizationUpdated, editable, title} = props
  const addPopup = usePopup()
  const labels = useLabels()
  const [realizationUpdate] = useRealizationUpdateMutation()
  const actions: Action[] = [{
    key: 'realizationCertificationLinkAdd',
    showIf: () => editable || true,
    onClick: addPopup.open,
    label: labels.realizationCertificationLinkAdd
  }]

  const handleRealizationUpdate = useCallback(async (links: RealizationCertificationIdLink[]): Promise<boolean> => {
    realization && onRealizationUpdated && await realizationUpdate({
      ...realizationToUpdateRealizationCommand(realization),
      certifications: links,
    })
    addPopup.close()
    return true;
  }, [realizationUpdate, realization])

  const getRealizationCertificationIdLinks = (): RealizationCertificationIdLink[] => {
    return realization?.certifications.map((link: RealizationCertificationLink) => {
      return {id: link.model.id, url: link.url}
    }) || []
  }

  const handleAdd = useCallback(async (item: RealizationCertificationIdLink): Promise<boolean> => {
    return handleRealizationUpdate(getRealizationCertificationIdLinks().concat([item]))
  }, [realizationUpdate, realization])

  const handleUpdate = useCallback(async (item: RealizationCertificationIdLink): Promise<boolean> => {
    const links = getRealizationCertificationIdLinks()
    const index = links.findIndex(link => link.id == item.id)
    if (index < 0) return false
    links[index] = item
    return handleRealizationUpdate(links)
  }, [realizationUpdate, realization])

  const handleDelete = useCallback(async (id: RealizationCertificationId): Promise<boolean> => {
    return handleRealizationUpdate(getRealizationCertificationIdLinks().filter(link => link.id !== id))
  }, [realizationUpdate, realization])

  return (
    <PlatformSubSection title={title} actions={actions}>
      { editable && realization?.id && (
        <RealizationCertificationLinkFormPopup type={"add"}
        onAdd={handleAdd} onUpdate={handleUpdate} onDelete={handleDelete} 
        onClose={addPopup.close} open={addPopup.isOpen} />
      )}
      <RealizationCertificationLinkCardStack items={realization?.certifications || []}  editable={editable || false}
      onAdd={handleAdd} onUpdate={handleUpdate} onDelete={handleDelete}/>
    </PlatformSubSection>
  )
}

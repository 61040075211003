import {useCallback, useState} from "react";

export const usePopup = () => {
  const [isOpen, setOpen] = useState<boolean>(false)
  const close = useCallback(
    () => {
      setOpen(false)
    },
    [setOpen]
  )
  const open = useCallback(
    () => {
      setOpen(true)
    },
    [setOpen]
  )
  return {isOpen, open, close}
}
import {CellProps, Column, Row} from '@komune-io/g2-layout'
import {Avatar, Typography} from '@mui/material'
import {TFunction} from 'i18next'
import {PlatformTableSearchFilters, PlatformPaginatedTable} from "@tracabois/template";
import {firstPage, OffsetPagination, PageQuery, PageResult} from "@tracabois/utils";
import React from "react";
import {RealizationCertification} from "../../model/RealizationCertification";
import { externalFileUrl } from '@tracabois/domain';

interface RealizationCertificationProps {
  page?: PageResult<RealizationCertification>
  search?: string
  isLoading?: boolean
  onPageQueryChange: (page: PageQuery) => void;
  onRowClicked: (row: Row<RealizationCertification>) => void;
  actions: React.ReactNode
}

export const RealizationCertificationTable = (props: RealizationCertificationProps) => {
  const {page, search, isLoading, onPageQueryChange, onRowClicked, actions} = props

  const onSearch = (search?: string) => {
    onPageQueryChange({
      pagination: page?.pagination || firstPage,
      search: search
    })
  }
  const filter = (
    <PlatformTableSearchFilters actions={actions} onSubmit={(props) => onSearch(props.search)}/>
  )
  const onPaginationChange = (pagination: OffsetPagination) => {
    onPageQueryChange({
      pagination: pagination,
      search: search
    })
  }
  return (
    <PlatformPaginatedTable<RealizationCertification>
      page={page}
      columnsDefinition={getColumns}
      isLoading={isLoading}
      actions={filter}
      onPageChange={onPaginationChange}
      onRowClicked={onRowClicked}
      noElevation
    />
  )
}


const getColumns = (t: TFunction): Column<RealizationCertification>[] => ([{
  Header: `${t("realizationCertification.img", "Image")}`,
  // @ts-ignore
  Cell: ({row}: CellProps<RealizationCertification>) => (
    <Avatar alt={row.original.name} src={externalFileUrl(row.original.img) || ""}/>
  )
}, {
  Header: `${t("realizationCertification.certifications", "Certifications de réalisations")}`,
  // @ts-ignore
  Cell: ({row}: CellProps<RealizationCertification>) => (
    <Typography variant="body2">{row.original.name}</Typography>
  )
}])
import {fetchBaseQuery} from "@reduxjs/toolkit/query";
import {config} from "../config";
import { OidcClient } from "@axa-fr/oidc-client";

interface BaseQueryProps {
  baseUrl?: string | null
}

export const authQuery = (props?: BaseQueryProps) => fetchBaseQuery({
  prepareHeaders: (headers) => {
    const auth = getOidcClient()
    const token =  auth?.tokens.accessToken
    if (token) {
      headers.set('authorization', `Bearer ${token}`)
    }
    return headers
  },
  baseUrl: props?.baseUrl == null ? "" : props?.baseUrl || config.baseUrl
})

const getOidcClient = (): OidcClient | undefined => {
  try {
    return OidcClient.get()
  } catch (error) {
  }
}
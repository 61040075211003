import {SubOrganization} from "../../../model";
import {FormComposableField} from "@tracabois/components";

export interface SubOrganizationFormFields {
  name: FormComposableField
  street: FormComposableField
  postalCode: FormComposableField
  city: FormComposableField
  longitude: FormComposableField
  latitude: FormComposableField
}

export const useSubOrganizationFormField = (): SubOrganizationFormFields => {
  return {
    name: {
      key: 'name',
      name: 'name',
      type: 'textField',
      label: 'Nom',
      validator: (_: any, value?: Partial<SubOrganization>) => {
        const trimmed = (value?.name ?? '').trim()
        if (!trimmed) return "Vous devez renseigner le nom de l'établissement" as string
        return undefined
      },
    },
    street: {
      key: 'address.street',
      name: 'address.street',
      type: 'textField',
      label: 'Adresse',
      validator: (_: any, value?: Partial<SubOrganization>) => {
        const trimmed = (value?.address?.street ?? '').trim()
        if (!trimmed) return "Vous devez renseigner l'adresse de l'établissement" as string
        return undefined
      },
    },
    postalCode: {
      key: 'address.postalCode',
      name: 'address.postalCode',
      type: 'textField',
      label: 'Code postal',
      validator: (_: any, value?: Partial<SubOrganization>) => {
        const trimmed = (value?.address?.postalCode ?? '').trim()
        if (!trimmed) return "Vous devez renseigner le code postal de l'établissement" as string
        return undefined
      },
      params: {
        textFieldType: 'number',
      }
    },
    city: {
      key: 'address.city',
      name: 'address.city',
      type: 'textField',
      label: 'Ville',
      validator: (_: any, value?: Partial<SubOrganization>) => {
        const trimmed = (value?.address?.city ?? '').trim()
        if (!trimmed) return "Vous devez renseigner la ville de l'établissement" as string
        return undefined
      }
    },
    latitude: {
      key: 'geo.latitude',
      name: 'geo.latitude',
      type: 'textField',
      label: 'Latitude',
      params:  {
        placeholder: "43.602829"
      }
    },
    longitude: {
      key: 'geo.longitude',
      name: 'geo.longitude',
      type: 'textField',
      label: 'Longitude',
      params: {
        placeholder: "3.879260"
      }
    }
  }
}
import {CellProps, Column, Row} from '@komune-io/g2-layout'
import {TFunction} from 'i18next'
import {PlatformPaginatedTable, TableCellText, PlatformActions} from "@tracabois/template";
import {OffsetPagination, PageQuery, PageResult} from "@tracabois/utils";
import {OrganizationRef} from "../../../../referentiels/organizationType";
import {OrgRolesArray} from "@tracabois/connect";
import { useCallback } from 'react';
import { DeleteButton } from '@komune-io/g2';
import { RealizationId, RealizationRemoveMoeCommand } from '../../model/realizationModel';

interface RealizationMoeTableProps {
  page: PageResult<OrganizationRef>
  realizationId: RealizationId
  isLoading?: boolean
  editable?: boolean
  onPageQueryChange: (page: PageQuery) => void;
  onRowClicked: (row: Row<OrganizationRef>) => void;
  onRemoveMoe: (command: RealizationRemoveMoeCommand) => Promise<boolean>
  actions?: React.ReactNode
}

export const RealizationMoeTable = (props: RealizationMoeTableProps) => {
  const {page, realizationId, isLoading, editable, onPageQueryChange, actions, onRowClicked, onRemoveMoe} = props
  const filter = (
    actions && <PlatformActions actions={actions}></PlatformActions>
  )
  const onPaginationChange = (pagination: OffsetPagination) => {
    onPageQueryChange({
      pagination: pagination
    })
  }

  const onRemoveMoeClicked = useCallback((organization: OrganizationRef) => {
    console.log("editable", editable)
    const command: RealizationRemoveMoeCommand = {id: realizationId, organization: {id: organization.id}}
    onRemoveMoe && onRemoveMoe(command)
  }, [onRemoveMoe])

  const getColumns = useCallback((t: TFunction): Column<OrganizationRef>[] => ([{
    Header: `${t("organization.name", "Nom")}`,
    Cell: ({row}: CellProps<OrganizationRef>) => (
      <TableCellText main={row.original.name} />
    )
  }, {
    Header: `${t("organization.type", "Type")}`,
    Cell: ({row}: CellProps<OrganizationRef>) => {
      return (
        <TableCellText main={row.original.roles?.filter(it => OrgRolesArray.includes(it)).join(', ')}/>
      )
    }
  }, {
    Header: `${t("organization.localisation", "Localisation")}`,
    Cell: ({row}: CellProps<OrganizationRef>) => (
      <TableCellText main={row.original.location|| ""} />
    )
  }, {
    Header: `${t("moe.remove", "Supprimer")}`,
    Cell: ({row}: CellProps<OrganizationRef>) => (
        <DeleteButton 
          onClick={() => onRemoveMoeClicked(row.original)} 
          disabled={!editable} 
        />
    ),
  }]), [onRemoveMoeClicked, editable])

  return (<>
      <PlatformPaginatedTable<OrganizationRef>
        page={page}
        isLoading={isLoading}
        actions={filter}
        columnsDefinition={getColumns}
        onPageChange={onPaginationChange}
        onRowClicked={onRowClicked}
      />
    </>
  )
}

import {AutomatedOrganizationTable, useOrganizationColumns} from "@komune-io/g2-im";
import {PlatformPage, TableCellImg, PlatformSectionTable, PlatformSectionFilter} from "@tracabois/template";
import { useOrganizationFilters } from "./useOrganizationFilters";
import { Link, LinkProps, useNavigate } from "react-router-dom";
import {useCallback, useMemo} from "react";
import {Action, MenuItem, Organization, G2Row} from "@komune-io/g2";
import {useTranslation} from "react-i18next";
import {EditRounded, PersonAddAltRounded, Visibility} from "@mui/icons-material";
import {organizationRoutes} from "../../route";
import {stringify} from "qs";
import {getOrganizationLogoUrl} from "@tracabois/domain";
import {G2ColumnDef} from "@komune-io/g2-layout";

export interface OrganizationTablePros {
}

export const OrganizationListPage = (_: OrganizationTablePros) => {
  const navigate = useNavigate()
  const {component, submittedFilters, setPage} = useOrganizationFilters()
  const {t} = useTranslation()

  const getActions = useCallback(
    (org: Organization): MenuItem<LinkProps>[] => {
      return [
        {
          key: "view",
          label: t("view"),
          icon: <Visibility />,
          component: Link,
          componentProps: {
            to: `/organizations/${org.id}/view`
          }
        },
        {
          key: "edit",
          label: t("edit"),
          icon: <EditRounded />,
          component: Link,
          componentProps: {
            to: `/organizations/${org.id}/edit`
          }
        },
        {
          key: "addUser",
          label: t("addUser"),
          icon: <PersonAddAltRounded />,
          component: Link,
          componentProps: {
            to: `/users${stringify(
              { organizationId: org.id },
              { addQueryPrefix: true }
            )}`
          }
        }
      ]
    },
    [],
  )
  const pageActions: Action[] = useMemo(() => ([
    {
      key: "create",
      label: t("organization.create"),
      onClick: () => organizationRoutes.goto.createOrganization(navigate)
    }
  ]), []);
  const existingColumns = useOrganizationColumns({getActions: getActions})
  const additionalColumns = useMemo((): G2ColumnDef<Organization>[] => {
    return [{
      id: "logo",
      header: t("logo"),
      cell: ({ row }) => {
        return (
          <TableCellImg src={`${getOrganizationLogoUrl(row.original.id)}`}/>
        )
      }
    }, ...existingColumns.columnsArray]
  }, [])

  const handleRowClicked = useCallback((row: G2Row<Organization>) => {
    organizationRoutes.goto.editOrganization(row.original.id, navigate)
  }, [])
  return (
    <PlatformPage title={t("organisations")} actions={pageActions}>
      <PlatformSectionTable>
        <AutomatedOrganizationTable
          tableStateParams={{
            columns: additionalColumns
          }}
          header={(<PlatformSectionFilter>{component}</PlatformSectionFilter>)}
          variant='grounded'
          filters={{...submittedFilters, withDisabled: false}}
          onRowClicked={handleRowClicked}
          page={submittedFilters.page + 1}
          setPage={setPage}
        />
      </PlatformSectionTable>
    </PlatformPage>
  );
};

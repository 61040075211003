import {config} from "../../../config";
import {createApi} from "@reduxjs/toolkit/dist/query/react";
import {StatsGetQuery, StatsGetResponse} from "../model";
import {authQuery} from "../../../utils/authQuery";

export const StatsApi = createApi({
  reducerPath: 'statsApi',
  baseQuery: authQuery({baseUrl: config.baseUrl}),
  endpoints: (builder) => ({
    getStats: builder.query<StatsGetResponse, StatsGetQuery>({
      query: (body: StatsGetQuery) => ({
        url: `stats`,
        method: 'POST',
        body: body
      }),
      transformResponse: (response: StatsGetResponse[]) => response[0],
    }),
  }),
})

export const {
  useGetStatsQuery,
} = StatsApi

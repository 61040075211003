import {DeleteButton} from '@komune-io/g2-components'
import {Stack} from "@mui/material";
import {ProductMetricsFieldForm} from "../ProductMetrics/ProductMetricsField";
import {EndProductTypeFields} from "./useDestinationEndProductsField";
import {FormComposableState} from "@komune-io/g2";
import {FormComposable} from "@tracabois/components";

export interface DestinationEndProductFieldProps {
  productTypeFields: EndProductTypeFields[]
  formState: FormComposableState
  deleteEndProduct: (index: number) => void
}

export const DestinationEndProductField = (props: DestinationEndProductFieldProps) => {
  const {
    productTypeFields,
    formState,
    deleteEndProduct,
  } = props

  return (<>{
    productTypeFields.map((line, index) => (
      <Stack direction="row" key={index} gap={1} flexWrap="wrap" alignItems="flex-end">
          <FormComposable sx={{flexGrow: 1}} fields={line.productNameFields} formState={formState}/>
          <ProductMetricsFieldForm field={line.metricsField} formState={formState}/>
          {!formState.isLoading && !formState.readOnly && <DeleteButton onClick={() => deleteEndProduct(index)}/>}
      </Stack>
    ))
  }</>)
}

import {
  ArtIndexIcon,
  OuvrageIcon,
  ProductIcon,
  RealizationIcon,
  RouteIcon,
  WoodIcon
} from "../icons";
import {Ticket} from "../Ticket";

export interface IndicatorTicketProps {
  indicateur: string | number
  isLoading?: boolean
}

// export const Co2Ticket = (props: IndicatorTicketProps) => {
//   const {indicateur, isLoading} = props
//   return <Ticket isLoading={isLoading} icon={<Co2Icon/>} title="CO2" indicateur={indicateur} unit={"T"}
//     tooltips="CO2 émis par rapport à l’utilisation de bois non local (origine UE)"
//   />;
// };

export const OuvrageTicket = (props: IndicatorTicketProps) => {
  const {indicateur, isLoading} = props
  return <Ticket isLoading={isLoading} icon={<OuvrageIcon width="40" height="40"/>} title="Ouvrages" indicateur={indicateur}/>;
};

export const ProductTicket = (props: IndicatorTicketProps) => {
  const {indicateur, isLoading} = props
  return <Ticket isLoading={isLoading} icon={<ProductIcon width="40" height="40"/>} title="Produits" indicateur={indicateur}/>;
};

export const RealizationTicket = (props: IndicatorTicketProps) => {
  const {indicateur, isLoading} = props
  return <Ticket isLoading={isLoading} icon={<RealizationIcon width="40" height="40"/>} title="Réalisations" indicateur={indicateur}/>;
};

export const WoodTicket = (props: IndicatorTicketProps) => {
  const {indicateur, isLoading} = props
  return <Ticket isLoading={isLoading} icon={<WoodIcon width="40" height="40"/>} title="De bois local" indicateur={indicateur} unit="m3"
    tooltips="Volume de bois certifié mis en oeuvre dans l’ouvrage."
  />;
};

export const RouteTicket = (props: IndicatorTicketProps) => {
  const {indicateur, isLoading} = props
  return <Ticket isLoading={isLoading} icon={<RouteIcon width="40" height="40"/>} title="Parcourus" indicateur={indicateur} unit="km"
    tooltips="Distance moyenne pondérée depuis la forêt en passant par tous les acteurs jusqu'à la mise en œuvre de l'ouvrage final."
  />;
};

export const ArtIndexTicket = (props: IndicatorTicketProps) => {
  const {indicateur, isLoading} = props
  return <Ticket isLoading={isLoading} icon={<ArtIndexIcon width="40" height="40"/>} title="Indice ART" indicateur={indicateur}
    tooltips="Outil développé par la Fédération nationale des Communes forestières (Fncofor) en collaboration avec l'agence de notation Biom-Attitude. Il permet de mesurer l'impact direct des investissements sur le territoire pour une réalisation."
  />;
};

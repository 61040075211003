import { OrganizationEditPage, OrganizationList, MyOrganization} from "@tracabois/connect";
import {PlatformRoute} from "@tracabois/template";
import {OrganizationCreatePage} from "../page/OrganizationCreatePage";

export const OrganizationAppRoute = (): PlatformRoute[] => [
  {
    path: "/organizations",
    components:  <OrganizationList />
  }, {
    path: "/organizations/:organizationId/edit",
    components:  <OrganizationEditPage update />
  }, {
    path: "/organizations/:organizationId/view",
    components: <OrganizationEditPage readonly />
  }, {
    path: "/organizations/add",
    components: (<OrganizationCreatePage />)
  }, {
    path: "/myOrganization",
    components: (<MyOrganization />)
  }
]

import {WoodSpeciesForm, WoodSpeciesFormProps} from "./WoodSpeciesForm";
import {PopupForm} from "@tracabois/components";

export interface WoodSpeciesFormPopupProps extends WoodSpeciesFormProps {
  open: boolean;
}

export const WoodSpeciesFormPopup = (props: WoodSpeciesFormPopupProps) => {
  const {item, open, ...woodSpeciesFormPopupProps} = props
  const title = !item?.id ? "Création type d’essence" : "Type d’essence"

  return (
    <PopupForm open={open} onClose={woodSpeciesFormPopupProps.onCancel} title={title}>
      <WoodSpeciesForm
        item={item}
        {...woodSpeciesFormPopupProps}
      />
    </PopupForm>
  )
};

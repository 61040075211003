import {Realization, RealizationModel, toRealizationS2State,} from "@tracabois/domain";
import {useMemo} from "react";
import {ObjectStateChip} from "@tracabois/components";
import {useRealizationStateAction} from "./useRealizationStateAction";

export interface RealizationStateChipProps {
  item?: RealizationModel | Realization
  onStateChanged?: () => void
  editable?: boolean
}

export const RealizationStateChip = (props: RealizationStateChipProps) => {
  const {item, onStateChanged, editable} = props
  const action = useRealizationStateAction(item, onStateChanged)
  const state = useMemo(() => {
    return item && toRealizationS2State(item)
  }, [item])
  return (
    <ObjectStateChip editable={editable} state={state} stateAction={action}/>
  );
}

import {CellProps, Column, Row} from '@komune-io/g2-layout'
import {TFunction} from 'i18next'
import {PlatformActions, PlatformPaginatedTable, TableCellText} from "@tracabois/template";
import {OffsetPagination, PageQuery, PageResult} from "@tracabois/utils";
import {SubOrganization} from "../../../model";

interface SubOrganizationProps {
  page?: PageResult<SubOrganization>
  isLoading?: boolean
  onPageQueryChange: (page: PageQuery) => void;
  onRowClicked: (row: Row<SubOrganization>) => void;
  actions: React.ReactNode
}

export const SubOrganizationTable = (props: SubOrganizationProps) => {
  const {page, isLoading, onPageQueryChange, actions, onRowClicked} = props

  const onPaginationChange = (pagination: OffsetPagination) => {
    onPageQueryChange({
      pagination: pagination,
    })
  }
  const action = (
    <PlatformActions actions={actions} />
  )
  return (
    <PlatformPaginatedTable<SubOrganization>
      page={page}
      actions={action}
      columnsDefinition={getColumns}
      isLoading={isLoading}
      onPageChange={onPaginationChange}
      onRowClicked={onRowClicked}
      noElevation
    />
  )
}


const getColumns = (t: TFunction): Column<SubOrganization>[] => ([{
  Header: `${t("subOrganization.name", "Nom")}`,
  // @ts-ignore
  Cell: ({row}: CellProps<SubOrganization>) => (
    <TableCellText main={`${row.original.name} `}/>
  )
}, {
  Header: `${t("subOrganization.address", "Adresse")}`,
  // @ts-ignore
  Cell: ({row}: CellProps<SubOrganization>) => (
    <TableCellText
      main={`${row.original.address.street} ${row.original.address.postalCode} ${row.original.address.city}`}
    />
  )
}])
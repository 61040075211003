import {Card, CardContent, Stack, Typography} from '@mui/material'
import { RemovalZone } from '../../index'
import treeRingsImg from './asset/tree-rings.png'
import {WoodSpeciesCard} from "../../../../referentiels/woodSpecies";
import { theme } from '../../../../Themes';
import { RemovalZoneCertificationCard } from '../../../../referentiels/removalZoneCertification';

export interface RemovalZoneCardProps {
  item: RemovalZone
}

export const RemovalZoneCard = (props: RemovalZoneCardProps) => {
  const {item} = props
  
  return (
    <Card style={{
      borderRadius: '24px', maxWidth: '300px', width: '100%',
      display: 'flex', flexDirection: 'column', justifyContent: 'space-between'
    }}>
      <CardContent>
        <Stack gap={2} direction="row" justifyContent={"space-between"}>
          <Typography gutterBottom variant="subtitle1" color="text.primary" component="div">
            {item?.department?.name} ({item?.department?.code})
          </Typography>
          <RemovalZoneCertificationCard items={item.certifications}/>
        </Stack>
        <Typography gutterBottom variant="subtitle2" color={theme?.colors?.primary} component="div">
          {item.name}
        </Typography>
      </CardContent>
      <Stack style={{ padding: '16px',
        backgroundColor: '#57454A', backgroundImage: `url(${treeRingsImg})`, 
        backgroundPosition: 'left', backgroundRepeat: 'no-repeat', 
        overflowX: 'auto'
        }}>
        <WoodSpeciesCard items={item.woodSpecies}/>
      </Stack>
    </Card>
  )
}

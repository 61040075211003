import {CellProps, Column, Row} from '@komune-io/g2-layout'
import {PlatformTableSearchFilters, PlatformPaginatedTable, TableCellTag, TableCellText} from "@tracabois/template";
import {firstPage, OffsetPagination, PageQuery, PageResult} from "@tracabois/utils";
import {Product} from '../..';
import React from "react";
import {ProductStateChip} from "../state/ProductStateChip";
import {useExtendedAuth} from "@tracabois/connect";
import {useTranslation} from "react-i18next";

interface RealizationTableProps {
  page?: PageResult<Product>
  isLoading?: boolean
  actions: React.ReactNode
  onPageQueryChange: (page: PageQuery) => void;
  onRowClicked: (row: Row<Product>) => void;
}

export const ProductTable = (props: RealizationTableProps) => {
  const {page, isLoading, actions, onPageQueryChange, onRowClicked} = props
  const onSearch = (search?: string) => {
    onPageQueryChange({
      pagination: page?.pagination || firstPage,
      search: search
    })
  }
  const onPaginationChange = (pagination: OffsetPagination) => {
    onPageQueryChange({
      pagination: pagination,
      search: page?.search
    })
  }
  const filter = (<PlatformTableSearchFilters actions={actions} onSubmit={(props) => onSearch(props.search)}/>)
  const column = useColumns()
  return (
    <PlatformPaginatedTable<Product>
      page={page}
      columns={column}
      isLoading={isLoading}
      actions={filter}
      onPageChange={onPaginationChange}
      onRowClicked={onRowClicked}
    />
  )
}

const useColumns = (): Column<Product>[] => {
  const {t} = useTranslation()
  const {service} = useExtendedAuth()
  const actorColumn: Column<Product>[] = service.isMarque() ? [{
    Header: `${t("product.transformation.actor", "Acteur de la transformation")}`,
    // @ts-ignore
    Cell: ({row}: CellProps<Product>) => (
      <TableCellText main={row.original.transformation?.actor?.name}/>
    )
  } as Column<Product>] : []

  return [{
    Header: `${t("product.ouvrage.name", "Nom de l'ouvrage")}`,
    // @ts-ignore
    Cell: ({row}: CellProps<Product>) => (
      <TableCellText main={row.original?.ouvrage?.name ?? " - "}/>
    )
  }, {
    Header: `${t("product.name", "Nom du produit")}`,
    // @ts-ignore
    Cell: ({row}: CellProps<Product>) => (
      <TableCellText main={row.original.name}/>
    )
  },
    // @ts-ignore
    ...actorColumn,
    // @ts-ignore
    {
    Header: `${t("product.status", "Status")}`,
    // @ts-ignore
    Cell: ({row}: CellProps<Product>) => (
      <TableCellTag
        main={<ProductStateChip item={row.original} editable={false}/>}
        second={`Créé le ${new Date(row.original.creationDate).toLocaleDateString("fr-FR")}`}
      />
    )
  },
    // @ts-ignore
    {
    Header: `${t("product.volumes", "Volume")}`,
    // @ts-ignore
    Cell: ({row}: CellProps<Product>) => (
      <TableCellText main={`${row.original.volume.value} ${row.original.volume.unit}`}/>
    )
  }]
}


import {config} from "../../../config";
import {createApi} from "@reduxjs/toolkit/dist/query/react";
import {firstPage, PageQuery, PageResult, toPagination} from "@tracabois/utils";
import {
  RealizationCertification,
  RealizationCertificationCreateCommand,
  RealizationCertificationCreatedEvent,
  RealizationCertificationDeleteCommand,
  RealizationCertificationDeletedEvent,
  RealizationCertificationId,
  RealizationCertificationUpdateCommand,
  RealizationCertificationUpdatedEvent
} from "../model/RealizationCertification";
import {useState} from "react";
import {authQuery} from "../../../utils/authQuery";

export const RealizationCertificationApi = createApi({
  reducerPath: 'realizationCertificationApi',
  baseQuery: authQuery({baseUrl: config.baseUrl}),
  tagTypes: ['RealizationCertification'],
  endpoints: (builder) => ({
    getRealizationCertificationById: builder.query<RealizationCertification, RealizationCertificationId>({
      query: (id: RealizationCertificationId) => ({
        url: `realizationCertificationGet`,
        method: 'POST',
        body: {id: id}
      }),
      providesTags: ['RealizationCertification'],
    }),
    realizationCertificationPage: builder.query<PageResult<RealizationCertification>, PageQuery>({
      query: (page: PageQuery) => ({
        url: `realizationCertificationPage`,
        method: 'POST',
        body: page
      }),
      transformResponse: (response: PageResult<RealizationCertification>[]) => response[0],
    }),
    realizationCertificationCreate: builder.mutation<RealizationCertificationCreatedEvent, RealizationCertificationCreateCommand>({
      query: (cmd: RealizationCertificationCreateCommand) => ({
        url: `realizationCertificationCreate`,
        method: 'POST',
        body: cmd
      }),
      transformResponse: (response: RealizationCertificationCreatedEvent[]) => response[0],
      invalidatesTags: ['RealizationCertification'],
    }),
    realizationCertificationUpdate: builder.mutation<RealizationCertificationUpdatedEvent, RealizationCertificationUpdateCommand>({
      query: (cmd: RealizationCertificationUpdateCommand) => ({
        url: `realizationCertificationUpdate`,
        method: 'POST',
        body: cmd
      }),
      transformResponse: (response: RealizationCertificationUpdatedEvent[]) => response[0],
      invalidatesTags: ['RealizationCertification'],
    }),
    realizationCertificationDelete: builder.mutation<RealizationCertificationDeletedEvent, RealizationCertificationDeleteCommand>({
      query: (cmd: RealizationCertification) => ({
        url: `realizationCertificationDelete`,
        method: 'POST',
        body: cmd
      }),
      transformResponse: (response: RealizationCertificationDeletedEvent[]) => response[0],
      invalidatesTags: ['RealizationCertification'],
    }),
  }),
})

export const {
  useGetRealizationCertificationByIdQuery, useRealizationCertificationPageQuery,
  useRealizationCertificationCreateMutation, useRealizationCertificationUpdateMutation, useRealizationCertificationDeleteMutation
} = RealizationCertificationApi


export const useRealizationCertificationAllQuery = () => {
  const {data, error, isLoading, refetch} = RealizationCertificationApi.useRealizationCertificationPageQuery({})
  const [pageQuery, setPageQuery] = useState<PageQuery>({
    pagination: firstPage
  })
  let sortedData =  [...data?.items ?? []]
  sortedData.sort((a, b) => {
    return  a.name.localeCompare(b.name)
  })
  if(pageQuery.search) {
    sortedData = sortedData.filter((item) => {
      return item.name.toLowerCase().includes(pageQuery.search?.toLowerCase() ?? "")
    })
  }
  const page = toPagination(sortedData, pageQuery.pagination)
  const getAll = () => sortedData

  return {data: page, error, isLoading, refetch, setPageQuery, getAll}
}
import {config} from "../../../config";
import {createApi} from "@reduxjs/toolkit/dist/query/react";
import {firstPage, PageQuery, PageResult, toPagination} from "@tracabois/utils";
import {
  RemovalZoneCertification,
  RemovalZoneCertificationCreateCommand,
  RemovalZoneCertificationCreatedEvent,
  RemovalZoneCertificationDeleteCommand,
  RemovalZoneCertificationDeletedEvent,
  RemovalZoneCertificationId,
  RemovalZoneCertificationUpdateCommand,
  RemovalZoneCertificationUpdatedEvent
} from "../model/RemovalZoneCertification";
import {useState} from "react";
import {authQuery} from "../../../utils/authQuery";

export const RemovalZoneCertificationApi = createApi({
  reducerPath: 'removalZoneCertificationApi',
  baseQuery: authQuery({baseUrl: config.baseUrl}),
  tagTypes: ['RemovalZoneCertification'],
  endpoints: (builder) => ({
    getRemovalZoneCertificationById: builder.query<RemovalZoneCertification, RemovalZoneCertificationId>({
      query: (id: RemovalZoneCertificationId) => ({
        url: `removalZoneCertificationGet`,
        method: 'POST',
        body: {id: id}
      }),
      providesTags: ['RemovalZoneCertification'],
    }),
    removalZoneCertificationPage: builder.query<PageResult<RemovalZoneCertification>, PageQuery>({
      query: (page: PageQuery) => ({
        url: `removalZoneCertificationPage`,
        method: 'POST',
        body: page
      }),
      transformResponse: (response: PageResult<RemovalZoneCertification>[]) => response[0],
    }),
    removalZoneCertificationCreate: builder.mutation<RemovalZoneCertificationCreatedEvent, RemovalZoneCertificationCreateCommand>({
      query: (cmd: RemovalZoneCertificationCreateCommand) => ({
        url: `removalZoneCertificationCreate`,
        method: 'POST',
        body: cmd
      }),
      transformResponse: (response: RemovalZoneCertificationCreatedEvent[]) => response[0],
      invalidatesTags: ['RemovalZoneCertification'],
    }),
    removalZoneCertificationUpdate: builder.mutation<RemovalZoneCertificationUpdatedEvent, RemovalZoneCertificationUpdateCommand>({
      query: (cmd: RemovalZoneCertificationUpdateCommand) => ({
        url: `removalZoneCertificationUpdate`,
        method: 'POST',
        body: cmd
      }),
      transformResponse: (response: RemovalZoneCertificationUpdatedEvent[]) => response[0],
      invalidatesTags: ['RemovalZoneCertification'],
    }),
    removalZoneCertificationDelete: builder.mutation<RemovalZoneCertificationDeletedEvent, RemovalZoneCertificationDeleteCommand>({
      query: (cmd: RemovalZoneCertification) => ({
        url: `removalZoneCertificationDelete`,
        method: 'POST',
        body: cmd
      }),
      transformResponse: (response: RemovalZoneCertificationDeletedEvent[]) => response[0],
      invalidatesTags: ['RemovalZoneCertification'],
    }),
  }),
})

export const {
  useGetRemovalZoneCertificationByIdQuery, useRemovalZoneCertificationPageQuery,
  useRemovalZoneCertificationCreateMutation, useRemovalZoneCertificationUpdateMutation, useRemovalZoneCertificationDeleteMutation
} = RemovalZoneCertificationApi


export const useRemovalZoneCertificationAllQuery = () => {
  const {data, error, isLoading, refetch} = RemovalZoneCertificationApi.useRemovalZoneCertificationPageQuery({})
  const [pageQuery, setPageQuery] = useState<PageQuery>({
    pagination: firstPage
  })
  let sortedData =  [...data?.items ?? []]
  sortedData.sort((a, b) => {
    return  a.name.localeCompare(b.name)
  })
  if(pageQuery.search) {
    sortedData = sortedData.filter((item) => {
      return item.name.toLowerCase().includes(pageQuery.search?.toLowerCase() ?? "")
    })
  }
  const page = toPagination(sortedData, pageQuery.pagination)
  const getAll = () => sortedData

  return {data: page, error, isLoading, refetch, setPageQuery, getAll}
}
import {org} from "boislocal-platform-platform-api-api-js-export";

import {OrganizationRef} from "connect-im";

export type ProductTypePageQuery = org.boislocal.platform.s2.productType.domain.query.ProductTypePageQueryDTO

export type ProductTypeCreateCommand = org.boislocal.platform.s2.productType.domain.command.ProductTypeCreateCommandDTO
export type ProductTypeCreatedEvent = org.boislocal.platform.s2.productType.domain.command.ProductTypeCreatedEventDTO

export type ProductTypeUpdateCommand = org.boislocal.platform.s2.productType.domain.command.ProductTypeUpdateCommandDTO
export type ProductTypeUpdatedEvent = org.boislocal.platform.s2.productType.domain.command.ProductTypeUpdatedEventDTO

export type ProductTypeDeleteCommand = org.boislocal.platform.s2.productType.domain.command.ProductTypeDeleteCommandDTO
export type ProductTypeDeletedEvent = org.boislocal.platform.s2.productType.domain.command.ProductTypeDeletedEventDTO

export type ProductType = org.boislocal.platform.s2.productType.domain.model.ProductTypeModelDTO
export type ProductTypeState = org.boislocal.platform.s2.productType.domain.automate.ProductTypeState

export const filterByOrganizationId = (productTypes: ProductType[], organizationRefs: OrganizationRef[], organizationId?: string): ProductType[]  => {
  const organizationRefsFound = organizationRefs.find( it => it.id == organizationId)
  const organizationRoles = organizationRefsFound?.roles
  return filterByOrganizationRole(productTypes, organizationRoles)
}
export const filterByOrganizationRef = (productTypes: ProductType[], organizationRef: OrganizationRef): ProductType[]  => {
  const organizationRoles = organizationRef.roles
  return filterByOrganizationRole(productTypes, organizationRoles)
}
export const filterByOrganizationRefs = (productTypes: ProductType[], organizationRefs: OrganizationRef[]): ProductType[]  => {
  const organizationRoles = organizationRefs.flatMap(ref =>   ref.roles)
  return filterByOrganizationRole(productTypes, organizationRoles)
}

const filterByOrganizationRole = (productTypes: ProductType[], roles?: OrganizationRole[]): ProductType[]  => {
  let filteringProductTypes = [...productTypes]
  if(!!roles) {
    filteringProductTypes = filteringProductTypes.filter((item: ProductType) => {
      const itemName = item.organizationTypes.map(it => it.name)
      return roles?.map(roleName => {
        return itemName.includes(roleName)
      }).reduce(
        (previousValue, currentValue) => previousValue || currentValue,
        false
      );
    })
  }
  return filteringProductTypes
}


type OrganizationRole = string

export type ProductTypeId = string

import React from 'react'
import { ReactComponent } from './icon.svg'
import { MergeReactElementProps } from '@komune-io/g2-utils'

interface HomeProps {
  color?: string
}

type Props = MergeReactElementProps<'svg', HomeProps>

export const HomeIcon = React.forwardRef(
  (props: Props, ref: React.Ref<SVGSVGElement>) => {
    const { color = '#828282' } = props
    return <ReactComponent fill={color} ref={ref} {...props} />
  }
)

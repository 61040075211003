import {useState} from "react";
import {OuvrageTypeFormPopup} from "../form/OuvrageTypeFormPopup";
import {OuvrageTypeTable} from "../table/OuvrageTypeTable";

import {Button} from '@komune-io/g2-components'
import {useTranslation} from "react-i18next";
import {Row} from "@komune-io/g2-layout";
import {
  OuvrageType,
  OuvrageTypeCreateCommand,
  OuvrageTypeDeleteCommand,
  OuvrageTypeUpdateCommand
} from "../../model/OuvrageType";
import {
  useOuvrageTypeAllQuery,
  useOuvrageTypeCreateMutation, useOuvrageTypeDeleteMutation,
  useOuvrageTypeUpdateMutation
} from "../../api/OuvrageTypeApi";
import {PlatformSubSection} from "@tracabois/template";

interface OuvrageTypeSectionProps {
  title: string
}

export const OuvrageTypeSection = (props: OuvrageTypeSectionProps) => {
  const {title} = props
  const [selectedItem, setSelectedItem] = useState<Partial<OuvrageType> | undefined>(undefined)
  const {t} = useTranslation()
  const ouvrageTypePageQuery = useOuvrageTypeAllQuery();
  const [createOuvrageType] = useOuvrageTypeCreateMutation()
  const [updateOuvrageType] = useOuvrageTypeUpdateMutation()
  const [deleteOuvrageType] = useOuvrageTypeDeleteMutation()

  const closePopup = () => {
    setSelectedItem(undefined)
  }

  const handleCreate = async (item: OuvrageTypeCreateCommand) => {
    await createOuvrageType(item)
    closePopup()
    ouvrageTypePageQuery.refetch()
    return true
  }
  const handleUpdate = async (item: OuvrageTypeUpdateCommand) => {
    await updateOuvrageType(item)
    closePopup()
    ouvrageTypePageQuery.refetch()
    return true
  }
  const handleDelete = async (item: OuvrageTypeDeleteCommand) => {
    await deleteOuvrageType(item)
    closePopup()
    ouvrageTypePageQuery.refetch()
    return true
  }
  const clickHandler = (wood: Row<OuvrageType>) => {
    setSelectedItem(wood.original)
  }
  const actions = (<Button color="primary"
                           onClick={() => setSelectedItem({})}>{t("ouvrageType.action.create", "Ajouter un type")}</Button>)
  return (
    <PlatformSubSection title={title}>
      <OuvrageTypeTable page={ouvrageTypePageQuery.data}
                        onPageQueryChange={ouvrageTypePageQuery.setPageQuery}
                        onRowClicked={clickHandler}
                        isLoading={ouvrageTypePageQuery.isLoading}
                        actions={actions}
      />
      <OuvrageTypeFormPopup
        open={!!selectedItem} item={selectedItem || {}}
        onCreate={handleCreate}
        onUpdate={handleUpdate}
        onDelete={handleDelete}
        onCancel={closePopup}
      />
    </PlatformSubSection>
  )
}

import {Tooltip} from '@komune-io/g2-notifications'
import {Ticket as G2Ticket} from '@komune-io/g2-components'
import React from "react";
import {makeG2STyles, useTheme} from '@komune-io/g2-themes'
import {Skeleton} from "@mui/material";
import { theme } from '@tracabois/domain';

const useStyles = makeG2STyles()(
  () => ({
    ticket: {
      '& .AruiTicket-root': {
        maxWidth: '220px',
        padding: '5px'
      },
    },
    title: {
      color: theme.colors?.primary,
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: 'normal'
    },
    content: {
      color: '#352328',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: 'normal'
    }
  }))

export interface TicketProps {
  icon: React.ReactElement;
  title: string
  indicateur: string | number
  unit?: String
  isLoading?: boolean
  tooltips?: string
}

export const Ticket = (props: TicketProps) => {
  const theme = useTheme()
  const {classes} = useStyles()
  const {icon, title, indicateur, unit, isLoading = false, tooltips} = props

  const coloredIcon = React.cloneElement(icon, {color: theme.colors.primary})
  const content = !!unit ? `${indicateur} ${unit}` : indicateur

  const ticket = (
    <G2Ticket className={classes.ticket} classes={{title: classes.title, content: classes.content}} icon={coloredIcon} title={content} content={title}/>
  )

  if(isLoading)
    return <G2Ticket className={classes.ticket} icon={coloredIcon} title={<Skeleton />} content={<></>}/>;

  if(!!tooltips) {
    return (
      <span className={classes.ticket}>
        <Tooltip helperText={tooltips}>
          {ticket}
        </Tooltip>
      </span>
    )
  }
  return (
    <span className={classes.ticket}>
      {ticket}
    </span>
  )
}

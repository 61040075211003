import {Stack} from '@mui/material'
import React from 'react'

export interface PlatformActionsProps {
  actions?: React.ReactNode
}

export const PlatformActions = (props: PlatformActionsProps) => {
  const {
    actions,
  } = props
  return (
    <Stack
      direction='row'
      justifyContent='flex-end'
      sx={{
        zIndex: 1000,
        padding: '10px 20px'
      }}
    >
      <Stack>
      {actions}
      </Stack>
    </Stack>
  )
}

import {useOrganizationFormState} from "connect-im";
import {useNavigate, useParams} from "react-router";
import {PlatformPage} from "@tracabois/template";
import {useTranslation} from "react-i18next";
import {organizationRoutes} from "../../route";
import {Action} from "@komune-io/g2";
import {useFeedback} from "@tracabois/components";
import {organizationRoles} from "../../../auth";
import {OrganizationForm} from "../../components/OrganizationForm";

export interface OrganizationCreatePageProps {
  title?: string
  organizationId?: string
}

export const OrganizationCreatePage =(props: OrganizationCreatePageProps) => {
  const {title} = props;
  const { organizationId = props.organizationId!!} = useParams<{ organizationId: string }>();
  const navigate = useNavigate()
  const {t} = useTranslation()
  const feedback = useFeedback()

  const organizationFormState = useOrganizationFormState({
    update: !!organizationId,
    organizationId: organizationId,
    defaultRoles: [...organizationRoles],
    multipleRoles: false,
    createOrganizationOptions: {
      onSuccess: (data) => {
        organizationRoutes.goto.editOrganization(data!!.id, navigate)
      }
    }
  })

  const actions: Action[] = [{
    key: "create",
    label: t("create"),
    onClick: async (): Promise<boolean> => {
      await organizationFormState.formState.submitForm()
      feedback.setFeedback(true)
      return true
    },
    success: feedback.isSuccess,
    fail: feedback.isFailure,
  }]
  return (
    <PlatformPage title={title ?? t("organization.one")} actions={actions}>
      <OrganizationForm 
        organizationId={organizationId}
        formState={organizationFormState.formState}
        organization={organizationFormState.organization}
        isLoading={organizationFormState.isLoading}
      />
    </PlatformPage>
  );
};

 import {FormComposable} from "@tracabois/components";
import {Stack} from '@mui/material'
import {BtmcOrganization} from "../../../model";
import {DropZonePicture} from "@komune-io/g2-fs";
import {DropZonePictureAction} from "./BrandNumberSection";
import {useFormFieldComposable} from "@komune-io/g2";
import {FormComposableState} from "@komune-io/g2-composable/dist/FormComposable/type";
import { brandConfig } from "@tracabois/domain";

export interface BrandNumberFormProps {
  dropZonePicture: DropZonePictureAction
  item: BtmcOrganization
  form: FormComposableState
}

export const BrandNumberForm = (props: BrandNumberFormProps) => {
  const {
    dropZonePicture,
    item,
    form
  } = props

  const formFields = useFormFieldComposable({
    fields: [
      {
        key: 'btmcNumber',
        name: 'btmcNumber',
        type: 'textField',
        label: `Identifiant ${brandConfig().acronym}`,
      }
    ],
  },[item.btmcNumber])

  return (
    <Stack
      direction='row'
      justifyContent='space-between'
      spacing={2}
    >
      <FormComposable
        fields={formFields.fields}
        formState={form}
      />
      <DropZonePicture {...dropZonePicture}/>
    </Stack>
  )
}

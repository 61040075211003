import {useCallback, useMemo, useState} from 'react'
import {FormAction} from '@komune-io/g2-forms'
import {BasicProps} from '@komune-io/g2-themes'
import {FormComposable, FormComposableField, useFeedback} from "@tracabois/components";
import {DocumentHandler, fileToBase64, useFormComposable} from "@komune-io/g2";
import { CreditedFile, FileName, ExternalFile, METADATA_CREDIT_KEY, externalFileUrl, config } from '@tracabois/domain';
import { Box } from '@mui/material';
import {useTranslation} from "react-i18next";

type CreditedImageFormValue = {
  credit: string,
  toUseAsCover: boolean
}

export interface CreditedImageFormProps extends BasicProps {
  item?: ExternalFile
  isCover?: boolean
  onAdd: (item: CreditedFile, toUseAsCover: boolean) => Promise<boolean> | boolean
  onUpdate: (name: FileName) => Promise<boolean> | boolean
  onDelete: (name: FileName) => Promise<boolean> | boolean
  onCancel: () => void
}

export const CreditedImageForm = (props: CreditedImageFormProps) => {
  const {
    item,
    isCover,
    onAdd,
    onUpdate,
    onDelete,
    onCancel
  } = props
  const [fileError, setFileError] = useState<string | undefined>()
  const [submittedFile, setSubmittedFile] = useState<File | undefined>()
  const { t } = useTranslation()
  const submitFeedback = useFeedback()
  const labels = {
    submit: "Enregistrer",
    delete: "Supprimer",
    cancel: "Annuler"
  }

  const onFileSubmitted = useCallback((file: File) => {
    setFileError(undefined)
    const format = /[`!@#$%^&*()+=\[\]{};':"\\|,<>\/?~]/
    console.log("config.imageMaxSize", config.imageMaxSize)
    console.log("file", file)
    if (format.test(file.name)) {
      setFileError(t("Nom de fichier invalide : Évitez les caractères spéciaux."))
      return
    } else if (file.size > config.imageMaxSize) {
      setFileError(t(`Taille de fichier trop importante : La taille maximale autorisée est de ${config.imageMaxSize/(1000000)} Mo.`))
      return
    }
    setSubmittedFile(file)
  },[setFileError, setSubmittedFile])

  const onSubmitMemoized = useCallback(
    async (value: CreditedImageFormValue) => {
      if ( !item && !submittedFile) {
        setFileError(t("error.mandatoryFile"))
        return false
      }
      const result = submittedFile ? await onAdd({
        file: await fileToBase64(submittedFile),
        name: submittedFile.name,
        credit: value.credit,
      }, value.toUseAsCover) : await onUpdate(item!.name)
      submitFeedback.setFeedback(result)
      if (result) onCancel()
      return result
    },
    [item, submittedFile, onAdd, onUpdate]
  )

  const onDeleteClicked = useCallback(
    async () => {
      if (onDelete && item) {
        const result = await onDelete(item.name)
        if (result) onCancel()
        return result
      }
      return false
    },
    [onDelete, item]
  )

  const fields: FormComposableField[] = useMemo(
    (): FormComposableField[] => [
      {
        key: 'credit',
        name: 'credit',
        type: 'textField',
        label: 'Crédit photo',
        readOnly: item !== undefined
      },
      {
        key: 'toUseAsCover',
        name: 'toUseAsCover',
        type: 'checkBox',
        label: 'Utiliser cette image comme photo de couverture',
        readOnly: isCover
      },
    ],
    []
  )

  const formState = useFormComposable<CreditedImageFormValue>({
    onSubmit: onSubmitMemoized,
    formikConfig: {
      initialValues: {
        credit: item?.metadata?.[METADATA_CREDIT_KEY] ?? '',
        toUseAsCover: isCover ?? false,
      }
    },
  })

  const actions: FormAction[] =  useMemo(() =>[{
    key: "cancel",
    label: labels.cancel,
    onClick: onCancel,
    variant: "cancellation"
  }, {
    key: "delete",
    label: labels.delete,
    showIf: () => item != undefined,
    onClick: onDeleteClicked,
    variant: "deletion"
  }, {
    key: "submit",
    label: labels.submit,
    success: submitFeedback.isSuccess,
    fail: submitFeedback.isFailure,
    onClick: formState.submitForm
  }],[onCancel, labels.cancel, labels.delete, labels.submit, submitFeedback, formState, item])

  return (
    <Box style={{width: '500px'}}>
      <Box style={{paddingTop: '20px', paddingBottom: '20px'}}>
        <DocumentHandler
          fileTypesAllowed={['png', 'jpeg']}
          customErrorMessage={fileError}
          label={item?.name ?? submittedFile?.name ?? ''}
          onAdd={files => onFileSubmitted(files[0])}
          multiple={false}
          onDelete={() => setSubmittedFile(undefined)}
          uploaded={item !== undefined ?? submittedFile !== undefined}
          getFileUrl={() => externalFileUrl(item)}
          contentEditable={item == undefined}
        />
      </Box>
      <FormComposable
        fields={fields}
        formState={formState}
        actions={actions}
      />
    </Box>
  )
}

import {Button} from '@komune-io/g2-components'
import {Stack} from "@mui/material";
import {SubOrganizationFormPopup} from "../form/SubOrganizationFormPopup";
import {
  BtmcSubOrganizationAddCommand,
  BtmcSubOrganizationAddedEvent, BtmcSubOrganizationDeleteCommand,
  BtmcSubOrganizationUpdateCommand,
  SubOrganization
} from "../../../model";
import {
  useBtmcSubOrganizationAddMutation,
  useBtmcSubOrganizationDeleteMutation,
  useBtmcSubOrganizationUpdateMutation
} from "../../../api";


interface SubOrganizationButtonProps {
  organizationId: string
  item?: Partial<SubOrganization>
  onSelectItem: (subOrganization: SubOrganization | undefined) => void
  label: string
  onSubOrganizationCreated?: (evt: BtmcSubOrganizationAddedEvent) => void
  onSubOrganizationUpdated?: (evt: BtmcSubOrganizationUpdateCommand) => void
  onSubOrganizationDeleted?: (evt: BtmcSubOrganizationDeleteCommand) => void
}


export const SubOrganizationButton = (props: SubOrganizationButtonProps) => {
  const {
    organizationId, label, item,
    onSubOrganizationCreated, onSubOrganizationUpdated, onSubOrganizationDeleted,
    onSelectItem
  } = props

  const [createSubOrganization] = useBtmcSubOrganizationAddMutation()
  const [updateSubOrganization] = useBtmcSubOrganizationUpdateMutation()
  const [deleteSubOrganization] = useBtmcSubOrganizationDeleteMutation()

  const closePopup = () => {
    onSelectItem(undefined)
  }

  const handleCreate = async (item: BtmcSubOrganizationAddCommand) => {
    const event = await createSubOrganization(item)
    closePopup()
    // @ts-ignore
    onSubOrganizationCreated(event.data)
    return true
  }
  const handleUpdate = async (item: BtmcSubOrganizationUpdateCommand) => {
    const event = await updateSubOrganization(item)
    closePopup()
    // @ts-ignore
    onSubOrganizationUpdated(event.data)
    return true
  }
  const handleDelete = async (item: BtmcSubOrganizationDeleteCommand) => {
    const event = await deleteSubOrganization(item)
    closePopup()
    // @ts-ignore
    onSubOrganizationDeleted(event.data)
    return true
  }

  return (<>
    <Stack justifyContent="flex-end" direction="row">
        <Button onClick={() => onSelectItem({} as SubOrganization)}>{label}</Button>
    </Stack>
    <SubOrganizationFormPopup
      organizationId={organizationId}
      open={!!item} item={item || {}}
      onCreate={handleCreate}
      onUpdate={handleUpdate}
      onDelete={handleDelete}
      onCancel={closePopup}
    />
  </>)
}

import {useCallback, useMemo} from 'react'
import {FormAction} from '@komune-io/g2-forms'
import {BasicProps} from '@komune-io/g2-themes'
import {
  RealizationCertificationIdLink,
  RealizationCertificationLink,
} from "../../../model/realizationModel";
import {FormComposable, FormComposableField, useFeedback} from "@tracabois/components";
import {useFormComposable} from "@komune-io/g2";
import { RealizationCertification, RealizationCertificationId } from '../../../../../referentiels/realizationCertification';


export interface RealizationCertificationLinkFormLabel {
  submit?: string,
  delete?: string,
  cancel?: string
}

export interface RealizationCertificationLinkFormProps extends BasicProps {
  item?: RealizationCertificationLink
  labels?: RealizationCertificationLinkFormLabel
  realizationCertifications: RealizationCertification[]
  onSubmit?: (item: RealizationCertificationIdLink) => Promise<boolean> | boolean
  onDelete?: (id: RealizationCertificationId) => Promise<boolean> | boolean
  onCancel?: () => void
}

export const RealizationCertificationLinkForm = (props: RealizationCertificationLinkFormProps) => {
  const {
    item,
    realizationCertifications,
    onSubmit,
    onDelete,
    onCancel
  } = props
  const submitFeedback = useFeedback()
  const labels = {
    submit: props.labels?.submit ?? "Enregistrer",
    delete: props.labels?.delete ?? "Supprimer",
    cancel: props.labels?.cancel ?? "Annuler"
  }

  const onSubmitMemoized = useCallback(
    async (values: RealizationCertificationIdLink) => {
      if (onSubmit) {
        const result = await onSubmit(values)
        submitFeedback.setFeedback(result)
        return result
      }
      return false
    },
    [onSubmit]
  )

  const onDeleteClicked = useCallback(
    async () => {
      if (onDelete && item) {
        return await onDelete(item.model.id)
      }
      return false
    },
    [onDelete, item]
  )

  const fields: FormComposableField[] = useMemo(
    (): FormComposableField[] => [
      {
        key: 'id',
        name: 'id',
        type: 'autoComplete',
        label: 'Certification',
        readOnly: item != undefined,
        params: {
          placeholder: 'Sélectionner',
          options: realizationCertifications.map((item) => ({key: item.id, label: item.name})),
          limitTags: 1,
          multiple: false,
        },
      },
      {
        key: 'url',
        name: 'url',
        type: 'textField',
        label: 'Url',
      },
    ],
    []
  )

  const formState = useFormComposable<RealizationCertificationIdLink>({
    onSubmit: onSubmitMemoized,
    formikConfig: {
      initialValues: {
        id: item?.model.id,
        url: item?.url,
      }
    },
  })

  const actions: FormAction[] =  useMemo(() =>[{
    key: "cancel",
    label: labels.cancel,
    onClick: onCancel,
    variant: "cancellation"
  }, {
    key: "delete",
    label: labels.delete,
    showIf: () => item != undefined,
    onClick: onDeleteClicked,
    variant: "deletion"
  }, {
    key: "submit",
    label: labels.submit,
    success: submitFeedback.isSuccess,
    fail: submitFeedback.isFailure,
    onClick: formState.submitForm
  }],[onCancel, labels.cancel, labels.delete, labels.submit, submitFeedback, formState, item])

  return (
    <FormComposable
      fields={fields}
      formState={formState}
      actions={actions}
  />)
}

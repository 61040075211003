import {Tooltip} from '@komune-io/g2-notifications'
import { Grid } from "@mui/material";
import { makeG2STyles } from "@komune-io/g2-themes";
import { cx } from "@emotion/css";
import { DocumentHandler, FormComposable, FormComposableField } from "@tracabois/components";
import { Button, useFormComposable } from "@komune-io/g2";
import { useCallback, useMemo, useState } from "react";
import { ProductId, ProductSection } from "../../../model/product";
import {
  ProductEvidenceDeletedEvent,
  ProductEvidenceFile,
  ProductEvidenceUploadedEvent, ProduitEvidencePoliciesResult,
  toProductEvidenceSection
} from "../../../model/productEvidence";
import { DeleteButton, useLocalDocumentHandler } from "@komune-io/g2-components";
import { useProductEvidenceUploadFunction } from "../../../api";
import { useProductEvidenceDeleteFunction } from "../../../api/useProductEvidenceDeleteFunction";
import {useProductEvidenceDownloadQuery} from "../../../api/useProductEvidenceDownloadFunction";
import {useTranslation} from "react-i18next";

const useStyles = makeG2STyles()(
  () => ({
    line: {
      padding: '20px',
      alignItems: "center"
    },
  }))

export interface ProductEvidenceUploadLineProps {
  productId: ProductId,
  policies: ProduitEvidencePoliciesResult
  section: ProductSection,
  evidence?: ProductEvidenceFile,
  isLoading: boolean,
  readOnly: boolean,
  deletable?: boolean,
  onProductEvidenceUploaded?: (event: ProductEvidenceUploadedEvent | ProductEvidenceDeletedEvent) => void
}

export const ProductEvidenceUploadLine = (props: ProductEvidenceUploadLineProps) => {
  const {
    isLoading, readOnly,
    section, productId,
    evidence, onProductEvidenceUploaded,
    policies, deletable = false
  } = props
  const { t } = useTranslation()
  const { classes } = useStyles()
  const [fileError, setFileError] = useState<string | undefined>()

  const isReadOnly = readOnly || !policies.canUpload
  const fields = useMemo((): FormComposableField[] => ([
    {
      name: 'identifier',
      type: 'textField',
      params: {
        disabled: isReadOnly,
        placeholder: 'N° de BL ou Facture',
      }
    }, {
      name: 'date',
      type: 'datePicker',
      params: {
        disabled: isReadOnly,
      }
    }
  ]), []
  )
  const { file, resetFile, documentHandlerProps } = useLocalDocumentHandler()
  const useProductEvidenceUpload = useProductEvidenceUploadFunction()
  const useProductEvidenceDelete = useProductEvidenceDeleteFunction()
  const useProductEvidenceDownload = useProductEvidenceDownloadQuery()


  const getProductEvidenceUrl = useCallback(async () => {
    if(!evidence) return undefined
    return await useProductEvidenceDownload({
      id: productId,
      evidenceId: evidence.evidenceId,
      fileName: evidence.fileName,
    })
  }, [productId, evidence])


  const handleDelete = useCallback(async () => {
    if (!evidence || !policies.canDelete) {
      return false
    }
    const event = await useProductEvidenceDelete.mutateAsync({
      evidenceId: evidence.evidenceId,
      fileName: evidence.fileName,
      id: productId
    })
    onProductEvidenceUploaded && onProductEvidenceUploaded(event!!)
    return true
  }, [evidence, productId])
  const handleSubmit = useCallback(async (model: ProductEvidenceUploadForm): Promise<boolean> => {
    if (!policies.canUpload) {
      return false
    }
    if (!file) {
      setFileError(t("error.mandatoryFile"))
      return false
    }
    const event = await useProductEvidenceUpload.mutateAsync({
      command: {
        id: productId,
        section: toProductEvidenceSection(section),
        identifier: model.identifier,
        date: model.date,
        evidenceId: evidence?.evidenceId
      },
      files: [...(file ? [{ file: file }] : [])]
    })
    onProductEvidenceUploaded && onProductEvidenceUploaded(event!!)
    formState.resetForm()
    resetFile()

    return true
  }, [file, evidence, productId])

  const formState = useFormComposable<ProductEvidenceUploadForm>({
    onSubmit: handleSubmit,
    isLoading: isLoading,
    formikConfig: {
      initialValues: {
        identifier: evidence?.identifier,
        date: evidence?.date
      },
    }
  })

  return (
    <Grid
      className={cx(classes.line)}
      container spacing={2} direction="row" 
      flexWrap="unset"
      >
    <Tooltip helperText={file?.name ?? evidence?.fileName ?? ''}>
      <Grid item xs={5}>
        <DocumentHandler
          fileTypesAllowed={['pdf', 'doc', 'docx', 'xls', 'xlsx']}
          customErrorMessage={fileError}
          label={file?.name ?? evidence?.fileName ?? ''}
          {...documentHandlerProps}
          onDelete={!!evidence ? undefined : documentHandlerProps?.onDelete}
          uploaded={!!evidence}
          getFileUrl={getProductEvidenceUrl}
          hidden={!policies.canDownload}
        />
      </Grid>
    </Tooltip>
      <Grid item xs={5}>
        <FormComposable
          fields={fields}
          formState={formState}
          fieldsStackProps={{ direction: "row" }}
        />
      </Grid>
      <Grid item xs={2.5} >
        { policies.canUpload && <Button onClick={formState.submitForm}>Valider</Button> }
        { deletable && policies.canDelete && <DeleteButton onClick={handleDelete}/> }
      </Grid>
    </Grid>
  )
}

interface ProductEvidenceUploadForm {
  identifier: string,
  date: string
}

import Box from '@mui/material/Box';
import {RouteTicket, WoodTicket} from "@tracabois/components";
import {OuvrageIndicator} from "../../model/ouvrage";

export interface OuvrageIndicatorGridProps {
  indicators?: OuvrageIndicator
}

export const OuvrageIndicatorGrid = (props: OuvrageIndicatorGridProps) => {
  const {indicators} = props
  return (
    <Box display="flex" flexWrap="wrap" justifyContent="space-around">
      {!!indicators?.wood && <WoodTicket indicateur={indicators.wood}/>}
      {!!indicators?.route && <RouteTicket indicateur={indicators.route}/>}
    </Box>
  )
};

import {Stack} from "@mui/material";
import {NameForm, NameViewForm} from "../NameForm/NameForm";

export interface PlatformPageHeaderTitleProps {
    prefix?: string
    title?: string
    editableTitle?: boolean
    onTitleChange?: (name: string) => void
    onTitleSave?: (name: string) => boolean | Promise<boolean>
}

export const PlatformPageObjectHeaderTitle = (props: PlatformPageHeaderTitleProps) => {
    const {prefix, title, editableTitle = false, onTitleSave, onTitleChange} = props
    return (
      <Stack direction="row" justifyContent="flex-start" spacing={2} width={"100%"}>
          {prefix && <Stack><NameViewForm  name={prefix}/></Stack>}
          {editableTitle && <Stack><NameForm name={title || ""} onChange={onTitleChange} onSave={onTitleSave}/></Stack>}
          {!editableTitle && <Stack><NameViewForm name={title || ""}/></Stack>}
      </Stack>
    )
}

import {Stack, Typography} from "@mui/material";

export interface TableCellTextProps {
  main: string
  second?: string
}

export const TableCellText = (props: TableCellTextProps) => {
  const {main, second} = props
  const text = (!main?.trim()?.length ?? 0) ? " - " : main;
  return (
    <Stack>
      <Typography color="secondary" variant="subtitle2">{text}</Typography>
      {second && <Typography variant="overline">{second}</Typography>}
    </Stack>
  )
}

import {Product, ProductModel, toProductState,} from "@tracabois/domain";
import {useMemo} from "react";
import {ObjectStateChip} from "@tracabois/components";
import {useProductStateAction} from "./useProductStateAction";

export interface ProductStateChipProps {
  item?: Product | ProductModel
  onStateChanged?: () => void
  editable?: boolean
}

export const ProductStateChip = (props: ProductStateChipProps) => {
  const {item, onStateChanged, editable} = props
  const action = useProductStateAction(item, onStateChanged)
  const state = useMemo(() => {
    return item && toProductState(item)
  }, [item])
  return (
    <ObjectStateChip editable={editable} state={state} stateAction={action}/>
  );
}

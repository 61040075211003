import {KeycloackService, useAuth} from "@komune-io/g2-providers"
import {TFunction} from "i18next"

export const rolesOptions = (t: TFunction) => [{
  key: "admin",
  label: t("roles.admin")
  },
  {
    key: "user",
    label: t("roles.user")
  }]

export const userRolesColors: { [roles in UserRoles]: string } = {
  "super_admin": "#d1b00a",
  "admin": "#E56643",
  "user": "#3041DC"
}

export const userRoles = [ "admin", "user"]
export const organizationRoles = [
  "Exploitant forestier", "Scieur", "Lamelliste", "Négoce", "Charpentier",
  "Menuisier", "COFOR", "Marque", "Maitre d'oeuvre", "Maitre d'ouvrage", "Financeur"
] as const


export const OrgRolesArray = [...userRoles, ...organizationRoles]

export type UserRoles = typeof OrgRolesArray[number]

const mutableUserRoles: UserRoles[] = [...OrgRolesArray]


export const useExtendedAuth = () => useAuth<AuthServices, UserRoles>(mutableUserRoles, staticServices, {})


export const orgRolesOptions = [{
  key: "Exploitant forestier",
  label: "Exploitant forestier"
}, {
  key: "Scieur",
  label: "Scieur"
},{
  key: "Lamelliste",
  label: "Lamelliste"
}, {
  key: "Négoce",
  label: "Négoce"
},{
  key: "Charpentier",
  label: "Charpentier"
}, {
  key: "Menuisier",
  label: "Menuisier"
},{
  key: "COFOR",
  label: "COFOR"
}, {
  key: "Marque",
  label: "Marque"
},{
  key: "Maitre d'oeuvre",
  label: "Maitre d'oeuvre"
}, {
  key: "Maitre d'ouvrage",
  label: "Maitre d'ouvrage"
}, {
  key: "Financeur",
  label: "Financeur"
}]

export type User = {
  id: string
  email: string
  firstName: string
  lastName: string
}

export type AuthServices = {
  getMemberOf: { returnType: string | undefined },
  isAdmin: { returnType: boolean },
  isCharpentier: { returnType: boolean },
  isCOFOR: { returnType: boolean },
  isExploitant: { returnType: boolean },
  isFinanceur: { returnType: boolean },
  isLamelliste: { returnType: boolean },
  isMarque: { returnType: boolean },
  isMenuisier: { returnType: boolean },
  isMOA: { returnType: boolean },
  isMOE: { returnType: boolean },
  isNegoce: { returnType: boolean },
  isScierie: { returnType: boolean },
  isFirstTransformationActor: { returnType: boolean },
}

const staticServices: KeycloackService<AuthServices, UserRoles> = {

  getMemberOf(keycloak): string | undefined {
    // @ts-ignore
    return keycloak.tokenParsed.memberOf ?? undefined
  },

  isFirstTransformationActor(_, service): boolean {
    return  service.hasRole("Exploitant forestier") ||  service.hasRole("Scieur")
  },
  isAdmin: (_, service) => {
    return service.hasRole("admin") || service.hasRole("Marque") || service.hasRole("super_admin")
  },
  isCharpentier: (_, service) => {
    return service.hasRole("Charpentier")
  },
  isCOFOR: (_, service) => {
    return service.hasRole("COFOR")
  },
  isExploitant: (_, service) => {
    return service.hasRole("Exploitant forestier")
  },
  isFinanceur: (_, service) => {
    return service.hasRole("Financeur")
  },
  isLamelliste: (_, service) => {
    return service.hasRole("Lamelliste")
  },
  isMarque: (_, service) => {
    return service.hasRole("Marque") || service.hasRole("super_admin")
  },
  isMenuisier: (_, service) => {
    return service.hasRole("Menuisier")
  },
  isMOA: (_, service) => {
    return service.hasRole("Maitre d'ouvrage")
  },
  isMOE: (_, service) => {
    return service.hasRole("Maitre d'oeuvre")
  },
  isNegoce: (_, service) => {
    return service.hasRole("Négoce")
  },
  isScierie: (_, service) => {
    return service.hasRole("Scieur")
  }
}

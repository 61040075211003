import {org} from "boislocal-platform-platform-api-api-js-export";
import {ProductSection} from "./product";

export type ProductEvidenceUploadCommand = org.boislocal.platform.f2.product.evidence.domain.command.ProductEvidenceUploadCommandDTO
export type ProductEvidenceUploadedEvent = org.boislocal.platform.f2.product.evidence.domain.command.ProductEvidenceUploadedEventDTO

export type ProductEvidenceDeleteCommand = org.boislocal.platform.f2.product.evidence.domain.command.ProductEvidenceDeleteCommandDTO
export type ProductEvidenceDeletedEvent = org.boislocal.platform.f2.product.evidence.domain.command.ProductEvidenceDeletedEventDTO

export type ProductEvidenceListQuery = org.boislocal.platform.f2.product.evidence.domain.query.ProductEvidenceListQueryDTO
export type ProductEvidenceListResult = org.boislocal.platform.f2.product.evidence.domain.query.ProductEvidenceListResultDTO

export type ProductEvidenceFile = org.boislocal.platform.f2.product.evidence.domain.model.ProductEvidenceFileDTO
export type ProductEvidenceSection = org.boislocal.platform.f2.product.evidence.domain.model.ProductEvidenceSectionDTO

export const ProductEvidenceSectionValues: Record<string, ProductEvidenceSection> = {
  from: {value: org.boislocal.platform.f2.product.evidence.domain.model.ProductEvidenceSectionValues.from()},
  to: {value: org.boislocal.platform.f2.product.evidence.domain.model.ProductEvidenceSectionValues.to()}
}
export const toProductEvidenceSection = (section: ProductSection): ProductEvidenceSection => {
  return ProductEvidenceSectionValues[section]
}

export type ProductEvidencePoliciesType = typeof org.boislocal.platform.f2.product.evidence.domain.policy.ProductEvidencePolicies
const policies = org.boislocal.platform.f2.product.evidence.domain.policy.ProductEvidencePolicies
const policiesKeys = Object.keys(Object.getPrototypeOf(policies)) as any[]
export const ProduitEvidencePolicies: ProductEvidencePoliciesType = policiesKeys.reduce<ProductEvidencePoliciesType>((object, key) => {
  //@ts-ignore
  object[key] = policies[key].bind(policies)
  return object
  //@ts-ignore
}, {})

export interface ProduitEvidencePoliciesResult {
  canUpload: boolean
  canDelete: boolean
  canDownload: boolean
  canList: boolean
}

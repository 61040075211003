import {S2StateProp} from "@tracabois/utils";
import {Chip} from "@tracabois/template";
import {ObjectStateFormPopup} from "./ObjectStateFormPopup";
import {usePopup} from "../hooks/usePopup";

interface ObjectStateProps {
  editable?: boolean,
  state?: S2StateProp,
  stateAction?: UpdateStateAction
}

export interface UpdateStateAction {
  label: string
  action: () => Promise<boolean> | boolean
}

export const ObjectStateChip = (props: ObjectStateProps) => {
  const {editable = false,state, stateAction} = props
  const popup = usePopup()
  const statusChip = state && (<Chip label={state.name} onClick={editable ? popup.open : undefined} color={state.color}/>)
  return (<>
    { editable && stateAction && <ObjectStateFormPopup stateAction={stateAction} open={popup.isOpen} onClose={popup.close}/> }
    {statusChip}
  </>)
}
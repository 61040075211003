import {Stack, Typography} from "@mui/material";
import {ReactElement} from "react";

export interface TableCellTagProps {
  main: ReactElement
  second?: string
}

export const TableCellTag = (props: TableCellTagProps) => {
  const {main, second} = props
  return (
    <Stack>
      {main}
      {second && <Typography color="secondary" variant="caption">{second}</Typography>}
    </Stack>
  )
}

import {ProductTransformationDateChangeCommand, ProductId} from "../../../model/product";
import {StaticDatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFnsV3";
import {Stack} from "@mui/material";
import {useCallback} from "react";

export interface ProductDateFormProps {
  productId: ProductId
  date: Date | null
  onDateChange: (cmd: ProductTransformationDateChangeCommand) => void;
  loading: boolean
  readOnly: boolean
}

export const ProductDateForm = (props: ProductDateFormProps) => {
  const {productId, date, onDateChange, loading, readOnly} = props

  const handleDateChange = useCallback(async (newDate: Date | null) => {
    newDate && onDateChange({
      id: productId,
      date: newDate.getTime(),
    })
  }, [productId])

  return (
    <Stack width='100%'>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <StaticDatePicker<Date> readOnly={readOnly || loading} value={date} onChange={handleDateChange}/>
      </LocalizationProvider>
    </Stack>
  )
}

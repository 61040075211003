import {PlatformPage, PlatformSectionTable} from "@tracabois/template";
import {useMemo} from "react";
import {useNavigate } from "react-router-dom";
import {Action} from "@komune-io/g2";
import {userRoute} from "../../route";
import {useTranslation} from "react-i18next";
import {UserTable} from "../../components/UserTable/UserTable";

export interface UserTablePros {
  organizationId?: string;
}

export const UserListPage = (props: UserTablePros) => {
  const {organizationId} = props;
  const navigate = useNavigate()
  const {t} = useTranslation()

  const pageActions: Action[] = useMemo(() => ([
    {
      key: "create",
      label: t("user.create"),
      onClick: () => userRoute.goto.createUser(navigate, {
        organizationId: organizationId,
      })
    }
  ]), []);


  return (
      <PlatformPage title={t('user.plurals')} actions={pageActions}>
        <PlatformSectionTable>
          <UserTable organizationId={organizationId}/>
        </PlatformSectionTable>
      </PlatformPage>
  );
};

import {RealizationListPage} from "../list";
import {PlatformRoute} from "@tracabois/template";
import {RealizationViewPage} from "../view";

export const RealizationRoutes: PlatformRoute[] = [
    {
        path: "/realizations",
        components: <RealizationListPage />
    }, {
        path: "/realizations/:realizationId/view",
        components: <RealizationViewPage />
    }
]
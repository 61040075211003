import {Stack} from "@mui/material";
import { CreditedFile, FileName, ExternalFile } from "@tracabois/domain";
import { CreditedImage } from "./CreditedImage";

export interface EditableGalleryProps {
  editable: boolean
  loading?: boolean
  files: ExternalFile[]
  onAdd?: (item: CreditedFile, toUseAsCover: boolean) => Promise<boolean> | boolean
  onUpdate?: (item: FileName) => Promise<boolean> | boolean
  onDelete?: (name: FileName) => Promise<boolean> | boolean
}

export const EditableGallery = (props: EditableGalleryProps) => {
  const {editable, loading, files, onAdd, onUpdate, onDelete} = props
  if (loading) {
    return <></>
  }
    return ( 
    <Stack sx={{
      margin: "20px 0",
      overflowX: "auto"
    }}
    style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: '20px'}}>
      {( 
        files.map((file, index) => {
        return (
        <CreditedImage item={file} isCover={index === 0} editable={editable} onAdd={onAdd} onUpdate={onUpdate} onDelete={onDelete}/>
        )
      }))}
      { editable && (
        <CreditedImage editable isCover={files.length === 0} onAdd={onAdd} onUpdate={onUpdate} onDelete={onDelete} />
      )}
    </Stack>)
}

import {Loading} from "@tracabois/template";
import {brandConfig} from "@tracabois/domain";

export const AppLoading = () => {
  const logo = `${brandConfig().logo}`
  return (
    <Loading img={logo} />
  )
}

export default AppLoading
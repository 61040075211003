import {useMemo} from 'react'
import {
  RemovalZone,
  RemovalZoneCreateCommand,
} from "../../model/removalZone";
import {WoodSpecies} from "../../../../referentiels/woodSpecies";
import {
  TransformationRoles,
  useExtendedAuth,
  useGetOrganizationRefs
} from "@tracabois/connect";
import {DropZonePictureAction} from "../../../brandOrganization";
import {DropZonePicture} from "@komune-io/g2-fs";
import {FormComposable, FormComposableState, FormComposableField} from "@komune-io/g2-composable";
import {Stack} from '@mui/material';
import { City, Department } from "@tracabois/domain";
import { RemovalZoneCertification } from '../../../../referentiels/removalZoneCertification';

export interface RemovalZoneFormProps {
  item?: Partial<RemovalZone>
  readonly?: boolean
  woodSpecies: WoodSpecies[]
  departments: Department[]
  cities: City[]
  removalZoneCertifications: RemovalZoneCertification[]
  formState: FormComposableState
  imageDropZone: DropZonePictureAction
  readonlyOrganization?: boolean
}

export const RemovalZoneForm = (props: RemovalZoneFormProps) => {
  const {
    item,
    readonly = false,
    woodSpecies,
    departments,
    cities,
    removalZoneCertifications,
    formState,
    readonlyOrganization = false,
    imageDropZone
  } = props
  const {service} = useExtendedAuth()

  const getOrganizationRefs = useGetOrganizationRefs({
    roles: TransformationRoles
  })

  const communeCodeToDepartmentCode = (code: number) => Number.parseInt(code.toString().substring(0, code < 96000 ? 2 : 3))

  const detailsFields = useMemo(
    (): FormComposableField[] => [
      {
        key: 'name',
        name: 'name',
        type: 'textField',
        label: 'Nom de la forêt',
        defaultValue: item?.name,
        params: {
          disabled: readonly
        },
        validator: (value?: string) => {
          const trimmed = (value ?? '').trim()
          if (!trimmed) return 'Vous devez renseigner le nom de la forêt' as string
          return undefined
        }
      }, {
        key: 'city',
        name: 'city',
        type: 'autoComplete',
        label: 'Commune',
        params: {
          options: cities,
          getOptionLabel: option => `${option.name} (${option.code})`,
          multiple: false,
          optionsResultLimit: 20,
          isOptionEqualToValue: (option, value) => option.code === value.code,
          disabled: readonly
        },
        onChange: (value) => {
          if (value === undefined) return undefined
          formState.setFieldValue('department', departments.find(department => department.code === communeCodeToDepartmentCode(value.code)))
        }
      }, {
        key: 'department',
        name: 'department',
        type: 'autoComplete',
        label: 'Departement',
        params: {
          options: departments,
          getOptionLabel: option => `${option.name} (${option.code})`,
          multiple: false,
          optionsResultLimit: 20,
          isOptionEqualToValue: (option, value) => option.code == value.code,
          disabled: readonly || formState.values.city != undefined
        },
        validator: (_: string, value: Partial<RemovalZoneCreateCommand>) => {
          if (!value.department) return "Vous devez renseigner le département"
          return undefined
        },
      }, {
        key: 'massif',
        name: 'massif',
        type: 'textField',
        label: 'Massif',
        params: {
          disabled: readonly,
        }
      },
      {
        key: 'geo.latitude',
        name: 'geo.latitude',
        type: 'textField',
        label: 'Latitude',
        params:{
          placeholder: "43.602829"
        }
      },
      {
        key: 'geo.longitude',
        name: 'geo.longitude',
        type: 'textField',
        label: 'Longitude',
        params:{
          placeholder: "3.879260"
        },
      },
      {
        key: 'organization.id',
        name: 'organization.id',
        type: 'autoComplete',
        label: 'Acteur',
        readOnly: readonlyOrganization,
        params: {
          textFieldProps: {
            placeholder: 'Sélectionner',
          },
          options: getOrganizationRefs.data.map((item) => ({key: item.id, label: item.name})),
          disabled: !service.isMarque()
        },
        validator: (_: string, value: Partial<RemovalZoneCreateCommand>) => {
          const trimmed = (value.organization?.id ?? '').trim()
          if (!trimmed) return "Vous devez renseigner l'acteur"
          return undefined
        },
      },
      {
        key: 'certifications',
        name: 'certifications',
        type: 'autoComplete',
        label: 'Certifications',
        params: {
          options: removalZoneCertifications.map((item) => ({key: item.id, label: item.name})),
          limitTags: 1,
          multiple: true,
          style: {
            flexGrow: 1,
          }
        },
      },
    ],
    [readonly, woodSpecies, cities, getOrganizationRefs.data, removalZoneCertifications]
  )

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" spacing={5}>
        <DropZonePicture {...imageDropZone}/>
        <FormComposable
          fields={detailsFields}
          formState={formState}
        />
      </Stack>
    </Stack>
  )
}

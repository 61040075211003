import {Realization, RealizationUpdatedEvent, realizationToUpdateRealizationCommand} from "../../../model/realizationModel";
import {useCallback, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {PlatformSubSection} from "@tracabois/template";
import {Action, MarkdownField} from "@komune-io/g2";
import {useRealizationUpdateMutation} from "../../../api";

interface RealizationDescriptionSectionProps {
  realization?: Realization
  onRealizationUpdated?: (result: RealizationUpdatedEvent) => void
  editable?: boolean
  title: string
}

const useLabels = () => {
  const {t} = useTranslation()
  return useMemo(() => ({
    editDescription: t("realization.description.edit", "Editer"),
    saveDescription: t("realization.description.save", "Valider"),
  }), [t])
}

export const RealizationDescriptionSection = (props: RealizationDescriptionSectionProps) => {
  const {realization, onRealizationUpdated, editable, title} = props
  const [editMode, setEditMode] = useState<boolean>(false)
  const [markdown, setMarkdown] = useState<string>(realization?.description || "")
  const labels = useLabels()
  const [realizationUpdate] = useRealizationUpdateMutation()
  const actions: Action[] = [{
    key: 'descriptionEdit',
    showIf: () => editable || true,
    onClick: () => {
      if (!editable) return
      if (editMode && markdown != realization?.description) {
        handleUpdate(markdown)
      }
      setEditMode(!editMode)
    },
    label: editMode ? labels.saveDescription : labels.editDescription
  }]

  useEffect(() => {
    if (realization?.description) setMarkdown(realization.description)
  }, [realization?.description])

  const handleUpdate = useCallback(async (description: string): Promise<boolean> => {
    realization && onRealizationUpdated && await realizationUpdate({
      ...realizationToUpdateRealizationCommand(realization),
      description: description,
    })
    return true;
  }, [realizationUpdate, realization])

  return (
    <PlatformSubSection title={title} actions={actions}>
      {realization?.id && (
        <MarkdownField markdown={markdown} readOnly={!((editable || false) && editMode)} onChange={setMarkdown}/>
      )}
    </PlatformSubSection>
  )
}

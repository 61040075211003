import {QueryParams, useQueryRequest} from "@komune-io/g2";
import {ProductEvidenceListQuery, ProductEvidenceListResult} from "../model/productEvidence";
import {useAuthenticatedRequest} from "../../../config/useAuthenticatedRequest";

export const useProductEvidenceListFunction = (params: QueryParams<ProductEvidenceListQuery, ProductEvidenceListResult>) => {
  const requestProps = useAuthenticatedRequest()
  return useQueryRequest<ProductEvidenceListQuery, ProductEvidenceListResult>(
    "productEvidenceList", requestProps, params
  )
}

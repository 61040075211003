import {config} from "../../../config";
import {createApi} from "@reduxjs/toolkit/dist/query/react";
import {firstPage, PageQuery, PageResult, toPagination} from "@tracabois/utils";
import {
  WoodSpecies,
  WoodSpeciesCreateCommand,
  WoodSpeciesCreatedEvent,
  WoodSpeciesDeleteCommand,
  WoodSpeciesDeletedEvent,
  WoodSpeciesId,
  WoodSpeciesUpdateCommand,
  WoodSpeciesUpdatedEvent
} from "../model/WoodSpecies";
import {useState} from "react";
import {authQuery} from "../../../utils/authQuery";

export const WoodSpeciesApi = createApi({
  reducerPath: 'woodSpeciesApi',
  baseQuery: authQuery({baseUrl: config.baseUrl}),
  tagTypes: ['WoodSpecies'],
  endpoints: (builder) => ({
    getWoodSpeciesById: builder.query<WoodSpecies, WoodSpeciesId>({
      query: (id: WoodSpeciesId) => ({
        url: `woodSpeciesGet`,
        method: 'POST',
        body: {id: id}
      }),
      providesTags: ['WoodSpecies'],
    }),
    woodSpeciesPage: builder.query<PageResult<WoodSpecies>, PageQuery>({
      query: (page: PageQuery) => ({
        url: `woodSpeciesPage`,
        method: 'POST',
        body: page
      }),
      transformResponse: (response: PageResult<WoodSpecies>[]) => response[0],
    }),
    woodSpeciesCreate: builder.mutation<WoodSpeciesCreatedEvent, WoodSpeciesCreateCommand>({
      query: (cmd: WoodSpeciesCreateCommand) => ({
        url: `woodSpeciesCreate`,
        method: 'POST',
        body: cmd
      }),
      transformResponse: (response: WoodSpeciesCreatedEvent[]) => response[0],
      invalidatesTags: ['WoodSpecies'],
    }),
    woodSpeciesUpdate: builder.mutation<WoodSpeciesUpdatedEvent, WoodSpeciesUpdateCommand>({
      query: (cmd: WoodSpeciesUpdateCommand) => ({
        url: `woodSpeciesUpdate`,
        method: 'POST',
        body: cmd
      }),
      transformResponse: (response: WoodSpeciesUpdatedEvent[]) => response[0],
      invalidatesTags: ['WoodSpecies'],
    }),
    woodSpeciesDelete: builder.mutation<WoodSpeciesDeletedEvent, WoodSpeciesDeleteCommand>({
      query: (cmd: WoodSpecies) => ({
        url: `woodSpeciesDelete`,
        method: 'POST',
        body: cmd
      }),
      transformResponse: (response: WoodSpeciesDeletedEvent[]) => response[0],
      invalidatesTags: ['WoodSpecies'],
    }),
  }),
})

export const {
  useGetWoodSpeciesByIdQuery, useWoodSpeciesPageQuery,
  useWoodSpeciesCreateMutation, useWoodSpeciesUpdateMutation, useWoodSpeciesDeleteMutation
} = WoodSpeciesApi


export const useWoodSpeciesAllQuery = () => {
  const {data, error, isLoading, refetch} = WoodSpeciesApi.useWoodSpeciesPageQuery({})
  const [pageQuery, setPageQuery] = useState<PageQuery>({
    pagination: firstPage
  })
  let sortedData =  [...data?.items ?? []]
  sortedData.sort((a, b) => {
    return  a.name.localeCompare(b.name)
  })
  if(pageQuery.search) {
    sortedData = sortedData.filter((item) => {
      return item.name.toLowerCase().includes(pageQuery.search?.toLowerCase() ?? "")
    })
  }
  const page = toPagination(sortedData, pageQuery.pagination)
  const getAll = () => sortedData

  return {data: page, error, isLoading, refetch, setPageQuery, getAll}
}
import {Stack, styled, Typography,} from '@mui/material'
import {WoodSpecies} from "../../model/WoodSpecies";
import { externalFileUrl } from '@tracabois/domain';

const StyledStack = styled(Stack)({
  '& .AruiPopover-root': {
    width: '80vw',
    maxWidth: '450px'
  },
  '& .mainFormLeft': {
    marginRight: '30px',
    width: '260px'
  },
  '& .mainFormRight': {
    width: '260px'
  }
})

const StyledWoodSpeciesImgStack = styled(Stack)({
  '& :first-child': {
    width: '32px!important',
  },
})

export interface WoodSpeciesCardProps {
  items: WoodSpecies[]
  alignItems?: string
}

export const WoodSpeciesCard = (props: WoodSpeciesCardProps) => {
  const {items, alignItems = "center"} = props
  return (
    <StyledStack
      alignItems={alignItems}
      width="100%"
      direction="row"
      justifyContent="space-evenly"
      flexWrap="nowrap"
      gap={2}
      maxHeight="60px"
    >
    <StyledWoodSpeciesImgStack
      alignItems={alignItems}
      direction="row"
      justifyContent="space-around"
      flexWrap="nowrap"
    >
      {items.map((item) => (
        <div style={{width: '22px'}}>
          <img
            src={externalFileUrl(item.img)}
            alt={item.name}
            width={32}
            height={32}
            loading="lazy"
            style={{
              border: '2px solid #FFFFFF', 
              borderRadius: '50px', 
              float: 'right',
              boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
            }}
          />
        </div>
      ))}
    </StyledWoodSpeciesImgStack>
    <Stack margin="5px 0px" paddingRight="10px">
      <Typography variant="body2" color="#FFFFFF" fontWeight="bold" whiteSpace="pre">
        {items.map(item => item.name).join(", ")}
      </Typography>
    </Stack>
    </StyledStack>
  )
}

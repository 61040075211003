import {config} from "../../../config";
import {createApi} from "@reduxjs/toolkit/dist/query/react";
import {PageResult} from "@tracabois/utils";
import {
  RemovalZone,
  RemovalZoneCreateCommand,
  RemovalZoneCreatedEvent,
  RemovalZoneDeleteCommand,
  RemovalZoneDeletedEvent, RemovalZoneGetResponse,
  RemovalZoneId, RemovalZonePageQuery, RemovalZonePageResponse,
  RemovalZoneUpdateCommand,
  RemovalZoneUpdatedEvent
} from "../model/removalZone";
import {authQuery} from "../../../utils/authQuery";

export const RemovalZoneApi = createApi({
  reducerPath: 'removalZoneApi',
  baseQuery: authQuery({baseUrl: config.baseUrl}),
  tagTypes: ['RemovalZone'],
  endpoints: (builder) => ({
    removalZoneGet: builder.query<RemovalZoneGetResponse, RemovalZoneId>({
      query: (id: RemovalZoneId) => ({
        url: `removalZoneGet`,
        method: 'POST',
        body: {id: id}
      }),
      transformResponse: (response: RemovalZoneGetResponse[]) => response[0],
      providesTags: ['RemovalZone'],
    }),
    removalZonePage: builder.query<RemovalZonePageResponse, RemovalZonePageQuery>({
      query: (page: RemovalZonePageQuery) => ({
        url: `removalZonePage`,
        method: 'POST',
        body: page
      }),
      transformResponse: (response: PageResult<RemovalZone>[]) => response[0],
      providesTags: ['RemovalZone'],
    }),
    removalZoneCreate: builder.mutation<RemovalZoneCreatedEvent, RemovalZoneCreateCommand>({
      query: (cmd: RemovalZoneCreateCommand) => ({
        url: `removalZoneCreate`,
        method: 'POST',
        body: cmd
      }),
      transformResponse: (response: RemovalZoneCreatedEvent[]) => response[0],
      invalidatesTags: ['RemovalZone'],
    }),
    removalZoneUpdate: builder.mutation<RemovalZoneUpdatedEvent, RemovalZoneUpdateCommand>({
      query: (cmd: RemovalZoneUpdateCommand) => ({
        url: `removalZoneUpdate`,
        method: 'POST',
        body: cmd
      }),
      transformResponse: (response: RemovalZoneUpdatedEvent[]) => response[0],
      invalidatesTags: ['RemovalZone'],
    }),
    removalZoneDelete: builder.mutation<RemovalZoneDeletedEvent, RemovalZoneDeleteCommand>({
      query: (cmd: RemovalZone) => ({
        url: `removalZoneDelete`,
        method: 'POST',
        body: cmd
      }),
      transformResponse: (response: RemovalZoneDeletedEvent[]) => response[0],
      invalidatesTags: ['RemovalZone'],
    }),
  }),
})

export const {
  useRemovalZonePageQuery, useRemovalZoneGetQuery,
  useRemovalZoneCreateMutation, useRemovalZoneUpdateMutation, useRemovalZoneDeleteMutation,
} = RemovalZoneApi

import {config} from "../../../config";
import {createApi} from "@reduxjs/toolkit/dist/query/react";
import {PageResult} from "@tracabois/utils";
import {
  Product,
  ProductCreateCommand,
  ProductCreatedEvent,
  ProductDeleteCommand,
  ProductDeletedEvent,
  ProductGetQuery,
  ProductGetResponse,
  ProductPageQuery,
  ProductRenameCommand,
  ProductRenamedEvent,
  ProductTransformationActorDefineCommand,
  ProductTransformationActorDefinedEvent,
  ProductTransformationDateChangeCommand,
  ProductTransformationDateChangedEvent,
  ProductUpdateCommand,
  ProductUpdatedEvent,
  ProductUnlockCommand,
  ProductUnlockedEvent,
  ProductFinishedEvent,
  ProductFinishCommand,
  ProductModel,
} from "../model/product";
import {authQuery} from "../../../utils/authQuery";

export const ProductApi = createApi({
  reducerPath: 'productApi',
  baseQuery: authQuery({baseUrl: config.baseUrl}),
  tagTypes: ['Product'],
  endpoints: (builder) => ({
    getProductById: builder.query<ProductModel | undefined, ProductGetQuery>({
      query: (body: ProductGetQuery) => ({
        url: `productGet`,
        method: 'POST',
        body: body
      }),
      transformResponse: (response: ProductGetResponse[]) => response[0]?.item as ProductModel,
    }),
    productsPage: builder.query<PageResult<Product>, ProductPageQuery>({
      query: (page: ProductPageQuery) => ({
        url: `productPage`,
        method: 'POST',
        body: page
      }),
      transformResponse: (response: PageResult<Product>[]) => response[0],
      providesTags: ['Product'],
    }),
    productCreate: builder.mutation<ProductCreatedEvent, ProductCreateCommand>({
      query: (cmd: ProductCreateCommand) => ({
        url: `productCreate`,
        method: 'POST',
        body: cmd
      }),
      transformResponse: (response: ProductCreatedEvent[]) => response[0],
      invalidatesTags: ['Product'],
    }),
    productUpdate: builder.mutation<ProductUpdatedEvent, ProductUpdateCommand>({
      query: (cmd: ProductUpdateCommand) => ({
        url: `productUpdate`,
        method: 'POST',
        body: cmd
      }),
      transformResponse: (response: ProductUpdatedEvent[]) => response[0],
      invalidatesTags: ['Product'],
    }),
    productRename: builder.mutation<ProductRenamedEvent, ProductRenameCommand>({
      query: (cmd: ProductRenameCommand) => ({
        url: `productRename`,
        method: 'POST',
        body: cmd
      }),
      transformResponse: (response: ProductRenamedEvent[]) => response[0],
      invalidatesTags: ['Product'],
    }),
    productDelete: builder.mutation<ProductDeletedEvent, ProductDeleteCommand>({
      query: (cmd: ProductDeleteCommand) => ({
        url: `productDelete`,
        method: 'POST',
        body: cmd
      }),
      transformResponse: (response: ProductDeletedEvent[]) => response[0],
      invalidatesTags: ['Product'],
    }),
    productChangeDate: builder.mutation<ProductTransformationDateChangedEvent, ProductTransformationDateChangeCommand>({
      query: (cmd: ProductDeleteCommand) => ({
        url: `productChangeDate`,
        method: 'POST',
        body: cmd
      }),
      transformResponse: (response: ProductTransformationDateChangedEvent[]) => response[0],
      invalidatesTags: ['Product'],
    }),
    productTransformationActorDefine: builder.mutation<ProductTransformationActorDefinedEvent, ProductTransformationActorDefineCommand>({
      query: (cmd: ProductDeleteCommand) => ({
        url: `productTransformationActorDefine`,
        method: 'POST',
        body: cmd
      }),
      transformResponse: (response: ProductTransformationActorDefinedEvent[]) => response[0],
      invalidatesTags: ['Product'],
    }),
    productUnlock: builder.mutation<ProductUnlockedEvent, ProductUnlockCommand>({
      query: (cmd: Product) => ({
        url: `${config.baseUrl}/productUnlock`,
        method: 'POST',
        body: cmd
      }),
      transformResponse: (response: ProductDeletedEvent[]) => response[0],
      invalidatesTags: ['Product'],
    }),
    productFinish: builder.mutation<ProductFinishedEvent, ProductFinishCommand>({
      query: (cmd: Product) => ({
        url: `productFinish`,
        method: 'POST',
        body: cmd
      }),
      transformResponse: (response: ProductFinishedEvent[]) => response[0],
      invalidatesTags: ['Product'],
    })
  }),
})

export const {
  useGetProductByIdQuery, useProductsPageQuery,
  useProductCreateMutation, useProductUpdateMutation,
  useProductRenameMutation, useProductDeleteMutation,
  useProductChangeDateMutation,
  useProductTransformationActorDefineMutation,
  useProductUnlockMutation,
  useProductFinishMutation,
} = ProductApi

export * from "./useProductEvidenceUploadFunction"

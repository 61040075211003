import {filterByOrganizationId, ProductType} from "../../../../../../referentiels/productType";
import {Unit} from "../../../../../../domain/unit";
import {WoodSpecies} from "../../../../../../referentiels/woodSpecies";
import {EndProductModel} from "../../../../model/product";
import {withProductField} from "../ProductMetrics/ProductMetricsField";
import {DataSelection} from "../useDataSelection";
import {FormComposableField} from "@tracabois/components";

export interface EndProductTypeFieldsArray {
  fieldName: string
  fields: EndProductTypeFields[]
}

export interface EndProductTypeFields {
  productNameFields: FormComposableField[]
  metricsField: FormComposableField[]
}

export const useDestinationEndProductsField = (selection: DataSelection, prefix: string, items: Partial<EndProductModel>[], transformatedActor?: string): EndProductTypeFieldsArray => {
  const filtered =  filterByOrganizationId(selection.endProductTypes, selection.transformationOrganizations, transformatedActor)
  // const atLeastOneLIneItems = items.length != 0 ? items : [{}]
  const atLeastOneLIneItems = items
  const fields = (atLeastOneLIneItems.map((item, index) => {
    const prefixArray = `${prefix}[${index}]`
    return useDestinationEndProductField(filtered, selection.units, selection.woodSpecies, prefixArray, item)
  }))

  return {
    fields: fields,
    fieldName: prefix
  }

}

export const useDestinationEndProductField = (productTypes: ProductType[], units: Unit[], woodSpecies: WoodSpecies[], prefix: string, item: Partial<EndProductModel>): EndProductTypeFields => {
  const productNameFields: FormComposableField[] = [{
    key: `${prefix}.productTypes`,
    name: `${prefix}.productTypes`,
    type: 'autoComplete',
    label: 'Produit fini',
    defaultValue: item?.productTypes,
    params: {
      options: productTypes.map((item) => ({
        key: item.id,
        label: item.name,
      })),
      readonlyType: "text",
      multiple: true,
    },
  }]

  const metricsField = withProductField(units, woodSpecies, true,`${prefix}.metrics`, item.metrics || undefined)

  return {
    productNameFields: productNameFields,
    metricsField: metricsField.fields,
  }
}

import { Typography } from '@mui/material'
import {Action, AutomatedUserTable} from '@komune-io/g2'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useMemo } from 'react'
import { useUserListPage } from "./UserTable/useUserListPage";
import { useUserFilters } from "./UserTable/useUserFilters";

export interface OrganizationUserListProps {
    organizationId?: string
    myOrganization?: boolean

}

export const OrganizationUserList = (props: OrganizationUserListProps) => {
    const { organizationId, myOrganization } = props
    const { onRowClicked, columns } = useUserListPage()
    const { t } = useTranslation()
    const actions = useMemo((): Action[] => [ {
        key: "user.create",
        label: t("user.create"),
        component: Link,
        componentProps: { to: `/users/add?organizationId=${organizationId}`}
    }], [t, myOrganization, organizationId])

    const { component, submittedFilters, setPage } = useUserFilters({ actions })
    return (
        <>
            {component}
            <AutomatedUserTable
                tableStateParams={{
                    columns: columns
                }}
                onRowClicked={onRowClicked}
                filters={{ organizationId: organizationId, ...submittedFilters }}
                noDataComponent={<Typography align="center">{t("userList.noUserOrg")}</Typography>}
                expectedSize={5}
                page={submittedFilters.page + 1}
                setPage={setPage}
            />
        </>
    )
}

// const userTableBlockedColumns = ["memberOf", "address", "email"]

import {Stack} from "@mui/material";
import {PlatformPageObjectHeaderTitle, PlatformPageHeaderTitleProps} from "./PlatformPageObjectHeaderTitle";
import {PlatformPageObjectHeaderAction, PlatformPageHeaderActionProps} from "./PlatformPageObjectHeaderAction";

interface PlatformPageObjectHeaderProps extends PlatformPageHeaderTitleProps, PlatformPageHeaderActionProps {}

export const PlatformPageObjectHeader = (props: PlatformPageObjectHeaderProps) => {
  const {children, title, editableTitle, onTitleSave, prefix} = props
  return (
    <Stack width="100%" direction="row" justifyContent="space-between" spacing={2}>
      {title && <PlatformPageObjectHeaderTitle
          title={title}
          editableTitle={editableTitle}
          onTitleSave={onTitleSave}
          prefix={prefix}
      />}
      {children && <PlatformPageObjectHeaderAction children={children}/>}
    </Stack>
  )
}

import {
  ProductEvidenceUploadCommand,
  ProductEvidenceUploadedEvent,
} from "../model/productEvidence";
import {useAuthenticatedRequest} from "../../../config/useAuthenticatedRequest";

import {CommandParams, CommandWithFile, useCommandWithFileRequest} from "@komune-io/g2";

export type ProductEvidenceUploadCommandOptions = Omit<
  CommandParams<CommandWithFile<ProductEvidenceUploadCommand>, ProductEvidenceUploadedEvent>,
  'jwt' | 'apiUrl'
>

export const useProductEvidenceUploadFunction = (params?: ProductEvidenceUploadCommandOptions) => {
  const requestProps = useAuthenticatedRequest()
  return useCommandWithFileRequest<ProductEvidenceUploadCommand, ProductEvidenceUploadedEvent>(
    "productEvidenceUpload", requestProps, params
  )
}



import {CellProps, Column, Row} from '@komune-io/g2-layout'
import {Typography} from '@mui/material'
import {TFunction} from 'i18next'
import {PlatformTableSearchFilters, PlatformPaginatedTable} from "@tracabois/template";
import {firstPage, OffsetPagination, PageQuery, PageResult} from "@tracabois/utils";
import {OuvrageType} from "../../model/OuvrageType";

interface OuvrageTypeProps {
  page?: PageResult<OuvrageType>
  isLoading?: boolean
  onPageQueryChange: (page: PageQuery) => void;
  onRowClicked: (row: Row<OuvrageType>) => void;
  actions: React.ReactNode
}

export const OuvrageTypeTable = (props: OuvrageTypeProps) => {
  const {page, isLoading, onPageQueryChange, onRowClicked, actions} = props

  const onSearch = (search?: string) => {
    onPageQueryChange({
      pagination: page?.pagination || firstPage,
      search: search
    })
  }
  const filter = (
    <PlatformTableSearchFilters actions={actions} onSubmit={(props) => onSearch(props.search)}/>
  )
  const onPaginationChange = (pagination: OffsetPagination) => {
    onPageQueryChange({
      pagination: pagination,
      search: page?.search
    })
  }
  return (
    <PlatformPaginatedTable<OuvrageType>
      page={page}
      columnsDefinition={getColumns}
      isLoading={isLoading}
      actions={filter}
      onPageChange={onPaginationChange}
      onRowClicked={onRowClicked}
      noElevation
    />
  )
}


const getColumns = (t: TFunction): Column<OuvrageType>[] => ([{
  Header: `${t("ouvrageType.name", "Libéllé")}`,
  // @ts-ignore
  Cell: ({row}: CellProps<OuvrageType>) => (
    <Typography variant="body2"
    >{row.original.name}</Typography>
  )
}])

import React from "react";
import { AppProvider, ThemeContextProvider, AlertHub } from "@komune-io/g2";
import App from "App";
import { AppLoading } from "@tracabois/components";
import { languages, theme } from "@tracabois/domain";
import { KeycloakProvider, G2ConfigBuilder, g2Config, OidcSecure } from '@komune-io/g2-providers'
import { QueryClient } from '@tanstack/react-query';
import { createRoot } from 'react-dom/client';
import {Provider} from "react-redux";
import {store} from "./store";
import { OidcConfiguration } from "@axa-fr/oidc-client";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 86400000,
      refetchOnMount: "always"
    }
  }
})

const oidcConfiguration: OidcConfiguration = {
  client_id: g2Config().keycloak.clientId,
  redirect_uri: window.location.origin + '/authentication/callback',
  silent_redirect_uri:
    window.location.origin + '/authentication/silent-callback',
  scope: 'openid',
  authority: g2Config().keycloak.url + '/realms/' + g2Config().keycloak.realm,
  service_worker_relative_url: '/OidcServiceWorker.js',
  storage: localStorage,
  service_worker_only: false,
}


// @ts-ignore
G2ConfigBuilder(window._env_.config)

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<KeycloakProvider>
  <React.StrictMode>
    <ThemeContextProvider theme={theme}>
      <KeycloakProvider
        configuration={oidcConfiguration}
      >
        <OidcSecure>
          <AppProvider
            languages={languages}
            loadingComponent={<AppLoading />}
            queryClient={queryClient}
          >
              <Provider store={store}>
                <AlertHub
                  autoHideDuration={3000}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                  }}
                >
                  <App />
                </AlertHub>
              </Provider>
          </AppProvider>
        </OidcSecure>
      </KeycloakProvider>
    </ThemeContextProvider>
  </React.StrictMode>
</KeycloakProvider>);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);

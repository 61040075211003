import {useEffect, useMemo} from 'react'
import {ProductCreateCommand} from "../../../model/product"
import {OrganizationRef} from 'connect-im';
import {FormComposable, FormComposableField, useFeedback} from "@tracabois/components";
import {ProductType} from "../../../../../referentiels/productType";
import {OuvrageId} from "../../../../ouvrage";
import {useExtendedAuth} from "@tracabois/connect";
import {FormAction} from "@komune-io/g2-forms";
import {useFormComposable} from "@komune-io/g2";
import {getIn} from "formik";

export interface ProductCreateFormProps {
  onSubmit?: (item: ProductCreateCommand) => Promise<boolean> | boolean
  onCancel?: () => void
  ouvrageId?: OuvrageId
  organizationRefs: OrganizationRef[]
  productTypes: ProductType[]
  onOrganizationChange: (organizationId: string) => void
  loading?: boolean
  readOnly?: boolean
}

export const ProductCreateForm = (props: ProductCreateFormProps) => {
  const {
    onSubmit,
    onCancel,
    ouvrageId,
    organizationRefs,
    productTypes,
    onOrganizationChange,
    readOnly,
    loading
  } = props

  const feedback = useFeedback()
  const {service} = useExtendedAuth()
  const organizationId = !service.isMarque() ? service.getMemberOf() : undefined
  const formFields = useMemo(
    (): FormComposableField[] => {
      return [{
        key: 'actor.id',
        name: 'actor.id',
        type: 'autoComplete',
        label: 'Acteur de la transformation',
        params: {
          placeholder: 'Sélectionner',
          options: organizationRefs.map((item) => ({key: item.id, label: item.name})),
          disabled: !service.isMarque()
        },
        validator: (_: string, value: Partial<ProductCreateCommand>) => {
          const trimmed = (value.actor?.id ?? '').trim()
          if (!trimmed) return "Vous devez renseigner l'acteur"
          return undefined
        },
      }, {
        key: 'productType.id',
        name: 'productType.id',
        type: 'autoComplete',
        label: 'Type de produit',
        params: {
          placeholder: 'Sélectionner',
          options: productTypes.map((item) => ({
            key: item.id,
            label: item.name
          }))
        },
        validator: (_: string, value: Partial<ProductCreateCommand>) => {
          const trimmed = (value.productType?.id ?? '').trim()
          if (!trimmed) return "Vous devez renseigner le type de produit"
          return undefined
        },
      }
      ]
    },
    [productTypes, organizationRefs]
  )

  const formState = useFormComposable({
    onSubmit: async (values: ProductCreateCommand) => {
      if (!onSubmit) {
        return false
      }
      const result = await onSubmit({
        ...values,
        ouvrage: ouvrageId ? {id: ouvrageId} : undefined
      }) || false
      feedback.setFeedback(result)
      return result
    },
    formikConfig: {
      initialValues: {
        "actor": {id: organizationId}
      },
    }
  })
  const actorId = getIn(formState.values, "actor.id")
  useEffect(() => actorId && onOrganizationChange(actorId), [actorId])

  const actions: FormAction[] = [{
    key: "cancel",
    label: "Annuler",
    onClick: onCancel,
    variant: "text"
  }, {
    key: "create",
    label: "Créer",
    success: feedback.isSuccess,
    fail: feedback.isFailure,
    onClick: formState.submitForm
  }]

  return (
    <FormComposable
      isLoading={loading}
      readOnly={readOnly}
      fields={formFields}
      formState={formState}
      actions={actions}
    />
  )
}

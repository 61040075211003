import {
  ProductEvidenceDeleteCommand, ProductEvidenceDeletedEvent,
} from "../model/productEvidence";
import {useAuthenticatedRequest} from "../../../config/useAuthenticatedRequest";

import {CommandParams, useCommandRequest } from "@komune-io/g2";

export type ProductEvidenceDeleteCommandOptions = Omit<
  CommandParams<ProductEvidenceDeleteCommand, ProductEvidenceDeletedEvent>,
  'jwt' | 'apiUrl'
>

export const useProductEvidenceDeleteFunction = (params?: ProductEvidenceDeleteCommandOptions) => {
  const requestProps = useAuthenticatedRequest()
  return useCommandRequest<ProductEvidenceDeleteCommand, ProductEvidenceDeletedEvent>(
    "productEvidenceDelete", requestProps, params
  )
}

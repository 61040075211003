import {Box} from '@mui/material'
import {BasicProps, useTheme} from '@komune-io/g2-themes'
import React from 'react'
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";

export interface PlatformSectionTableProps  extends BasicProps {
    noElevation?: boolean
    children: React.ReactNode;
}

export const PlatformSectionTable =  (props: PlatformSectionTableProps) => {
    const {
        noElevation, children
    } = props
    const {colors} = useTheme()
    const sx: SxProps<Theme> = noElevation ? {} : {
        '& .AruiTable-root': {
            borderRadius: '8px',
            boxShadow: 1,
            background: 'white',
            marginBottom: '20px'
        },
        '& .MuiTableRow-head': {
            background: `${colors.primary}25`,
        },
        '& .MuiTableRow-head .MuiTypography-root': {
            color: `${colors.primary}`,

        }
    }
    return (
        <Box sx={sx}>
            {children}
        </Box>
    )
}

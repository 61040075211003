import {PopupForm} from "@tracabois/components";
import {OuvrageCreateForm} from "./OuvrageCreateForm";
import {useTranslation} from "react-i18next";
import {OuvrageCreateCommand} from "../../../model/ouvrage";
import {RealizationId} from "../../../../realization";
import {useOuvrageTypeAllQuery} from "../../../../../referentiels/ouvrageType";

export interface OuvrageCreatePopupProps {
  onSubmit: (item: OuvrageCreateCommand) => Promise<boolean> | boolean
  open: boolean
  onClose: () => void
  realizationId?: RealizationId,
}

export const OuvrageCreateFormPopup = (props: OuvrageCreatePopupProps) => {
  const {onSubmit, open, onClose, realizationId} = props
  const {t} = useTranslation()
  const ouvrageTypeAllQuery = useOuvrageTypeAllQuery();
  return (
    <PopupForm open={open} onClose={onClose} title={t("ouvrage.form.create.title", "Création d’un ouvrage")}>
      <OuvrageCreateForm
        realizationId={realizationId}
        onCancel={() => {
          onClose()
          return true;
        }}
        onSubmit={onSubmit}
        ouvrageTypes={ouvrageTypeAllQuery.data.items}
      />
    </PopupForm>
  )
};

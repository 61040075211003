import {PlatformRoute} from "@tracabois/template";
import {OuvrageListPage} from "../list";
import {OuvrageViewPage} from "../view";

export const OuvrageRoutes: PlatformRoute[] = [
    {
        path: "/ouvrages",
        components: <OuvrageListPage />
    },    {
        path: "/ouvrages/:ouvrageId/view",
        components: <OuvrageViewPage />
    }
]

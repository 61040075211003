import {ProductId, ProductTransformation, ProductTransformationActorDefineCommand} from "../../../model/product";
import {useCallback, useEffect} from "react";
import {useProductTransformationActorDefineMutation} from "../../../api";
import {
  TransformationRoles,
  useExtendedAuth,
  useGetOrganizationRefs
} from "@tracabois/connect";
import {OrganizationRef} from "@tracabois/web-certificate/src/App/model";
import {FormComposableState, useFormComposable} from "@komune-io/g2";
import {FormComposable, FormComposableField} from "@tracabois/components";

export interface ProductTransformerFormProps {
  productId: ProductId
  transformation?: ProductTransformation
  onChange: (organizationId: OrganizationRef) => void;
  onFormReady: (cmd: FormComposableState) => void;
  loading: boolean;
  readOnly: boolean;
}

export const ProductTransformerForm = (props: ProductTransformerFormProps) => {
  const {productId, transformation, onFormReady, onChange, loading, readOnly} = props
  const organizations = useGetOrganizationRefs({
    roles: TransformationRoles
  }).data
  const {service} = useExtendedAuth()

  const fields: FormComposableField[] = [{
    key: 'actor.id',
    name: 'actor.id',
    type: 'autoComplete',
    onChange: (selectedKey) => {
      // @ts-ignore
      const newTransformer: OrganizationRef = organizations.find((ref) => ref.id == selectedKey)!
      onChange(newTransformer)
    },
    params: {
      disabled: !service.isMarque(),
      options: organizations.map((item) => ({
        key: item.id,
        label: item.name
      }))
    }
  }]

  const handleFormSubmit = useCallback(async (cmd: ProductTransformationActorDefineCommand) => {
    const  result = await productTransformationActorDefine({
      ...cmd,
      id: productId,
    })
    return !!result
  }, [productId])

  const formState = useFormComposable<ProductTransformationActorDefineCommand>({
    onSubmit: handleFormSubmit,
    formikConfig: {
      initialValues: transformation
    },
  })
  useEffect(() => onFormReady(formState), [])
  const [productTransformationActorDefine] = useProductTransformationActorDefineMutation()

  return (
    <FormComposable
      isLoading={loading}
      readOnly={readOnly}
      fields={fields}
      formState={formState}
    />
  )
}
